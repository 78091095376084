import React from 'react'
// import styled from 'styled-components'
import { useTable , usePagination } from 'react-table'
import '../../../css/Tabella.css'


// const Styles = styled.div`
//   padding: 1rem;

//   table {
//     border-spacing: 0;
//     border: 1px solid black;
 
//     tr {
//       :last-child {
//         td {
//           border-bottom: 0;
//         }
//       }
//     }

//     th,
//     td {
//       margin: 0;
//       padding: 0.5rem;
//       border-bottom: 1px solid black;
//       border-right: 1px solid black;

//       :last-child {
//         border-right: 0;
//       }
//     }
//   }
// `

function Table({ columns, data , Pulsanti }) {
    // Use the state and functions returned from useTable to build your UI
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page, // Instead of using 'rows', we'll use page,
      // which has only the rows for the active page
  
      // The rest of these things are super handy, too ;)
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0 },
      },
      usePagination
    )

    // const Pulsanti = () => {

//   return(
//       <td>
//           <button className='btn button_fix' >
//               <FontAwesomeIcon className='fs-4' icon={faEye} />
//           </button>

//           <button className='btn button_fix' >
//               <FontAwesomeIcon className='fs-4' icon={faEdit} />
//           </button>
//       </td>
//   )
// }
  
    // Render the UI for your table
    return (
      <>
        <table className='border overflow-hidden border-1 border-primary rounded table  table-bordered w-100 table-striped table-hover' {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')} {column?.altro}</th>
                ))}
                {Pulsanti &&<th>Azioni</th>}
              </tr>
            ))}

          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                  {Pulsanti && <Pulsanti id={row} />}
                </tr>
              )
            })}
          </tbody>
        </table>
        {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
        <div className="pagination mt-5 d-flex align-items-center">
            <div className='me-3'>
                <button className='btn btn-primary' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button className='btn btn-primary' onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button className='btn btn-primary' onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button className='btn btn-primary' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
            </div>
          <span className='me-4'>
            Pagina{' '}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>{' '}
          </span>
          <span className='align-items-center d-flex me-4' >
            | Vai a pagina :{' '}
            <input
              className='form-control ms-2'
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              style={{ width: '100px' }}
            />
          </span>{' '}
          Elementi 

          <select
            className='ms-1 form-select'
            style={{ width: '15%' }}
            value={pageSize}
            onChange={e => {
              setPageSize(Number(e.target.value))
            }}
          >
            {[5 , 10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                 {pageSize}
              </option>
            ))}
          </select>
        </div>
      </>
    )
  }

function Tabella(props) {

// const columns = React.useMemo(
//     () => [
        

//             {
//             Header: 'Nome',
//             accessor: 'nome',
//             },
//     ],
//     []
//     )



  return (
    // <Styles>
    // </Styles>
        <div className='m-4'>
            <Table {...props} />
        </div>
  )
}

export default Tabella
