import React from "react";
import { format } from "date-fns";
import TableSemp from "../../riutilizabili/TableSemp";
import ImgTabella from "../../prodotti/ImgTabella";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import { faBoxesStacked } from "@fortawesome/free-solid-svg-icons";
import { faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";

export default function ReadCampiBolla({
    inputs,
    cliente = false
}) {


    const campiAnagrafica = [
            {
                label : 'Ragione sociale',
                name : 'ragioneSociale'
            },
            {
                label : 'nome',
                name : 'nome'
            },
            {
                label : 'cognome',
                name : 'cognome'
            },
            {
                name : 'indirizzo',
                label : 'indirizzo'
            },
            {
                label : 'comune',
                name : 'comune'
            },
            {
                label : 'provincia',
                name : 'provincia'
            },
 
            {
                label : 'sede legale',
                name : 'sedeLegale'
            },
            {
                label : 'codice fiscale',
                name : 'codiceFiscale'
            },
            {
                label : 'paetita iva',
                name : 'pIva'
            },
            {
                label : 'email',
                name : 'email'
            },
            {
                label : 'numero civico',
                name : 'numeroCivico'
            },
            {
                label : 'cap',
                name : 'cap'
            },
            {
                label : 'numero telefono',
                name : 'numeroTelefono1'
            }
    ]


    const convertiData = (data) => {
        //console.log(format(new Date(data) ,'dd-MM-yyyy hh:mm') , ' Date ' , data);
        //console.log(data ,' Date 2' , (data+'').indexOf('Z'));
        if ((data+'').indexOf('Z') !== -1) {
            return format(new Date(data) ,'dd-MM-yyyy hh:mm') 
        }
        return format(new Date(data) ,'dd-MM-yyyy') 
    }

    //
    console.log(inputs , 'inputs leggi');

    const colonne = [
        {
            label: 'Nome',
            nome: 'nome',
            altCol : (original)=> {
                return(
                    <span className="d-block p-0 m-0" style={{width: '100%'}} > {original?.nome} </span>
                )
            }
        },
        {
            label: 'Sezione',
            nome: 'infoSezione',
            altCol : (original)=> {
                //console.log(original , 'original'); 
                return(
                    <span style={{width: '12%'}} className="d-block p-0 m-0"  >
                        {original?.infoSezione?.nome}
                    </span>
                )
            }
        },
        {
            label: 'Codice a barre',
            nome: 'codBarre',
            altCol : (original)=> {
                //console.log(original , 'original'); 
                return(
                    <span className="d-block p-0 m-0" style={{width: '10%'}} >
                        {original?.codBarre}
                    </span>
                )
            }
        },
        {
            label: 'Codice articolo',
            nome: 'codiceArt',
            altCol : (original)=> {
                //console.log(original , 'original');
                return(
                    <span className="d-block p-0 m-0" style={{width: '15%'}} >
                        {original?.codiceArt}
                    </span>
                )
            }
        },
        {
            label: 'Iva',
            nome: 'iva',
            altCol : (original)=> {
                //console.log(original , 'original');
                return(
                    <span className=" p-0 m-0">
                        {original?.iva}
                    </span>
                )
            }
        },
        {
            label: 'Prezzo non ivato',
            nome: 'prezzoUnitario',
        },
        {
            label: 'Costo',
            nome: 'costo',
        },
        {
            label: 'Quantità',
            nome: 'quantita',

        }

    ]

    return(
        <div>
            <div className="border border-primary border-3 p-3 rounded-4 m-3 d-flex flex-wrap">
                <div className="w-100 d-flex flex-wrap" >
                    {!cliente ? <>
                        <h3 className="text-primary col-10" >Anagrafica fornitore</h3>
                        
                        {campiAnagrafica.map((item , key)=>{ 
                            return(
                                <span className="col-6 " key={key}>
                                    {item.label + ' : '+ (inputs.infoFornitore[item.name] ? inputs.infoFornitore[item.name] : '')}
                                </span>
                            )
                        })}    
                    </>
                    :
                    <>
                        <h3 className="text-primary col-10 d-flex align-items-center" >
                        <FontAwesomeIcon className="me-3 text-primary" icon={faUser} />
                            Anagrafica cliente
                        </h3>
                        <div className="ms-5 ps-3 d-flex align-items-center flex-wrap w-75">
                            <span className="col-12">
                                {!!inputs?.infoCliente?.ragioneSociale ? inputs?.infoCliente?.ragioneSociale : inputs?.infoCliente?.nome +' '+inputs?.infoCliente?.cognome }
                            </span>
                            <span className="col-12">
                                {inputs?.infoCliente?.indirizzo+ ', '+ inputs?.infoCliente?.numeroCivico + ', ' + inputs?.infoCliente?.comuneResidenza + ' (' +inputs?.infoCliente?.provincia + '), ' +inputs?.infoCliente?.cap}
                            </span>
                            <span className="col-3">P.iva {inputs?.infoCliente?.pIva} </span>
                            <span className="col-3">Tel. {inputs?.infoCliente?.numeroTelefono1} </span>
                        </div>
                        {/* {campiAnagrafica.map((item , key)=>{ 
                            return(
                                <span className="col-6 " key={key}>
                                    {item.label + ' : '+ (inputs.infoCliente[item.name] ? inputs.infoCliente[item.name] : '')}
                                </span>
                            )
                        })}     * */}
                    </>
                    }
                </div>
            </div>

            <div className="border border-primary border-3 p-3 rounded-4 m-3 w-100  d-flex flex-wrap">
                <div className="w-75 d-flex flex-wrap">
                <h3 className="text-primary col-10 d-flex align-items-center" >
                    <FontAwesomeIcon className="me-3 text-primary" icon={faFileLines} />
                    DDT N° {inputs?.numero + ' del ' +convertiData(inputs?.dataFattura) }
                </h3>
                <div className="ms-5 ps-3">
                    <div className="col-12 d-flex">
          
                        <div className="col-6">
                            <span className="text-primary">
                                Destinatario :
                            </span>
                            {!!cliente ?<>
                            <div className="d-flex flex-column">
                                <span>Ragione sociale :  {!!inputs?.infoCliente?.ragioneSociale ? inputs?.infoCliente?.ragioneSociale +', ' : inputs?.infoCliente?.nome +' '+inputs?.infoCliente?.cognome +', ' }</span>
                                <span>Indirizzo : {inputs?.infoCliente?.indirizzo} </span>
                                <span>Numero civico : {inputs?.infoCliente?.numeroCivico} </span>
                                <span>Comune : {inputs?.infoCliente?.comuneResidenza} </span>
                                <span>Provincia : {inputs?.infoCliente?.provincia} </span>
                                <span>Cap : {inputs?.infoCliente?.cap} </span>
                            </div>
                            </>
                            :
                            <>
                            <div className="flex flex-column">
                                <span>
                                    {!!inputs?.infoFornitore?.ragioneSociale ? inputs?.infoFornitore?.ragioneSociale +', ' : inputs?.infoFornitore?.nome +' '+inputs?.infoFornitore?.cognome +', ' }
                                </span>
                                <span>Indirizzo : {inputs?.infoFornitore?.indirizzo} </span>
                                <span>Numero civico : {inputs?.infoFornitore?.numeroCivico} </span>
                                <span>Comune : {inputs?.infoFornitore?.comuneResidenza} </span>
                                <span>Provincia : {inputs?.infoFornitore?.provincia} </span>
                                <span>Cap : {inputs?.infoFornitore?.cap} </span>
                            </div>
                            </>}

                        </div>
                        <div className="col-6 offset-1">
                            {inputs?.indirizzoDestinatario ?
                            <div>
                                <span className="text-primary">
                                    Destinazione :
                                </span>
                                
                                {!!cliente ?<>
                                    <div className="d-flex flex-column">
                                        <span>Ragione sociale :  {!!inputs?.infoCliente?.ragioneSociale ? inputs?.infoCliente?.ragioneSociale +', ' : inputs?.infoCliente?.nome +' '+inputs?.infoCliente?.cognome +', ' }</span>
                                        <span>Indirizzo : {inputs?.infoCliente?.indirizzo} </span>
                                        <span>Numero civico : {inputs?.infoCliente?.numeroCivico} </span>
                                        <span>Comune : {inputs?.infoCliente?.comuneResidenza} </span>
                                        <span>Provincia : {inputs?.infoCliente?.provincia} </span>
                                        <span>Cap : {inputs?.infoCliente?.cap} </span>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="flex flex-column">
                                        <span>
                                            {!!inputs?.infoFornitore?.ragioneSociale ? inputs?.infoFornitore?.ragioneSociale +', ' : inputs?.infoFornitore?.nome +' '+inputs?.infoFornitore?.cognome +', ' }
                                        </span>
                                        <span>Indirizzo : {inputs?.infoFornitore?.indirizzo} </span>
                                        <span>Numero civico : {inputs?.infoFornitore?.numeroCivico} </span>
                                        <span>Comune : {inputs?.infoFornitore?.comuneResidenza} </span>
                                        <span>Provincia : {inputs?.infoFornitore?.provincia} </span>
                                        <span>Cap : {inputs?.infoFornitore?.cap} </span>
                                    </div>
                                </>}
                            </div>
                            : <p className="text-primary">Destinazione e destinatario compaciano</p>
                            }
                        </div>
                    </div>
                </div>
                    {/* {Object.keys(campiDettagliBolla).map((item, key)=> {
                        return(
                            <>
                                {campiDettagliBolla[item].map((item2 , key2)=> {
                                    const valore = inputs[item2.name] ? (item === 'date' ? convertiData(inputs[item2.name]) : inputs[item2.name]) : ''
                                    return(
                                        <span className="col-6" key={key2} >
                                            {item2.label + ' : '+ valore}
                                        </span>
                                    )
                                })}
                            </>
                        )
                    })} */}
                </div>
            </div>

            
            <div className="border border-primary border-3 p-3 rounded-4 m-3 d-flex flex-wrap">
                <div className="col-12" >
                    <h3 className="text-primary col-12" >
                        <FontAwesomeIcon  className="me-3 text-primary" icon={faBoxesStacked} />
                        Dettagli articoli DDT 
                    </h3>
                    <span style={{fontSize: '0.8rem'}} className="p-auto col-12" >
                        <TableSemp
                            colonne={colonne}
                            dati={inputs.prods}
                            infoAdd={
                                <div className="col-12 my-5 d-flex justify-content-end" >
                                    <div className=" btn btn-primary h-100 m-2 ms-4">Imponibile : € {inputs?.imponibileCent && inputs?.imponibileCent/100} </div>
                                    <div className="col-2 btn btn-primary h-100 m-2 ms-4">Imposta : € {inputs?.impostaCent && inputs?.impostaCent/100} </div> 
                                    <div className="col-2 btn btn-primary h-100 m-2 ms-4">Importo : € {!!inputs?.totFatturaCent &&inputs?.totFatturaCent/100} </div> 
                                </div>
                            }
                        />
                    </span>
                </div>
            </div>

            {/* <div className="border border-primary border-3 p-3 rounded-4 m-3 d-flex flex-wrap">
                <div className="w-25"> <FontAwesomeIcon className="h-75 m-5 text-primary" icon={faMoneyCheckDollar} /> </div>
                <div className="w-75 d-flex flex-wrap">
                    <h3 className="text-primary col-10" >Dettagli pagamento bolla </h3>
                    <span className="col-6">
                        Banca di appoggio : {inputs.bancaAppoggio ? inputs.bancaAppoggio : ''}
                    </span>

                    <span className="col-6">
                        Totale fattura : {inputs.totFatturaCent ? (parseInt(inputs.totFatturaCent)/100) : ''}
                    </span>

                    <span className="col-6">
                        Totale pagamento fattura : {inputs.totPagCent ? (parseInt(inputs.totPagCent)/100) : ''}
                    </span>

                    <span className="col-6">
                        Sconto in percentuale fattura : {inputs.scontoPerc ? inputs.scontoPerc : ''}
                    </span>
                    <span className="col-6">&nbsp;</span>
                    <span className="col-6">&nbsp;</span>
                    <span className="col-6">&nbsp;</span>
                    <span className="col-6">&nbsp;</span>
                </div>

            </div> */}

            
        </div>

    )
}