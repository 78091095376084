




export default function CheckForm({
    option , // [{nome , label , disabled }]
    onChange,
    values
}) {
    
    console.log(option, ' option');
    return(
        <div>
            {option.map((item , key) => {
                return(
                <div className="form-check form-check-inline">
                    <input 
                        className="form-check-input" 
                        type="radio" 
                        name="exampleRadios" 
                        id={item.nome} 
                        value={item.nome}  
                        onClick={()=>onChange(item.nome , !values[item.nome])}
                        checked={!!values[item.nome]} 
                        disabled={item.disabled || false}
                    />
                    <label className="form-check-label" for={item.nome}>
                        {item.label}
                    </label>
                </div>
                )
            })}
        </div>
    )
}