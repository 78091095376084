import React, { useEffect, useState } from 'react';
import FormError from './FormError';
import QuestionModal from './QuestionModal';

export default function ConfermaEliminazione({
    nome,
    stato = 0 ,
    conferma ,
    error ={}
}) {

    const resp = (stato) => {
        if (stato) {
            conferma(true )
        }else {
            stato = 0
             conferma(false )
        }
    }

    return(
        <div>
            {stato &&<QuestionModal
                title={'Elimina ' + nome }
                text={
                    <div>
                        <h4>
                            Conferma eliminazione ?
                            <FormError  
                                errors={error} 
                                field='elimina'
                                classi='fs-6 d-block m-2'
                            />
                        </h4>
                    </div>
                }
                onClose={resp}
            />}

        </div>
    )
}