import React from "react";
import Tabella from "../../riutilizabili/Tabella";
import { daCentEu } from "../../riutilizabili/daEurCent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

export default function TabellaBolle({
    array = [] ,
    Pulsanti ,
    FiltraBolla ,
    onChangeFiltraBolla
}) {
    console.log('====================================');
    console.log(FiltraBolla,  !FiltraBolla?.data ,' FiltraBolla');
    console.log('====================================');
    const columns = [
        {
            Header: 'Prot.',
            accessor: 'id',
            altro : <div>
            {
                !FiltraBolla?.orderMoreRecente ? 
                <button className="btn" onClick={()=>onChangeFiltraBolla('orderMoreRecente', 'DESC')}>
                    <FontAwesomeIcon  icon={faSort} />
                </button>
                :(
                    FiltraBolla?.orderMoreRecente === 'DESC' ?
                    <button className="btn" onClick={()=>onChangeFiltraBolla('orderMoreRecente', 'ASC')}>
                        <FontAwesomeIcon icon={faSortUp} />
                    </button>
                    :
                    <button className="btn" onClick={()=>onChangeFiltraBolla('orderMoreRecente', null)}>
                        <FontAwesomeIcon icon={faSortDown} />
                    </button>
                )
            }
        </div>
        },
        {
            Header: 'numero',
            accessor: 'numero'
        },
        {
            Header: 'descrizione',
            accessor: 'descrizione',
        },
        {
            Header: 'Data',
            accessor: 'dataFattura',
            Cell : ({row : { original}}) => {
                const arrayData = original?.dataFattura.split('-');
                return(
                    <>
                        {arrayData[2]+ '/'+arrayData[1]+'/'+arrayData[0]}
                        
                    </>
                )
            },
            altro : <div>
            {
                !FiltraBolla?.dataOrder ? 
                <button className="btn" onClick={()=>onChangeFiltraBolla('dataOrder', 'DESC')}>
                    <FontAwesomeIcon  icon={faSort} />
                </button>
                :(
                    FiltraBolla?.dataOrder === 'DESC' ?
                    <button className="btn" onClick={()=>onChangeFiltraBolla('dataOrder', 'ASC')}>
                        <FontAwesomeIcon icon={faSortUp} />
                    </button>
                    :
                    <button className="btn" onClick={()=>onChangeFiltraBolla('dataOrder', null)}>
                        <FontAwesomeIcon icon={faSortDown} />
                    </button>
                )
            }
        </div>
        },
        {
            Header: 'Fornitore',
            accessor: 'fornitore',
            Cell : ({row : { original}}) => {
                return(
                    <>
                       {!!original.infoFornitore && Object.keys(original.infoFornitore).length > 0 && <>
                            {!!original.infoFornitore.ragioneSociale ? original.infoFornitore.ragioneSociale : original.infoFornitore.nome+' '+original.infoFornitore.cognome}
                        </>}
                    </>
                )
            }
        },
        {
            Header: 'Peso totale in kg',
            accessor:'totPesoKg'
        },
        {
            Header: 'Porto',
            accessor:'porto'
        },
        {
            Header: 'N. colli',
            accessor:'nColli'
        },
        {
            Header: 'Totale DDT',
            Cell : ({row : { original}}) => {
                return(
                    <>
                        {daCentEu(original.totFatturaCent)}
                    </>
                )
            }
        },
    ]
    
    return(
        <div className="w-100" >
            <Tabella
                data={array}
                columns={columns}
                Pulsanti={Pulsanti}
            />
        </div>
    )
}