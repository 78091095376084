import React, { useState, useEffect } from 'react';
import InputForm from '../riutilizabili/forms/InputForm';

export default function RicercaCampiGruppi({
    classiDiv , 
    values , 
    onChange , 
    errors
}) {

    return(
        <div className={classiDiv}>
            <InputForm
                label='Nome' 
                type='text' 
                placeholder='Inserisci nome'
                name='nome'
                value={values.nome ? values.nome : ''}
                onChange={(name , value) => onChange(name , value)}
                classi=''
                errors={errors}
            />

            <InputForm
                label='Descrizione' 
                type='text' 
                placeholder='Inserisci descrizione'
                name='descrizione'
                value={values.descrizione ? values.descrizione : ''}
                onChange={(name , value) => onChange(name , value)}
                classi='w-50'
                errors={errors}
            />
        </div>
    )
}