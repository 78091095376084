import React, { useState, useEffect ,useRef } from 'react';
import caricaFile_fetch from '../fetch/caricaFile_fetch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassPlus } from '@fortawesome/free-solid-svg-icons';

import Carousel from 'react-bootstrap/Carousel';
import Modal from "react-bootstrap/Modal";

export default function ImgTabella({
    prodId  
}) {
    const [imgs , setImgs]= useState([])
    const [imgProfile , setImgProfile]= useState(false)
    const [zoom, setZoom]= useState(false)
    const [carousel, setCarousel]= useState(false)
    

    useEffect(()=>{
        //console.log('test')
        serchProd()
      },[prodId])
    
    const serchProd = async () => {
        const arrayFoto = await caricaFile_fetch.visualizzaFotoProd(prodId);
        //console.log(arrayFoto.data.filter(item=> item.default === 1))
        setImgs(arrayFoto.data)
        setImgProfile(arrayFoto.data.filter(item=> item.default === 1))
    }

    const zoomHover = (event) => {
        //console.log('entra', zoom); 
        if(zoom !== true) {
            //localStorage.setItem('viewProd'+prodId , true)
            setZoom(true)
        }
    }

    const zoomHoverfalse = (event) => {
        //console.log('esce'); 
        if(zoom !== false) {
            //localStorage.setItem('viewProd'+prodId , false)
            setZoom(false)
        }
    }

    //console.log('zoom' , zoom); 
    return(
        <div>
            {imgs.length > 0 && 
            <div 
                className='w-100 position-relative' 
                onMouseEnter={()=>zoomHover()} 
                onMouseLeave={zoomHoverfalse} 
            >
            {!!imgProfile && <img className={!zoom ? 'w-100' : 'w-100 opacity-25'} src={'data:'+imgProfile[0]?.tipo+';base64,'+imgProfile[0]?.scr}   alt="" />}
                {!!zoom &&<FontAwesomeIcon 
                    className='position-absolute fs-1' 
                    style={{
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }} 
                    icon={faMagnifyingGlassPlus} 
                    onClick={()=>(setCarousel(true))}
                />}

                    {/* {!!carousel && <div
                        className="modal show w-25"
                        style={{ display: 'inline-block'  }}
                        >
                        
                            <Carousel>
                                {imgs.map(img=>{
                                    return(
                                    <Carousel.Item>
                                        <img className='w-100'  src={'data:'+img?.tipo+';base64,'+img?.scr}   alt="" />
                                    </Carousel.Item>
                                    )
                                })}
                            </Carousel>
            
                    </div>}  */}

              
                        <Modal 
                            show={!!carousel}
                            onHide={()=>setCarousel(false)}
                            size="xl"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                            className='d-inline'
                        >
                            <Carousel>
                                {imgs.map(img=>{
                                    return(
                                    <Carousel.Item key={img.id}>
                                        <img  className='w-100'  src={'data:'+img?.tipo+';base64,'+img?.scr}   alt="" />
                                    </Carousel.Item>
                                    )
                                })}
                            </Carousel>
                        </Modal>

            </div>}
        </div>
    )
}