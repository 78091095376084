import React, { useState } from 'react'
import SelezionaProdItem from '../../SelezionaProdItem'
import CampiProdotti from '../../CampiProdotti';


export default function AnagraficaProdotto({
    inputs = {},
    onChange,
    errors = {}
}) {
    const [viewSelezionaProd, setViewSelezionaProd] = useState(false);
    const [prodGen, setProdGen] = useState(null);

    const selezionaProd = (prod) => {
        setProdGen(prod);
        onChange('infoProd', prod)
        Object.keys(prod).forEach(element => {
            if (element !== 'id'
                && element !== 'userCreate'
                && element !== 'userLastmod'
                && element !== 'stato'
                && element !== 'dataCreate'
                && element !== 'dataLastmod'
            ) {
                onChange(element, prod[element])
            }
        })
        onChange('categoria_nome', prod?.infoCategoria?.nome)
        onChange('fornitore_nome', prod?.infoFornitore?.nome)
        setViewSelezionaProd(false);
    }

    const onChangeCampi = (nome, valore) => {
        let tmp = { ...inputs };
        if (!tmp?.infoProd) {
            tmp.infoProd = {}
        }
        tmp.infoProd[nome] = valore;
        onChange(nome, valore)
        onChange('infoProd', tmp.infoProd)
    }
    return (
        <div className="border border-primary border-3 p-3 rounded-4 m-3" >
            <h3 className="text-primary ps-1 ms-4" >Anagrafica articolo </h3>
            <div className='ps-1'>
                <button className="btn btn-primary ms-4" onClick={() => setViewSelezionaProd(true)}>
                    Seleziona anagrafica articolo
                </button>
            </div>
            {!!viewSelezionaProd &&
                <SelezionaProdItem
                    onSucces={(prod, stato) => selezionaProd(prod)}
                    onCancel={() => setViewSelezionaProd(false)}
                    prodSeleCtId={[prodGen?.id]}
                />}

            <CampiProdotti
                onChange={onChangeCampi}
                value={inputs}
                errors={errors}
                classiDiv='d-flex flex-wrap p-3 w-100'
            />
        </div>
    )
}