import impostazioni from "../components/cofig";
import http from "./controllerFetch";


class loginService {
    postLogin(data){
        return http.post('login',data)
    }

    mostraMenu(){
        return http.get('mostraMenu')
    }
    
    mostraRotte(){
        return http.get('mostraRotte')
        // console.log(window.location.href.substring(window.location.href.length-5, window.location.href.length)=== 'login');
        // if (!!window.location.href.substring(window.location.href.length-5, window.location.href.length)=== 'login') {
        //     return http.get('mostraRotte')
        // }else {
        //     return false
        // }
    }
}

export default new loginService();