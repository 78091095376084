import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap';

export default function Attendere() {

    return (
        <>         
            <Modal.Dialog className='d-inline bg-white border border-primary p-4 rounded border-3' style={{ top: '33%' , position: 'fixed', left: '44%' , zIndex: '1'}}>
                <Modal.Body>
                    <div className="container text-center">
                        <h3 className='m-2' >Attendere</h3>
                        <div className="d-flex justify-content-center">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal.Dialog> 
        </>
    )
}