import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import anCliente from '../fetch/anCliente_fetch';
import permessi from '../fetch/permessi_fetch';
import user_fetch from '../fetch/user_fetch';
import SelezionaUser from '../riutilizabili/SelezionaUser';

export default function CreaAssPermessoUser({id , onChangeUser }) {
    const navigate = useNavigate();
    const [datiAn, setDatiAn] = useState([]);
    const [datiPerm, setDatiPerm] = useState([]);
    const [user, setUser] = useState([]);
    const [error, setError] = useState([]);
    const [selezionaUserView, setSelezionaUserView] = useState(false);
    const [utentiDaEscludere, setUtentiDaEscludere] = useState([]);
    
    
    useEffect( ()=>{
        readAn()
        readPermessi()
        readUtentiAssociati()
      },[])

    const readAn = async () => {
        await anCliente.getAll()
        .then(response => {
            setDatiAn(response.data);
        })
        .catch(error => {
            //console.log( error);
            //console.log(error.status === 406);
            if (error.status === 406) {
                navigate("/login");;
            }else{
                setError([error.data])
            }
        })
    }

    const readPermessi = async () => {
        await permessi.get({id:id})
        .then(response => {
            //console.log(response.data , ' readPermessi');
            setDatiPerm(response.data[0])
        })
        .catch(error => {
            //console.log( error);
            //console.log(error.status === 406);
            if (error.status === 406) {
                navigate("/login");;
            }else{
                setError([error.data])
            }
        })
    }

    const onCancelSelezionaUser = () => {
        setSelezionaUserView(false)
    }

    const onSuccessSelezionaUser = async (userId) => {
        await user_fetch.get({id: userId})
        .then(user => {
            if (user) {
                //console.log(user.data[0] , 'user');
                setUser(user.data[0])
                onChangeUser(user.data[0].id)
                setSelezionaUserView(false)
            }
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");;
            }else{
                setError(error.response.data)
            }
        })
    }

    const readUtentiAssociati = async () =>{
        await permessi.getAssociazione({permessoId : id, stato : 1})
        .then(response => {
            //console.log(response.data , ' response 33');
            const id = []
            for (let index = 0; index < response.data.length; index++) {
                const element = response.data[index];
                id.push({id : element.userId})
            }
            setUtentiDaEscludere(id)
        })
        .catch(error => {
            //console.log(error , ' errori modifica');

            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }

    //console.log(utentiDaEscludere , ' utentiDaEscludere');

    return(
        <div>
            <p>
                <span className='fw-bold' >Permesso :</span> {datiPerm.nome}
            </p>
            {selezionaUserView ?
                 <SelezionaUser 
                    onCancel={onCancelSelezionaUser} 
                    onSuccess={(userId)=>onSuccessSelezionaUser(userId)} 
                    utentiDaEscludere={utentiDaEscludere}
                 /> 
                 :
                 <>
                    <button className='btn btn-primary' onClick={()=>setSelezionaUserView(true)} >
                        Seleziona user
                    </button>
                    {user?.username && <span className='ms-4'> {user.username}</span>}
                 </>
            }
        </div>
    )
}