import React from "react";
import Tabella from "../riutilizabili/Tabella";
import { daCentEu } from "../riutilizabili/daEurCent";



export default function TabellaProdotti({
    array,
    Pulsanti
}) {
    
    const columns = React.useMemo(
    () => [
        {
        Header: 'Nome',
        accessor: 'nome',
        Cell : ({row : { original}}) => {
            return(
                <>
                    {original?.infoProd?.nome}
                </>
            )
        }
        },
        {
        Header: 'Codice a barre',
        accessor: 'codBarre',
        },
        {
            Header: 'Unità di misura',
            accessor: 'umId',
            Cell : ({row : { original}})=> {
                console.log(original , ' original');
                
                return original?.infoUnitaMisura?.nome
            }
            },
        {
        Header: 'Quantità iniziale',
        accessor: 'quantita',
        },
        {
        Header: 'Quantità residua',
        accessor: 'quantitaResidua',
        },
        {
        Header: 'Prezzo',
        accessor: 'prezzoUnitarioCent',
        Cell : ({row : { original}}) => {
            return(
                <>
                    {daCentEu(original.prezzoUnitarioCent)}
                </>
            )
        }
        },
        {
        Header: 'Sezione',
        accessor: 'infoSezione',
        Cell : ({row : { original}}) => {
            let percorso =''
            if (!!original.infoSezione) {
                percorso = original?.infoSezione?.nome
                let og = original.infoSezione
                while (og.padreId !== null) {
                    percorso = og.sezionePadre.nome+ ' / '+percorso
                    og = og.sezionePadre
                }
            }
            return(
                <>
                    {!!original.infoSezione ? percorso : 'Sezione non assegnata'}
                </>
            )
        }
        },
    ],
    []
    )

    return(
        <div className="w-100 m-4" >
            <Tabella
                data={array}
                columns={columns}
                Pulsanti={Pulsanti}
            />
        </div>
    )
}