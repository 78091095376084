import React, { useState, useEffect } from 'react';
import InputForm from '../riutilizabili/forms/InputForm';
import TextAreaForm from '../riutilizabili/forms/TextAreaForm';

export default function CampiPermessi({classiDiv , value , onChange , errors}) {

    
    return(
        <div className={classiDiv}>
            <InputForm 
                label='Nome' 
                type='text' 
                placeholder='Inserisci nome'
                name='nome'
                value={value.nome ? value.nome : ''}
                onChange={(name , value) => onChange(name , value)}
                classi=''
                errors={errors}
                //errors={{'nome' : 'errore di prova'}}
            />
            <InputForm 
                label='URL' 
                type='text' 
                placeholder='Inserisci url'
                name='url'
                value={value.url ? value.url : ''}
                onChange={(name , value) => onChange(name , value)}
                classi=''
                errors={errors}
                //errors={{'nome' : 'errore di prova'}}
            />
            <div>
                <TextAreaForm
                    label = 'Descrizione'
                    name = 'descrizione' 
                    value = {value.descrizione ? value.descrizione : ''}
                    onChange ={(name , value) => onChange(name , value)}
                    classi = ''
                    errors ={errors}
                />
            </div>

        </div>
    )
}