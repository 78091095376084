import React , { useEffect, useState } from "react"
import SelezionaProdSezione from "../../prodotti/SelezionaProdSezione.js"
import QuestionModal from "../../riutilizabili/QuestionModal"
import funzioniRicorrenti from "../../riutilizabili/funzioniRiccorrenti"
import Tabella from "../../riutilizabili/Tabella"
import InputForm from "../../riutilizabili/forms/InputForm"
import FormError from "../../riutilizabili/FormError"
import ImgTabella from "../../prodotti/ImgTabella"
import { daCentEu, daEuCent } from "../../riutilizabili/daEurCent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTrash } from "@fortawesome/free-solid-svg-icons"
import TableSemp from "../../riutilizabili/TableSemp"
import parseInputFloatValue from "../../riutilizabili/forms/parseInputFloatValue.js"


export default function ProdottiBollaUscita({
    onChange,
    inputs,
    errors
}) {
    const [arrayProds , setArrayProds] = useState([])
    const [viewSelectProds , setViewSelectProds] = useState(false)
    const [selectProds , setSelectProds] = useState([])

    useEffect(()=> {
        if (inputs?.prods) {
            console.log(inputs.prods , ' inputs.prods');
            
            setSelectProds(inputs.prods)
        }
    },[inputs,selectProds])

    useEffect(()=>{
        calcTotFattura()
        //console.log('entro b');
    },[inputs?.prods])

    console.log(inputs , ' inputs DettagliPagamento');

    const calcTotFattura = async () => {
        let totale = 0;
        let totaleIvato = 0;
        let totaleIva = 0;
        if (!!inputs?.prods) {
            for (let index = 0; index < inputs.prods.length; index++) {
                const element = inputs.prods[index];
                totale += element.prezzoUnitarioCent*element.quantita;

                let prezzoUnitarioMil = element.prezzoUnitarioCent*10
                console.log(prezzoUnitarioMil , ' prezzoUnitarioMil');
                let iva = ((prezzoUnitarioMil/100)*element.iva)*element.quantita
                console.log('totale iva : ', iva);
                totaleIva += iva
                let prezzoUnitarioMilIvato = (prezzoUnitarioMil*element.quantita) +iva
                console.log(prezzoUnitarioMilIvato , ' prezzoUnitarioMilIvato');
                totaleIvato += prezzoUnitarioMilIvato
                //console.log(element.prezzoUnitarioCent);
            }
        }
        //onChange('totFatturaCent', totale)
        onChange('totFatturaCent',funzioniRicorrenti.arrotondaNumero(totaleIvato/1000 , 5) )
        onChange('totPagCent',funzioniRicorrenti.arrotondaNumero(totaleIvato/1000 , 5) )
        onChange('imponibileCent',funzioniRicorrenti.arrotondaNumero(totale/100 , 5) )
        onChange('impostaCent', funzioniRicorrenti.arrotondaNumero(totaleIva/1000 , 5) )
        
        console.log(totaleIvato/1000 , totaleIva/1000 ,totale);

    }


    const cambiaPrezzo = (prodotto , valoreNew) => {
        const posizioneProd = selectProds.indexOf(prodotto);
        //console.log(posizioneProd , ' posizioneProd');
        let tpm = [...selectProds]
        tpm[posizioneProd].prezzoUnitarioCent = daEuCent(valoreNew) ;
        tpm[posizioneProd].prezzoUnitario = valoreNew ;
        setSelectProds(tpm)
        onChange('prods',tpm)
    }

    const cambiaQt = (prodotto , valoreNew ) => {
        //console.log(prodotto , valoreNew);
        if (valoreNew) {
           // console.log(prodotto , valoreNew  , ' prodotto , valoreNew , quantitaResidua');
            const posizioneProd = selectProds.indexOf(prodotto);
          //  console.log(posizioneProd , ' posizioneProd');
            let tpm = [...selectProds]
            // tpm[posizioneProd].quantita = quantitaResidua ;
          //  console.log(tpm ,' tpm[posizioneProd].quantita');
            tpm[posizioneProd].quantita = parseInt(valoreNew) ;
            setSelectProds(tpm)
            onChange('prods',tpm)
            
        }
        
    }

    const selezionaProdotto = (value)=>{
        const idProd = selectProds.map(prod =>prod.id)
        const posizioneSeEsiste = idProd.indexOf(value.id)
        if (posizioneSeEsiste === -1) {
            let tpm = {...value}
            tpm.quantita = 1
            setSelectProds(values => ([...values, tpm]));
            onChange('prods',[...selectProds ,tpm])
        }else {
            let tpm = [...selectProds]
            tpm.splice(posizioneSeEsiste, 1)
            setSelectProds(tpm)
            onChange('prods', tpm)
        }
    }

    const cambiaRicarico = ( prodotto , valore) =>{
        let valoreNew = parseInputFloatValue({value : valore})
        console.log(valoreNew , 'valoreNew');
        if (!!valoreNew) {
            let tpm = [...selectProds]
            console.log(tpm , ' tpm')
            const posizioneProd = tpm.indexOf(prodotto);
            tpm[posizioneProd].ricarico = valoreNew ;
            const prezzoConRicarico =tpm[posizioneProd].infoProd.costoCent +((tpm[posizioneProd].infoProd.costoCent * parseFloat(valoreNew))/100)
            //const prezzoConRicarico =tpm[posizioneProd].costoCent +((tpm[posizioneProd].costoCent * parseFloat(valoreNew))/100)
            tpm[posizioneProd].prezzoUnitarioCent = prezzoConRicarico * parseInt(tpm[posizioneProd].quantita)
            tpm[posizioneProd].prezzoUnitario = daCentEu(prezzoConRicarico * parseInt(tpm[posizioneProd].quantita))
            setSelectProds(values => ([...values, tpm]));
            onChange('prods', tpm)
        } else {
            let tpm = []
            console.log(selectProds, 'selectProds');

            selectProds.forEach(element => {
                let temp = {...element} ;
                console.log(temp , ' temp');
                const ricarico = parseFloat(!!temp?.ricarico ? temp?.ricarico : (!!inputs?.anRicarico ?  parseFloat(inputs?.anRicarico) : 0)) 
                const prezzoConRicarico =temp.infoProd.costoCent +((temp.infoProd.costoCent * ricarico)/100)
                temp.prezzoUnitarioCent = prezzoConRicarico * parseInt(temp.quantita)
                temp.prezzoUnitario = daCentEu(prezzoConRicarico * parseInt(temp.quantita))
                if (temp.id === prodotto.id ) {
                    temp.ricarico = valoreNew;
                }
                tpm.push(temp)
            });
            // const posizioneProd = tpm.indexOf(prodotto);
            // console.log(tpm[posizioneProd] , posizioneProd , ' tpm[posizioneProd]');
            // tpm[posizioneProd].ricarico = valoreNew;
            //setSelectProds(values => ([...values, tpm]));
            onChange('prods', tpm)
        }

    }

    const cambiaPrezzoProd = (prodotto , valoreNew) => {
        console.log(valoreNew );
        
            let tpm = [...selectProds];
            const posizioneProd = tpm.indexOf(prodotto);
            tpm[posizioneProd].prezzoUnitario = parseInputFloatValue({value : valoreNew});
            tpm[posizioneProd].prezzoUnitarioCent = daEuCent(parseInputFloatValue({value : valoreNew}));
            onChange('prods', tpm)
        
    }

    const colonne = [
        {
            label: 'Immagine',
            nome: 'img',
            altCol : (original)=> {
                return(
                    <div style={{width: '10rem'}} className='m-auto' >
                        <ImgTabella prodId={original?.infoProd?.id} />
                    </div>
                )
            }
        },
        {
            label: 'Nome',
            nome: 'nome',
            altCol : (original)=> {
                return(
                    <span className="d-block p-0 m-0" style={{width: '10rem'}} > {original?.nome} </span>
                )
            }
        },
        {
            label: 'Seriale',
            nome: 'serialnumber',
            altCol : (original)=> {
                return(
                    <span className=" p-0 m-0" > {original?.serialnumber} </span>
                )
            }
        },
        {
            label: 'Sezione',
            nome: 'infoSezione',
            altCol : (original)=> {
                //console.log(original , 'original');
                return(
                    <span className=" p-0 m-0">
                        {original?.infoSezione?.nome}
                    </span>
                )
            }
        },
        {
            label: 'Codice a barre',
            nome: 'codBarre',
            altCol : (original)=> {
                //console.log(original , 'original');
                return(
                    <span className=" p-0 m-0">
                        {original?.codBarre}
                    </span>
                )
            }
        },
        {
            label: 'Codice articolo',
            nome: 'codiceArt',
            altCol : (original)=> {
                //console.log(original , 'original');
                return(
                    <span className=" p-0 m-0">
                        {original?.codiceArt}
                    </span>
                )
            }
        },
        {
            label: 'Ricarico (%)',
            nome: 'ricarico',
            altCol : (original)=> {
                console.log(original , 'original ricarico');
                return(
                    <span className=" p-0 m-0">
                        {/* <input
                            name={'ricarico'}
                            type='text'
                            value={!!original?.ricarico ? original?.ricarico : (!!inputs?.anRicarico ?  parseFloat(inputs?.anRicarico) : 0)}
                            onChange={(event)=> cambiaRicarico(original , event.target.value)}
                            className="rounded h-100 w-50 text-center"
                        /> */}
                        <InputForm
                            label=' '
                            name={'ricarico'}
                            placeholder=''
                            type='text'
                            value={original?.ricarico}
                            onChange={(nome , valore)=> cambiaRicarico(original ,valore)}
                            className="rounded h-100 w-50 text-center"
                            errors={{}}
                        />
                    </span>
                )
            }
        },
        {
            label: 'Prezzo non ivato',
            nome: 'prezzoUnitario',
            altCol : (original)=> {
                console.log(original , 'original ricarico');
                return(
                    <span className=" p-0 m-0">
                        {/* <input
                            name={'prezzoUnitario'}
                            type='number'
                            defaultValue={'0'}
                            value={original?.prezzoUnitarioCent/100}
                            onChange={(event)=> cambiaPrezzoProd(original , event.target.value)}
                            className="rounded h-100 w-50 text-center"
                        /> */}
                        <InputForm
                            label=' '
                            name={'prezzoUnitario'}
                            placeholder=''
                            type='text'
                            value={original.prezzoUnitario}
                            onChange={(nome , valore)=> cambiaPrezzoProd(original ,valore)}
                            className="rounded h-100 w-50 text-center"
                            errors={{}}
                        />
                    </span>
                )
            }
        },
        {
            label: 'Iva',
            nome: 'iva',
            altCol : (original)=> {
                //console.log(original , 'original');
                return(
                    <span className=" p-0 m-0">
                        {original?.iva}
                    </span>
                )
            }
        },
        {
            label: 'Quantità',
            nome: 'quantita',
            altCol : (original)=> {
             //   console.log(original , 'original f' ,original?.prezzoVendita);
                return(
                    <div>

                    {original?.quantita >=0 &&<input
                            name={'quantita'}
                            type='number'
                            defaultValue={'0'}
                            value={!!original.quantita ? original.quantita : 0}
                            max={!!original?.quantitaResidua && original?.quantitaResidua }
                            onChange={(event)=> cambiaQt(original , event.target.value)}
                            className="rounded h-100 w-50 text-center"
                        />}
                        <FormError errors={errors} field={'quantita'} />
                    </div>
                )
            }
        },
        {
            label: 'Elimina',
            nome: 'Elimina',
            altCol : (original)=> {
                return(
                    <>
                        <button onClick={()=>selezionaProdotto(original)} className="btn text-danger p-0">
                            <FontAwesomeIcon icon={faTrash} />
                        </button>
                    </>
                )
            }
        }

    ]

    console.log(inputs , ' inputs');

    return(
        <div  className="border border-primary border-3 p-3 rounded-4 m-3 ">
            <div className="d-flex flex-wrap align-items-center ms-4 mt-5">
                <button className="btn btn-primary me-4" onClick={()=>setViewSelectProds(true)} >Seleziona Articoli</button>
                <p className="p-0 m-0 text-danger fst-italic" >*Selezionare almeno un articolo per salvare </p>
            </div>

            {/* <Tabella
                data={selectProds}
                columns={columns}
            /> */}
            <TableSemp
                colonne={colonne}
                dati={selectProds}
                infoAdd={
                    <div className="col-12 my-5 d-flex justify-content-end" >
                        <div className=" btn btn-primary h-100 m-2 ms-4">Imponibile : € {inputs?.imponibileEur} </div>
                        <div className="col-2 btn btn-primary h-100 m-2 ms-4">Imposta : € {inputs?.impostaEur} </div> 
                        <div className="col-2 btn btn-primary h-100 m-2 ms-4">Importo : € {!!inputs?.totFatturaCent &&inputs?.totFatturaCent/100} </div> 
                    </div>
                }
            />

            {viewSelectProds &&<QuestionModal
                title={'Seleziona articoloz'}
                text={
                    <SelezionaProdSezione prodIdSelezionati={selectProds.map(item => item.id)} onChange={selezionaProdotto} />
                }
                onClose={()=>{setViewSelectProds(false); cambiaRicarico(false , false) }}
            />}

        </div>
    )
}
