import React, { useEffect, useState } from "react";
import prodotti_fetch from '../fetch/prodotti_fetch';
import { useNavigate } from "react-router-dom";
import InfoModal from "../riutilizabili/InfoModal";
import Tabella from "../riutilizabili/Tabella";
import Switch2 from "../riutilizabili/Switch2";
import ImgTabella from "./ImgTabella";
import InputForm from "../riutilizabili/forms/InputForm";

export default function SelezionaProdItem({ 
    onCancel ,
    onSucces ,
    prodSeleCtId=[] ,
    data ,
    prodIdEsc =[]
}) {
    const [dati, setDati] = useState([]);
    const [listReady, setListReady] = useState(false);
    const navigate = useNavigate();

    useEffect( ()=>{
        filtraProds({})
    },[])

    const filtraProds =  (param)=> {
        console.log(param ,' param filtraProds');
        
         prodotti_fetch.get(param)
        .then(prods=> {
            console.log('qui si ', prods)
            if (prods.data.length === 0) {
                setDati([])
                setListReady(true)
            }else {
                if (prodIdEsc.length > 0) {
                    let prodsOut = []
                    if (prods.data.length > 0) {
                        for (let key = 0; key < prods.data.length; key++) {
                            const element = prods.data[key];
                            if (prodIdEsc.some(code => code === element.id) === false) {
                                prodsOut.push(element)
                            }
                            if (prods.data.length === key+1) {
                                setDati(prodsOut)
                                setListReady(true)
                            }
                            
                        }
                    }else {
                        setDati(prodsOut)
                        setListReady(true)
                    }
                } else {
                    //console.log(prods.data , ' prods');
                    setDati(prods.data);
                    setListReady(true)
                    
                }
            }
        })
        .catch(error => {
            console.log('qui no ')
            if (error.response.status === 406) {
                navigate("/login");
            }
        })
    }

    const handleClose = () => {
        onCancel();
    };

    
    const [inputs, setInputs] = useState({});
    
    const cerca = (event) => {
        event.preventDefault();
        //console.log(inputs);
        filtraProds(inputs)
        
    }
    const azzera = (event) => {
        event.preventDefault();
        setInputs({})
        filtraProds({})
    
    }
    const handleChange = (event ,name , value) => {
        event.preventDefault();
        setInputs(values => ({...values, [name]:  value}))
        cerca(event)

    }
    
    // const Filtri = ()=> {
        
    //     return(
    //         <form >
                            
    //         {/* <div className="mb-3">
    //             <label htmlFor="nome" className="form-label">Nome</label>
    //             <br/>
    //             <input name="nome" type="text" id="nome" aria-describedby="emailHelp" onChange={handleChange} />
    //         </div> */}
    //         <div className="mb-3 d-flex">
    //         <div className={"m-3 w-25"}>
    //             <label htmlFor='nome' className="form-label">Nome </label>
    //             <input 
    //                 value={inputs?.nome} 
    //                 type='text'
    //                 className={"form-control "} 
    //                 name='nome' 
    //                 id='nome' 
    //                 placeholder=''
    //                 onChange={(event) => {handleChange(event ,'nome', event.target.value) }}
    //                 aria-describedby="validationServer03Feedback"
    //                 onChangeValue={cerca}
    //             />
    //         </div>

    //         <div className="mb-3 d-flex">
    
    //         <div className={"m-3 w-25"}>
    //             <label htmlFor='codBarre' className="form-label">Codice a barre </label>
    //             <input 
    //                 value={inputs?.codBarre} 
    //                 type='text'
    //                 className={"form-control "} 
    //                 name='codBarre' 
    //                 id='codBarre' 
    //                 placeholder=''
    //                 onChange={(event) => handleChange(event ,'nome', event.target.value)}
    //                 aria-describedby="validationServer03Feedback"
    //             />
    //         </div>
    //             <InputForm
    //                 label='Codice interno'
    //                 type='text'
    //                 placeholder=''
    //                 name='rifInterno'
    //                 value={inputs?.rifInterno}
    //                 onChange={ (name , value) =>{handleChange(name , value); cerca() }}
    //                 errors={{}}
    //                 classi='w-25'
    //             />
    //         </div>

    //         </div>
    //         <button onClick={cerca} className="btn btn-primary m-2">cerca</button>
    //         <button onClick={azzera} className="btn btn-secondary m-2">azzera</button>
    //     </form>
    //     )
    // }
    const columns = React.useMemo(
    () => [
            {
                Header: 'Immagine',
                accessor: 'img',
                Cell : ({row : { original}}) => {
                    return(
                        <div style={{width: '10rem'}} className='m-auto' >
                        <ImgTabella prodId={original.id} />
                        </div>
                    )
                }
            },
            {
                Header: 'Nome',
                accessor: 'nome',
            },
            {
                Header: 'descrizione',
                accessor: 'descrizione',
            },
            {
                Header: 'EAN',
                accessor: 'ean',
            },
            {
                Header: 'IVA',
                accessor: 'iva',
            },
    ],
    []
    )

    const selezionaProd = (stato , prodId)=> {
        console.log(stato , prodId , ' stato , prodId');
        onSucces(prodId , stato )

    }

    const Pulsanti = (id) => {
        const element = id.id.original;
        console.log(element.id , ' s ', prodSeleCtId);
        const stato = prodSeleCtId.includes(element.id)
        return(
            <td>
                <Switch2
                    stato={stato}
                    number={element}
                    onChangeValue={selezionaProd}
                />
            </td>
        )
    }
  


    return(
        <InfoModal
            title='Seleziona articoli'
            text={
                <div>
                    <form >      
                        <div className="mb-3 d-flex">
                            <div className={"m-3 w-25"}>
                                <label htmlFor='nome' className="form-label">Nome </label>
                                <input 
                                    value={inputs?.nome || ''} 
                                    type='text'
                                    className={"form-control "} 
                                    name='nome' 
                                    id='nome' 
                                    placeholder=''
                                    onChange={(event) => {handleChange(event ,'nome', event.target.value) }}
                                    aria-describedby="validationServer03Feedback"
                                    onChangeValue={cerca}
                                />
                            </div>
                
                            <div className="mb-3 d-flex">
                    
                            <div className={"m-3 w-25"}>
                                <label htmlFor='codBarre' className="form-label">Codice a barre </label>
                                <input 
                                    value={inputs?.codBarre} 
                                    type='text'
                                    className={"form-control "} 
                                    name='codBarre' 
                                    id='codBarre' 
                                    placeholder=''
                                    onChange={(event) => handleChange(event ,'nome', event.target.value)}
                                    aria-describedby="validationServer03Feedback"
                                />
                            </div>
                                <InputForm
                                    label='Codice interno'
                                    type='text'
                                    placeholder=''
                                    name='rifInterno'
                                    value={inputs?.rifInterno}
                                    onChange={ (name , value) =>{handleChange(name , value); cerca() }}
                                    errors={{}}
                                    classi='w-25'
                                />
                            </div>
                        </div>
                        {/* <button onClick={cerca} className="btn btn-primary m-2">cerca</button> */}
                        <button onClick={azzera} className="btn btn-secondary m-2 float-end mb-5">azzera</button>
                    </form>
                    <Tabella
                        columns={columns}
                        data={dati}
                        Pulsanti={Pulsanti}
                    />
                </div>
            }
            size='xl'
            canClose
            handleClose={handleClose}
            closeLabel='Inserisci selezionati'
        />
    )

}