import axios from "axios";
import cofig from '../../cofig.js'


const underSsl = (window?.location?.href || 'https://').substr(0, 'https://'.length) === 'https://';
const accessToken = localStorage.getItem('accessToken');
const refreshToken = !underSsl ? localStorage.getItem('refreshToken') : '';

const options = {
  baseURL: cofig.api_url,
  headers: {
    "Content-type": "application/json",
  }
};
//with credentials solo se su ssl
//const underSsl = config.backend.baseURL.substr(0, 'https://'.length) === 'https://';
if (underSsl) {
  options.withCredentials = true;
}

const instance = axios.create(options);


//manda access_token a tutte le richieste tranne accessoutente
instance.interceptors.request.use(function (config) {
  if (config.api_url !== 'login') {
    config.headers.Authorization = 'Bearer '+ accessToken;
  }
  return config;
});


const refreshAccessToken = () => {
  const data = {
    'refreshToken': refreshToken 
  };
  return instance.post(cofig.api_url+"refresh", data);

}

//se hai come risultato 401
instance.interceptors.response.use((response) => {
  return response
}, async function (error) {
  const originalRequest = error.config;
  //console.log(originalRequest , ' originalRequest');
  if (error?.response?.status === 403) {
      //gestire il forbidden
      return error.response;
    }
    if (error?.response?.status === 401) {
      localStorage.removeItem('username')
      window.location.href = '/login';
      throw error;
    }
    if (error?.response?.status === 406 && !originalRequest._retry) {
        originalRequest._retry = true;
        //console.log(originalRequest._retry, ' originalRequest._retry 2');
    try {
      //console.log(error.response.status , ' error?.response');
      const refreshReply = await refreshAccessToken();
      //console.log('di' , refreshReply)
      //console.log(refreshReply , ' refreshReply');
      //Salva token aggiornati
      localStorage.setItem('accessToken', refreshReply.data.accessToken);
      //rimanda richiesta
      originalRequest.headers.Authorization = accessToken;
      return axios(originalRequest)
    } catch (err) {
      //Se non riesci ad aggiore il token, sessione scaduta
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('statoMenu')
      window.location.href = '/login';
      throw error;
    }
  } else {
    //rilancia gli errori che non sono il primo 401
    throw error;
  }

});

export default instance;