import http from "./controllerFetch";

class setupDDT {
    getCausale() {
        return http.get('setupDDT/readCausale');
    }
    getAspettoBeni() {
        return http.get('setupDDT/readAspettoBeni');
    }
}

export default new setupDDT();