import { faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';


export default function PopError({error}) {
    const [errors , setErrors] = useState(error) 

    const deleteError = (key) => {
        const array = [...errors]
        //console.log(key , array);
        array.splice(key, 1);
        setErrors(array)
    }


    return(
        <>
            {errors.length > 0 && <div className='fixed-bottom position-fixed w-100'>
                {errors.map((item , key) => {
                    return(
                        <div className="alert alert-danger align-items-center d-flex justify-content-between ms-1" role="alert">
                            <div className='d-flex'>
                                <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:">
                                    <FontAwesomeIcon icon={faTriangleExclamation} />
                                </svg>
                                <div>
                                    {item}
                                </div>
                            </div>
                            <div>
                                <button className='btn button_fix' >
                                    <FontAwesomeIcon className='fs-3' icon={faTimes} onClick={()=>deleteError(key)} />
                                </button>
                            </div>
                        </div>
                    )
                })}
            </div>}

        </>
    )
    
}