import http from "./controllerFetch";

class anFornitore {

  getAll() {
    return http.get('anagraficaFornitore/readAnagrafica');
  }

  get( params = {}) {
    return http.get('anagraficaFornitore/readAnagrafica', { params });
  }

  create(data) {
    return http.post('anagraficaFornitore/creaAnagrafica', data);
  }

  update( data) {
    return http.put('anagraficaFornitore/updataAnagrafica', data);
  }
  tipoAn( params={}) {
    console.log(params , ' params');
    return http.get('anagraficaFornitore/tipoAnagrafica', {params});
  }
  autocompliteAn(  params = {}) {
    return http.get('anagraficaFornitore/autocompliteAn',  { params });
  }

  elimina(data) {
    return http.delete('anagraficaFornitore/eliminaAnagrafica', {data});
  }


}

export default new anFornitore();