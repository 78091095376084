import React, { useEffect, useState } from 'react';

export default function SelectLivello ({
    lettere = false ,
    name ,
    value = false ,
    onChange ,
    label ,
    maxLenght ,
    classi
}) {
    const [inputs, setInputs] = useState([]);

    useEffect( ()=>{
        generaArrey()
    },[])

    const generaArrey = () => {
        const arraylivello = []
        if (lettere) {
            const alfabeto = ["a","b","c","d","e","f","g","h","i","j","k","l","m","n","o","p","q","r","s","u","v","w","x","y","z"]
            for (let index = 0; index < maxLenght+1; index++) {
                const element = alfabeto[index-1];
                arraylivello.push(element) 
            }
            
        }else {
            //console.log('numeri ' , maxLenght);
            for (let index = 1; index < maxLenght+1; index++) {
                arraylivello.push(index) 
            }
        }
        //console.log(arraylivello , ' arraylivello');
        setInputs(arraylivello)
    }

    return (
        <div className={"m-3 "+classi}>
            <label htmlFor={name} className="form-label"> {label} </label>
            <select 
                name={name} 
                className="form-control "
                aria-label="Default select example"
                value={value}
                onChange={(event) => onChange(name, event.target.value)}
            >
                {inputs.map((item , key) =>{
                    return(
                        <option 
                            key={key} 
                            value={item}
                        >
                            {item}
                        </option>
                    )
                })}
            </select>
        </div>
    )
}