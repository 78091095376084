
//parserizza un valore float inserito dall'utente. punto e virgola vegono tradotti in virgola
const parseInputFloatValue = ({
    value, //valore inserito dall'utente
    decLenMax = 2, //max length parte decimale
    intLenMax = 9, //max length parte intera
    onlyPositive = true, // solo positivi
}) => {
    let out = '';
    let pd = false;
    let decLen = 0;
    for (let i = 0; i < value.length; i++) {
        const v = value.substr(i, 1);
        if (v.match(/[0-9]/)) {
            if (pd) {
                //inserisco parte decimale
                if (decLen < decLenMax) {
                    out += v;
                    decLen++;
                }
            } else {
                //inserisco parte intera
                if (out.replace('-', '').length < intLenMax) {
                    out += v;
                }
            }
        } else if ((v === '.' || v === ',') && !pd) {
            //inserisco punto decimale (uso virgola)
            pd = true;
            out += ',';
        } else if (v === '-' && !onlyPositive && out.length === 0) {
            //inserisco i lmeno
            out += v;
        }
    }
    return out;
}

export default parseInputFloatValue;