import { faEdit, faEye, faFile, faFileInvoiceDollar, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {useEffect, useState } from "react";
import PulsantiFiltra from "../../riutilizabili/PulsantiFiltra.js";
import bolla_fetch from "../../fetch/bolla_fetch.js";
import { daEuCent } from "../../riutilizabili/daEurCent.js";
import QuestionModal from "../../riutilizabili/QuestionModal.js";

import FiltraBollaUscita from './FiltraBollaUscita.js'

import TabellaBolleUscita from './TabellaBolleUscita.js'
import CampiBollaUscita from './CampiBollaUscita.js'
import funzioniRiccorrenti from "../../riutilizabili/funzioniRiccorrenti.js";
import InfoModal from "../../riutilizabili/InfoModal.js";
import ReadCampiBolla from "../sezioni/ReadCampiBolla.js";
import PaginazioneTitoloPulsanteCrea from "../../riutilizabili/PaginazioneTitoloPulsanteCrea.js";
import file_fetch from "../../fetch/file_fetch.js";
import { format } from "date-fns";
import Attendere from "../../riutilizabili/Attendere.js";

export default function ViewBollaUscita(onChangeCreaV) {
    const [inputs , setInputs] = useState({dataFattura : format(new Date(), 'yyyy-MM-dd') , dataOraPartenza :format(new Date(), "yyyy-MM-dd'T'HH:mm")});
    const [inputsModifica , setInputsModifica] = useState({});
    const [inputsFiltra , setInputsFiltra] = useState({});
    const [viewBolla , setViewBolla] = useState(false);
    const [errors , setErrors] = useState({});
    const [viewCrea , setViewCrea] = useState(false);
    const [viewModifica , setViewModifica] = useState(false);
    const [arrayBolla , setArrayBolla] = useState([]);
    const [statoAvanti , setStatoAvanti] = useState(false);
    const [statoAvantiMod , setStatoAvantiMod] = useState(false);
    const [campiObSegnale , setCampiObSegnale] = useState({});

    const [viewAttendere , setViewAttendere] = useState(false);

    console.log(onChangeCreaV, ' onChangeCreaV');

    useEffect(()=>{
        readBolle()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    useEffect(()=> {
        if (!!onChangeCreaV.onChangeCrea) {
            setViewCrea(true) 
        }
    },[!!onChangeCreaV])

    const campiObbligatori = [
        'dataFattura',
    ]

    const onChangeStatoAvanti = ()=> {
        // const tpm = funzioniRiccorrenti.pulisciCampiAn(inputs)
        // setStatoAvanti(funzioniRiccorrenti.abilitaAvanti(campiObbligatori , tpm , campiObSegnale , setCampiObSegnale))
        const error = funzioniRiccorrenti.validazioneCampi(campiObbligatori , inputs);
        console.log(error);
        if (Object.keys(error).length > 0) {
            if (error['anIndirizzo']) {
                error['indirizzo'] = error['anIndirizzo']
            }
            if (error['anProvincia']) {
                error['provincia'] = error['anProvincia']
            }
            if (error['anCodiceFiscale']) {
                error['codiceFiscale'] = error['anCodiceFiscale']
            }
            setCampiObSegnale(error)
            setStatoAvanti(false)
        }else {
            setCampiObSegnale({})
            setStatoAvanti(true)
        }
    }

    const onChangeStatoAvantiMod = ()=> {
        // const tpm = funzioniRiccorrenti.pulisciCampiAn(inputs)
        // setStatoAvanti(funzioniRiccorrenti.abilitaAvanti(campiObbligatori , tpm , campiObSegnale , setCampiObSegnale))
        const error = funzioniRiccorrenti.validazioneCampi(campiObbligatori , inputsModifica);
        console.log(error);
        if (Object.keys(error).length > 0) {
            if (error['anIndirizzo']) {
                error['indirizzo'] = error['anIndirizzo']
            }
            if (error['anProvincia']) {
                error['provincia'] = error['anProvincia']
            }
            if (error['anCodiceFiscale']) {
                error['codiceFiscale'] = error['anCodiceFiscale']
            }
            setCampiObSegnale(error)
            setStatoAvanti(false)
        }else {
            setCampiObSegnale({})
            setStatoAvanti(true)
        }
    }

    useEffect(()=>{
        onChangeStatoAvanti()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inputs])

    useEffect(()=>{
        onChangeStatoAvantiMod()
        // setStatoAvantiMod(funzioniRiccorrenti.abilitaAvanti(campiObbligatori , funzioniRiccorrenti.pulisciCampiAn(inputsModifica) , campiObSegnale , setCampiObSegnale))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[inputsModifica])

    const readBolle = async () => {
        const array = await bolla_fetch.getBollaUscita();
        setInputsFiltra({})
        setArrayBolla(array.data)
    }

    const filtra = async (param) => {
        const array = await bolla_fetch.getBollaUscita({...inputsFiltra, ...param});
        setArrayBolla(array.data)
    }

    
    const onChangeValue = (nome , valore) => {
        //console.log(nome , valore , ' nome , valore');
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputs(values => ({ ...values, [nome]: cent }))
            setInputs(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputs(values => ({ ...values, [nome]: valore }))
        }
        setStatoAvanti(funzioniRiccorrenti.abilitaAvanti(campiObbligatori , funzioniRiccorrenti.pulisciCampiAn(inputs) , campiObSegnale , setCampiObSegnale))
    }
    
    const onChangeModifica = (nome , valore) => {
        //console.log(nome , valore , ' nome , valore');
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputsModifica(values => ({ ...values, [nome]: cent }))
            setInputsModifica(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputsModifica(values => ({ ...values, [nome]: valore }))
        }

        setStatoAvantiMod(funzioniRiccorrenti.abilitaAvanti(campiObbligatori , funzioniRiccorrenti.pulisciCampiAn(inputsModifica) , campiObSegnale , setCampiObSegnale))
    }
    
    const onChangeValueFiltra= (nome , valore) => {
        //console.log(nome , valore , ' nome , valore');
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputsFiltra(values => ({ ...values, [nome]: cent }))
            setInputsFiltra(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputsFiltra(values => ({ ...values, [nome]: valore }))
        }
        filtra({ [nome]: valore })
    }

    const scaricaPdf = async (bollaId) => {
        await file_fetch.scaricaAllegato({bollaId})
    }

    const scaricaPdfNoPrezzi = async (bollaId) => {
        await file_fetch.scaricaAllegatoNoPrezzi({bollaId})
    }
    

    const Pulsanti = (id) => {
        //console.log(id.id.original ,' id.id.original');
        return(
            <td>
                <button className='btn button_fix' title="Visualizza bolla" >
                    <FontAwesomeIcon className='fs-4' onClick={()=>setViewBolla(id.id.original)} icon={faEye} />
                </button>
  
                <button className='btn button_fix' title="Modifica bolla" >
                    <FontAwesomeIcon className='fs-4' icon={faEdit} onClick={()=>recuperaDatiModBolla(id.id.original)} />
                </button>
  
                <button className='btn button_fix' title="Pdf bolla con prezzi" >
                    <FontAwesomeIcon className='fs-4' icon={faFileInvoiceDollar} onClick={()=>scaricaPdf(id.id.original.id)} />
                </button>
                <button className='btn button_fix' title="Pdf bolla senza prezzi" >
                    <FontAwesomeIcon className='fs-4' icon={faFilePdf} onClick={()=>scaricaPdfNoPrezzi(id.id.original.id)} />
                </button>
            </td>
        )
    }

    const recuperaDatiModBolla = (dati) => { 
        let oggetto = {}
        console.log(dati , 'dati');
        Object.keys(dati).forEach(element => {
            //console.log();
            switch (element) {
                case 'infoCliente':
                    Object.keys(dati.infoCliente).forEach(element2 => {
                     
                        oggetto['anNominazioneCliente'] =dati?.infoCliente?.viewName
                        if (element2 === 'infoTipoAnCliente') {
                            oggetto['anTipoAnaNome'] = dati.infoCliente.infoTipoAnCliente.nome
                            oggetto['anTipo'] = dati.infoCliente.infoTipoAnCliente.id
                        }else {
                            oggetto['an'+element2.charAt(0).toUpperCase()+element2.slice(1)] = dati.infoCliente[element2]
                        }
                    });
                break;
                case 'infoTipoPag' :
                    oggetto.tipoPag = dati?.infoTipoPag?.nome
                break;
                case 'dataOraPartenza' :
                    if (!!dati[element]) {
                        const formattedDate = format(dati[element], "yyyy-MM-dd'T'HH:mm")
                        oggetto[element] = formattedDate
                    }
                break;
            
                case 'prods':
                    if (dati.prods.length) {
                        console.log(dati.prods , ' dati.prods');
                        
                        oggetto.prods= []
                        dati.prods.forEach(element2 => {
                            if (element2.stato === 1) {
                                Object.keys(element2.infoProd).forEach(element3 => {    
                                    if (element3 !== 'id') {
                                        element2[element3] = element2.infoProd[element3]
                                    }
                                })
                                oggetto.prods.push(element2)
                            }
                        })

                    }
                break;
            
            
                default:
                    oggetto[element] = dati[element]
                break;
            }
        });
        console.log(oggetto , 'oggetto');
        setInputsModifica(oggetto)
        setViewModifica(true)
    }

    const onChangeCreaBolla = (stato) => {
        if (stato) {
            setViewAttendere(true)
            setViewCrea(false)
            bolla_fetch.creaBollaUscita(inputs)
            .then(response => {
                setInputs({})
                readBolle()
                setViewAttendere(false)
            }).catch(errors => {
                setViewCrea(true)
                setErrors(errors.response.data)
                //console.log(errors.response.data , ' bollaUscita errori');
            })
       
        
            
        }else {
            setViewCrea(false)
            setViewAttendere(false)
        }
    }

    const onCloseCreaMod = (stato) => {
        if (stato) {
            setViewAttendere(true)
            setViewModifica(false)
            bolla_fetch.modBollaUscita(inputsModifica)
            .then(response => {
                setInputsModifica({})
                readBolle()
                setViewAttendere(false)
            }).catch(errors => {
                setViewModifica(true)
                setErrors(errors.response.data)
                //console.log(errors.response.data , ' bollaUscita errori');
            })
       
        
            
        }else {
            setViewModifica(false)
            setViewAttendere(false)
        }
    }

    //console.log(inputs , ' inputss');

    return(
        <div className="p-3">
            <PaginazioneTitoloPulsanteCrea titolo={'Ddt in uscita'} funCrea={()=> setViewCrea(true)} />

            <div className="border_filtra p-3 m-5" >
                <FiltraBollaUscita inputs={inputsFiltra} onChangeValue={onChangeValueFiltra} />
         
                <PulsantiFiltra
                    filtra={filtra}
                    azzera={readBolle}
                />
            </div>

            <div className='d-flex justify-content-around flex-wrap w-100'>
                <TabellaBolleUscita
                    array={arrayBolla}
                    Pulsanti={Pulsanti}
                />
            </div>

            
            {viewCrea &&<QuestionModal
                title={'Aggiungi ddt in uscita'}
                text={
                    <CampiBollaUscita
                        inputs={inputs} 
                        errors={{...errors , ...campiObSegnale}}
                        onChange={onChangeValue}
                        />
                   }
                YesLabel='Salva'
                yesDisabled={!statoAvanti}
                onClose={onChangeCreaBolla}
            />}

            
            {!!viewModifica && <QuestionModal
                title={<div className="ms-4 ps-2" >Modifica ddt {'N.'+inputsModifica.id+'/'+inputsModifica.annoId}</div>}
                text={
                    <div>
                        <CampiBollaUscita onChange={onChangeModifica} inputs={inputsModifica} errors={{...errors , ...campiObSegnale}} />
                    </div>
                }
                yesDisabled={!statoAvantiMod}
                onClose={onCloseCreaMod}
            />}

            {!!viewBolla && 
                <InfoModal
                    title={<span className="ms-4 ps-2" > DDT n° {viewBolla.numero} </span>}
                    text={
                        <ReadCampiBolla inputs={viewBolla} cliente={true} />
                    }
                    canClose
                    handleClose={()=>setViewBolla(false)}
                    className = 'modal-xl'
                />
            }
            { !!viewAttendere && <Attendere />}

        </div>
    )
}