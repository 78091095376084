import http from "./controllerFetch";

class anagraficaService {

  getAll() {
    return http.get('anagrafica/readAnagrafica');
  }

  get( params = {}) {
    return http.get('anagrafica/readAnagrafica', { params });
  }

  create(data) {
    return http.post('anagrafica/creaAnagrafica', data);
  }

  update( data) {
    return http.put('anagrafica/updataAnagrafica', data);
  }
  tipoAn( data) {
    return http.get('anagrafica/tipoAnagrafica', data);
  }

  elimina(data) {
    return http.delete('anagrafica/eliminaAnagrafica', {data});
  }

  autocompliteAn(  params = {}) {
    return http.get('anagrafica/autocompliteAn',  { params });
  }


}

export default new anagraficaService();