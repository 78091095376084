import sezioneService from "../fetch/classSezione";
import create_autocompleteWithService from "../riutilizabili/autocomplite/create_autocompleteWithService";
import GeneraCampiText from "../riutilizabili/forms/GeneraCampiText"
import SelectForm from "../riutilizabili/forms/SelectForm";
import Switch from "../riutilizabili/forms/Switch";


export default function FiltraSezioni({
    value ,
    onChange ,
    errors
}) {

    const campi = {
        text : [
            {
                name : 'nome',
                label : 'nome magazzino'
            },
 
        ],
        number : [
            {
                name : 'capacità',
                label : 'capienza'
            },
        ]
    }

    const UnMisuraAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => sezioneService.getUnMisura({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona unità di misura' ,
        value: value.nomeUnitaMisura ,
        onChange : ({value, item}) => {onChange('umId' , item?.id ? item.id : ''); onChange('nomeUnitaMisura' , item?.nome ? item.nome : '');onChange('umId' , item?.id ? item.id : '');},
        placeholder: 'inserisci nome sezione',
    });

    const SezioneAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => sezioneService.getAll({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona sezione padre' ,
        value: value.nomeSezionePadre ,
        onChange : ({value, item}) => { onChange('padreId' , item?.id ? item.id : ''); onChange('nomeSezionePadre' , item?.nome ? item.nome : '');onChange('padreId' , item?.id ? item.id : '');},
        placeholder: 'inserisci nome sezione padre',
    });

    <SelectForm
        label='Seleziona tipologia magazzino'
        name='mobile'
        arrayElement={[
            {id :0 , nome : 'Magazzino fisico'},
            {id :1 , nome : 'Magazzino mobile' },
        ]}
        onChange={onChange}
        errors={errors}
    />

    return(
        <div>
            <div className="d-flex align-items-center flex-wrap">
                <div className="pb-3 ps-3">
                    <GeneraCampiText
                        campi={campi}
                        inputs={value}
                        onChange={onChange}
                        errors={errors}
                    />
                </div>
                <div className="w-25 ps-2">
                    <UnMisuraAutocomplite/>
                </div>
                <div className="w-25 ps-2"><SezioneAutocomplite /></div>
                <Switch
                    number='magazzinoPrincipale'
                    stato={value?.magazzinoPrincipale}
                    label={'Solo magazzini'}
                    onChangeValue={(stato , nome)=>onChange(nome , stato)}
                    classe={'p-0'}
                    width="10%"
                />

            </div>

        </div>
    )
    
}