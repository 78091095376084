import classZone_fetch from '../../fetch/classZone_fetch';
import create_autocompleteWithService from '../autocomplite/create_autocompleteWithService';

const ComuneAutocomplite = create_autocompleteWithService({
    freeinput: false,
    service: (myValue, serviceValues) => classZone_fetch.comuneLike({
        nome: myValue,
        ...serviceValues,
    }),
    itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
    itemToLi: item => item.cap + ' ' + item.nome + ' (' + item.prov + ')',
    itemToString: item => item.nome,
    itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
    //label: 'Seleziona comune ',
    placeholder: 'inserisci comune',
});

export default ComuneAutocomplite ;