import { faBan, faCheck, faEdit, faEye, faPlus, faSearch, faTrash, faUserPlus, faUsers, faUsersGear } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import permessi_fetch from '../fetch/permessi_fetch'
import SelezionaGruppi from '../gruppi/SelezionaGruppi';

import InfoModal from '../riutilizabili/InfoModal';
import PulsantiFiltra from '../riutilizabili/PulsantiFiltra';
import QuestionModal from '../riutilizabili/QuestionModal';
import Tabella from '../riutilizabili/Tabella';
import CampiPermessi from './CampiPermessi';
import CreaAssPermessoUser from './CreaAssPermessoUser';


export default function ViewPermessi() {
    const [permessi , setPermessi] = useState([]);
    const [inputs, setInputs] = useState({});
    const [inputsFiltra, setInputsFiltra] = useState({});
    const [viewCreaPermesso, setViewCreaPermesso] = useState(false);
    const [viewPermesso, setViewPermesso] = useState(false);
    const [viewEditPermesso, setViewEditPermesso] = useState(false);
    const [viewUsersViewPermesso, setViewUsersViewPermesso] = useState(false);
    const [viewUserPlusPermesso, setViewUserPlusPermesso] = useState(false);
    const [viewUsersViewGruppo, setViewUsersViewGruppo] = useState(false);
    const [viewAddGroup, setViewAddGroup] = useState(false);
    const [datiAddGroup, setDatiAddGroup] = useState(false);
    const [idUserAssPermesso, setIdUserAssPermesso] = useState({});
    const [gruppiPermesso , setGruppiPermesso] = useState([]);
    const [error, setError] = useState({});

    useEffect( ()=>{
        readAllPermessi()
      },[])

    const navigate = useNavigate();

    const readAllPermessi = async () => {
        await permessi_fetch.getAll()
        .then(response => {
            //console.log(response.data);
            setPermessi(response.data)
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }

    const azzeraFiltra = async() => {
        await readAllPermessi();
        setInputsFiltra({})
    }

    const columns = React.useMemo(
        () => [

              {
                Header: 'Nome',
                accessor: 'nome',
              },
              {
                Header: 'Descrizione',
                accessor: 'descrizione',
              }
        ],
        []
      )

    const handleChange = (name , value) => {
        //console.log(name , value);
        setInputs(values => ({ ...values, [name]: value }))
    }

    const handleChangeFiltra = (name , value) => {
        //console.log(name , value);
        setInputsFiltra(values => ({ ...values, [name]: value }))
        filtra({[name]: value})
    }

    const creaPermesso = async (stato) => {
        if (stato) {
            await permessi_fetch.create({...inputs , stato : 1})
            .then(response => {
                readAllPermessi()
                setViewCreaPermesso(false)
                setInputs({})
            })
            .catch(error=> {

                if (error.response.status === 406) {
                    navigate("/login");;
                }else {
                    setError(error.response.data)

                }
                //console.log(error.response.data , ' error');

            })
        } else {
            setViewCreaPermesso(false)
        }
    }

    const editPermesso = async (stato) => {
        if (stato) {
            await permessi_fetch.update(inputs)
            .then(response => {
                readAllPermessi()
                setViewEditPermesso(false)
                setInputs({})
            })
            .catch(error => {
                //console.log(error , ' error read user group');
                if (error.response.status === 406) {
                    navigate("/login");
                }else {
                    setError(error.response.data)
    
                }
            })
        } else {
            setViewEditPermesso(false)
        }
    }
    const onChangeViewPermesso =async (id) => {
        await permessi_fetch.get({id : id})
        .then(response => {
            //console.log(response);
            setViewPermesso(response.data[0])
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }
    const onChangeEditPermesso =async (id) => {
        await permessi_fetch.get({id : id})
        .then(response=> {
            //console.log(response ,'response');
            setInputs(response.data[0])
            setViewEditPermesso(true)
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }
    const eliminaPermesso = async (id, stato) => {
        const nuovoStato = stato === 1 ? '0' : '1'

            await permessi_fetch.update({
                id : id,
                stato : nuovoStato
            })
            .then(response => {
                //console.log(response);
                readAllPermessi()
            })
            .catch(error => {
                //console.log(error , ' error read user group');
                if (error.response.status === 406) {
                    navigate("/login");
                }else {
                    setError(error.response.data)
    
                }
            })

    } 

    const Pulsanti = (id) => {
        //console.log(id.id.original.id , 'id');
        return(
            <td>
                <button className='btn button_fix' onClick={()=>onChangeViewPermesso(id.id.original.id)}>
                    <FontAwesomeIcon className='fs-4 text_bluDue' icon={faEye}/>
                </button>

                <button className='btn button_fix' onClick={() =>onChangeEditPermesso(id.id.original.id)}>
                    <FontAwesomeIcon className='fs-4 text_bluDue' icon={faEdit} />
                </button>

                <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4 text_bluDue' icon={faUsers} onClick={()=>readPermessi({permessoId : id.id.original.id})} />
                </button>

                <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4 text_bluDue' icon={faUserPlus} onClick = {()=>setViewUserPlusPermesso(id.id.original.id)}/>
                </button>

                <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4 text_bluDue' icon={faUsersGear} onClick = {()=>onChangeViewgruppiPermessi(id.id.original.id , id.id.original.nome)}/>
                </button>

                <button className='btn button_fix' >
                    {id.id.original.stato ?
                    <FontAwesomeIcon className='fs-4 text-danger' icon={faTrash} onClick = {()=>eliminaPermesso(id.id.original.id , id.id.original.stato)}/>
                    :
                    <FontAwesomeIcon className='fs-4 text-success' icon={faCheck} onClick = {()=>eliminaPermesso(id.id.original.id , id.id.original.stato)}/>
                    }
                </button>
            </td>
        )
    }

    const filtra = async (param) => {
        await permessi_fetch.get({...inputsFiltra , ...param})
        .then(response => {
            setPermessi(response.data)
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }

    const onChangeCreaAssPermessoUser = async (stato) => {
        // viewUserPlusPermesso
        // idUserAssPermesso
        //console.log(viewUserPlusPermesso , 'viewUserPlusPermesso');
        //console.log(idUserAssPermesso , 'idUserAssPermesso');
        if (stato) {
            await permessi_fetch.createAssociazione({
                permessoId : viewUserPlusPermesso,
                userId : idUserAssPermesso ,
                stato : 1
            })
            .then(response => {
                //console.log(response , ' response');
                setViewUserPlusPermesso(false)
            })
            .catch(error => {
                //console.log(error , ' error read user group');
                if (error.response.status === 406) {
                    navigate("/login");
                }else {
                    setError(error.response.data)
    
                }
            })
        }else {
            //console.log('qui');
            setViewUserPlusPermesso(false)
        }
    }

    const readPermessi = async (data) => {
        await permessi_fetch.getAssociazioneName({...data , stato : 1})
        .then(response=> {
            setViewUsersViewPermesso(response.data)
            //console.log(response.data , ' permessi');
        })
        .catch(async (error) => {
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                const permesso = await permessi_fetch.get({id: data.permessoId})
                //console.log(permesso.data[0].nome , ' error' , );
                setViewUsersViewPermesso([{
                    permesso : permesso.data[0].nome ,
                    utente : 'Nessun Utente associato'
                }])
            }
        })
    }

    const deleteAssocizioneGruppo = async(id) => {
        await permessi_fetch.updateAssociazioneGruppo({
            stato : 0,
            id : id
        })
        .then(async(response) => {
            //console.log(response , ' response');
            onChangeViewgruppiPermessi(viewUsersViewGruppo.id , viewUsersViewGruppo.nome)
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }

    const deleteAssocizione = async(id) => {
        await permessi_fetch.updateAssociazione({
            stato : 0,
            id : id
        })
        .then(response => {
            //console.log(response , ' response');
            readPermessi({
                permessoId :response?.data?.permessoId,
                stato : 1
            })
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
    }

    const onChangeViewgruppiPermessi = async (id, nome) => {
        //console.log(id , ' permesso id');
        await permessi_fetch.getAssociazioneGruppo({permessoId : id})
        .then(response => {

            setGruppiPermesso(response.data)
            
            setViewUsersViewGruppo({nome : nome , id : id})
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })


    }

    const aggiungiPermessoGruppo = async (id) => {

        await permessi_fetch.createAssociazioneGruppo({
            gruppoId : id ,
            permessoId : viewUsersViewGruppo.id ,
            stato : 1
        })
        .then(response => {
            setViewAddGroup(false)
            onChangeViewgruppiPermessi(viewUsersViewGruppo.id , viewUsersViewGruppo.nome)
        })
        .catch(error => {
            //console.log(error , ' error read user group');
            if (error.response.status === 406) {
                navigate("/login");
            }else {
                setError(error.response.data)

            }
        })
        
    }
    
    
    //console.log(viewPermesso);
    //console.log(datiAddGroup , ' datiAddGroup');
    //console.log(gruppiPermesso , ' gruppiPermesso');
    return(
        <div className='d-flex flex-column justify-content-around flex-wrap ps-3'>
            <div>
                <h3 className='text-primary ms-4 ps-3' >Permessi</h3>
            </div>
            <div className=' border border-3 border-primary m-3 p-2 rounded'>
                <h3 className='text-primary  ms-2 ps-1 ' >Filtra</h3>
                <CampiPermessi value={inputsFiltra} onChange={handleChangeFiltra} errors={{}} classiDiv={'d-flex align-items-center'} />
                <div className='ps-2'>
                    <PulsantiFiltra
                        filtra={filtra}
                        azzera={azzeraFiltra}
                    />
                </div>

            </div>


            <div>
                <button
                    className='border button_fix border-4 border-primary btn float-end fs-4 m-2 rounded-circle  text-primary' 
                    onClick={() =>setViewCreaPermesso(true)}  
                >
                    <span>
                    <FontAwesomeIcon icon={faPlus} />

                    </span>
                </button>
                <Tabella data={permessi} columns={columns} Pulsanti={Pulsanti} />
                {viewCreaPermesso && <QuestionModal 
                    title = 'Crea permesso'
                    text = {<CampiPermessi value={inputs} onChange={handleChange} errors={error} classiDiv={'d-flex align-items-center'} />}
                    YesLabel = 'Crea'
                    onClose = {creaPermesso}
                />}
                {viewPermesso && <InfoModal 
                    title='Leggi anagrafica permesso'
                    text = {
                        <div>
                            <p><span className='fw-bold'>Nome : </span>{viewPermesso.nome}</p>
                            {viewPermesso.url && <p><span className='fw-bold'>URL : </span>{viewPermesso.url}</p>}
                            <p><span className='fw-bold'>Descrizione : </span>{viewPermesso.descrizione}</p>
                        </div>
                    }
                    canClose={true}
                    handleClose={() =>setViewPermesso(false)}
                />}
                {viewEditPermesso && <QuestionModal 
                    title = 'Modifica permesso'
                    text = {<CampiPermessi value={inputs} onChange={handleChange} errors={error} classiDiv={'d-flex align-items-center'} />}
                    YesLabel = 'Modifica'
                    onClose = {editPermesso}
                />}

                {viewUserPlusPermesso && <QuestionModal 
                    title = 'Associa permesso ad utente'
                    text = {<CreaAssPermessoUser id={viewUserPlusPermesso} onChangeUser={(userId) => setIdUserAssPermesso(userId)} /> }
                    YesLabel = 'Crea associazione'
                    onClose = {(stato)=>onChangeCreaAssPermessoUser(stato)}
                />}

                {viewUsersViewPermesso && <InfoModal 
                    title={'Utenti con il permesso '+ viewUsersViewPermesso[0].permesso}
                    text={
                        <div>
                            <h3>Utenti assegnati : </h3>
                            <ul>
                                {viewUsersViewPermesso.map(element => {
                                    //console.log(element , ' element');
                                    return(
                                        <li>
                                            {element.utente} 
                                            {element.id && 
                                                <button className='btn' onClick={()=> deleteAssocizione(element.id)} >
                                                <FontAwesomeIcon icon={faBan} />
                                            </button>}
                                        </li>
                                    )
                                })}
                            </ul>

                        </div>
                    }
                    handleClose={()=>setViewUsersViewPermesso(false)}
                    canClose={true}
                    
                />}

                {viewUsersViewGruppo && <InfoModal 
                    title={'Gruppi con il permesso '+ viewUsersViewGruppo.nome}
                    text={
                        <div>
                            <h3>Gruppi assegnati : </h3>
                            <ul>
                                {gruppiPermesso.map(element => {
                                    console.log(element , ' element');
                                    return(
                                        <li>
                                            {element.infoGruppo.nome} 
                                            {element.id && 
                                                <button className='btn' onClick={()=> deleteAssocizioneGruppo(element.id)} >
                                                <FontAwesomeIcon icon={faBan} />
                                            </button>}
                                        </li>
                                    )
                                })}
                            </ul>

                           {!viewAddGroup ? 
                           <>
                                <button
                                    className='btn'
                                    onClick={()=> (setViewAddGroup(true))}
                                >
                                    <FontAwesomeIcon icon={faPlus} className='text-primary fs-1' />
                                </button>

                                {datiAddGroup && <p> {datiAddGroup.nome} </p>}
                           </>
                            :
                            <SelezionaGruppi 
                                onCancel={()=> (setViewAddGroup(false))}
                                onSuccess={(id , nome) => aggiungiPermessoGruppo(id ) }
                            />}

                        </div>
                    }
                    handleClose={()=>{setViewUsersViewGruppo(false); setGruppiPermesso([]) }}
                    canClose={true}
                />}
                
            </div>
    
        </div>
    )
}