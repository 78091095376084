import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";




export default function PaginazioneTitoloPulsanteCrea({
    titolo ,
    funCrea
}) {
    
    return(
        <div className="d-flex justify-content-between mx-3 p-5 mb-5">
        <h4 className="text-primary"> {titolo} </h4>
        <button className="btn btn-primary rounded-5 float-end" onClick={funCrea}>
            <FontAwesomeIcon icon={faPlus} />
        </button>
    </div>
    )
}