import React, { useState } from "react"
import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap';

/** 
 * messaggio mediante un modal
 * props: 
 *   se presente canClose posso chiudere? (TODO : cliccando fuori si chiude anche se canClose non e' stato passato)
 *   string title
 *   string text
 *   function  onClose gestisci chiusura
 */

function InfoModal({ 
    title, 
    text, 
    children, 
    onClose, 
    canClose, 
    noHeader, 
    noFooter, 
    alternativeFooter, 
    closeLabel = "Chiudi",
    handleClose,
     ...ps 
    }) {
    //console.log(ps ,' ');
    const [show, setShow] = useState(true);

    // const handleClose = () => {
    //     setShow(false);
    //     onClose && onClose();

    // }
    return (
        <Modal show={show} onHide={handleClose} animation={false} {...ps}>
            {!noHeader && <Modal.Header closeButton={canClose}>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>}
            <Modal.Body>{text}{children}</Modal.Body>
            {!noFooter && (
                <Modal.Footer>
                    {!!alternativeFooter
                        ? <>{alternativeFooter}</>
                        : <>
                            {canClose ? (<Button variant="primary" className="me-4" onClick={handleClose}>
                                {closeLabel}
                            </Button>) : ('')}
                        </>}
                </Modal.Footer>
            )}
        </Modal>
    );
}

export default InfoModal;