import React , { useEffect, useState } from "react";
import CampiSezioni from "./CampiSezioni";
import sezioneService from '../fetch/classSezione'
import QuestionModal from "../riutilizabili/QuestionModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faBoxesStacked, faCheck, faEdit, faEye} from "@fortawesome/free-solid-svg-icons";
import Tabella from "../riutilizabili/Tabella";
import TabellaProdotti from "../prodotti/TabellaProdotti";
import InfoModal from "../riutilizabili/InfoModal";
import FiltraSezioni from "./FiltraSezioni";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra";
import funzioniRiccorrenti from "../riutilizabili/funzioniRiccorrenti";
import PaginazioneTitoloPulsanteCrea from "../riutilizabili/PaginazioneTitoloPulsanteCrea";
import Switch2 from "../riutilizabili/Switch2";
import classSezione from "../fetch/classSezione";
import file_fetch from "../fetch/file_fetch";
import { faCircle } from "@fortawesome/free-regular-svg-icons";


export default function CreaSezione () { 
    const [inputs , setInputs] = useState({mobile : 0})
    const [inputsFiltra , setInputsFiltra] = useState({mobile : 0})
    const [errors , setErrors] = useState({})
    const [arraySez , setArraySez] = useState([])
    const [viewCreaSez , setViewCreaSez] = useState(false)
    const [viewModSez , setViewModSez] = useState(false)
    const [viewMostraProdSez , setViewMostraProdSez] = useState(false)
    const [arrayProdSez , setArrayProdSez] = useState([])
    const [disablePulsanteCrea , setDisablePulsanteCrea] = useState(true)
    const [viewInventario , setViewInventario] = useState(false)
    const [viewSezione , setViewSezione] = useState(false)
    const [magInventario , setMagInventario] = useState([])

    useEffect(()=>{readSez()},[])
    useEffect(()=>{verificaCampiObbligatoriCrea()},[inputs])

    const onChange = (nome ,valore)=>{
        setInputs(values => ({ ...values, [nome]: valore }))
        verificaCampiObbligatoriCrea()
    }

    const onChangeFiltra = (nome ,valore)=>{
        setInputsFiltra(values => ({ ...values, [nome]: valore }))
        filtraSezioni({[nome]: valore})
    }
    //console.log(inputs , ' inputs');

    const creaSez = async ()=> {
        let tmp = {...inputs}
        delete tmp.Prod_posizione_seziones
        const sez = await sezioneService.create(tmp)
        .then(response => {
            //console.log(response , ' response');
            return response
        })
        .catch(error => {
            //console.log(error , ' error');
            return error
        })
        return sez
    }

    const readSez = async () => {
        const sezioni = await sezioneService.get({stato : 1})
        //console.log(sezioni , ' sezionis');
        setInputsFiltra({})
        setArraySez(sezioni.data)
    }

    const filtraSezioni = async (param) => {
        const sezioni = await sezioneService.get({...inputsFiltra , ...param})
        //console.log(sezioni , ' sezionis');
        setArraySez(sezioni.data)
    }


    const onCloseCreate = async (stato) => {
        if (stato) {
            const sezione = await creaSez();
            if (sezione.status === 200) {
                await readSez()
                setViewCreaSez(false)
                setErrors({})
            }else {
                setErrors(sezione.data)
            }
        } else {
            setViewCreaSez(false)
            
        }
        setInputs({})
    }

    const onCloseModifica = async (stato) => {
        if (stato) {
            const sezione = await creaSez();
            //console.log(sezione , ' sezione mod');
            if (sezione.status === 200) {
                await readSez()
                setViewModSez(false)
                setInputs({})
            }else {
                setErrors(sezione.data)
            }
        } else {
            setViewModSez(false)
            setInputs({})
            
        }
    }

    const columns = React.useMemo(
    () => [
        

            {
            Header: 'Nome',
            accessor: 'nome',
            Cell : ({row : { original}}) => {
                return(
                    <span className={original.sezionePadre === null  ? 'fw-bold' : ''} >{original.nome} </span>
                )
            }
            },
            {
            Header: 'Descrizione',
            accessor: 'descrizione',
            },
            {
            Header: 'Capienza/Portata',
            accessor: 'capienza',
            Cell : ({row : { original}}) => {
                //console.log(original , 'original');
                return(
                    <>
                        {original?.capienza === 1000000000 ? 'Illimitato' : original.capienza}
                    </>
                )
            }
            },
            {
            Header: 'Unita misura',
            accessor: 'unitaMisura',
            },
            {
            Header: 'Tipo Magazzino',
            accessor: 'mobile',
            Cell : ({row : { original}}) => {
                //console.log(original , 'original');
                return(
                    <>
                        {original.mobile === 1 ? 'Magazzino mobile' : 'Magazzino fisico'}
                    </>
                )
            }
            },
            {
            Header: 'rfid',
            accessor: 'rfid',
            },
            {
            Header: 'Spazio modulare',
            accessor: 'modulare',
            Cell : ({row : { original}}) => {
                //console.log(original , 'original');
                return(
                    <>
                        {original.modulare === 1 ? <> <FontAwesomeIcon icon={faCheck} />  </> : <FontAwesomeIcon icon={faBan} />}
                    </>
                )
            }
            },
            {
                Header: 'Magazzino padre',
                accessor: 'sezionePadre',
                Cell : ({row : { original}}) => {
                    //console.log(original , 'original');
                    return(
                        <>
                            {original.sezionePadre === null ? 'Nessuno' 
                            : 
                            original.sezionePadre.nome
                            }
                        </>
                    )
                }
                },
    ],
    []
    )

    const mostraModifica = (element)=> {
        let dati = {...element}
        dati.nomeSezionePadre = dati?.sezionePadre?.nome;
        dati.nomeUnitaMisura = dati?.UnitaMisura?.nome; 
        //console.log(dati, 'dati');
        setInputs(dati);
        verificaCampiObbligatoriCrea()
        setErrors({})
        setViewModSez(true)
    }

    const onCloseMostraProd = () => {
        setViewMostraProdSez(false)
    }

    const mostraProdSez = (element) => { 
        const prodotti = [...element.Prod_posizione_seziones]
        //console.log(element , ' element');
        let prodottiAttivi =[]
        prodotti.forEach(prod => {
            if (prod.stato === 1) {
                prodottiAttivi.push(prod)
            }
        });
        //console.log(element , ' arrayProdSez 2');

        setArrayProdSez({prodotti : prodottiAttivi , sezioneFigli : element.sezioniFigli , nome : element.nome})
        setViewMostraProdSez(true)
    }

    const onChangeValueSelezionaMg = (stato , id) => {
        let tmp =[...magInventario]
        if (!!stato) {
            tmp.push(id)
        }else {
            const posizione = tmp.indexOf(id);
            tmp.splice(posizione , 1)
        }
        setMagInventario(tmp)
    }
    
    const Pulsanti = (id) => {
        const element = id.id.original
        //console.log(element , ' element');
        return(
            <td>
                <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4' onClick={()=>setViewSezione(element)} icon={faEye} />
                </button>
                <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4' title="modifica sezione/magazzino" icon={faEdit} onClick={async () =>await mostraModifica(element)} />
                </button>
                <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4' title="visualizza sezioni/prodotti" icon={faBoxesStacked} onClick={async () =>await mostraProdSez(element)} />
                </button>
            </td>
        )
    }
    
    const PulsantiSeleziona = (id) => {
        const element = id.id.original
        //console.log(element , ' element');
        return(
            <td>
                <Switch2
                    stato={magInventario.includes(element.id)}
                    number={element.id}
                    onChangeValue={onChangeValueSelezionaMg}
                />
            </td>
        )
    }

    const mostraSottoSezione = async (id) => {
        setViewMostraProdSez(false)
        setArrayProdSez({})
        const sezioni = await sezioneService.get({id : id});
        mostraProdSez(sezioni.data[0])

    }

    const verificaCampiObbligatoriCrea = () => {
        const campi = [
            'umId',
            'nome',
        ]
        const error = funzioniRiccorrenti.validazioneCampi(campi , inputs);
        console.log(error);
        if (Object.keys(error).length > 0) {
            setErrors(error)
            setDisablePulsanteCrea(true)
        }else {
            setErrors({})
            setDisablePulsanteCrea(false)
        }
        //console.log(inputs , ' inputs ver');
        // const error = funzioniRiccorrenti.verificaCampiObbligatori(Object.keys(inputs) ,campi);
        // if (error === true) {
        //     setErrors({})
        //     setDisablePulsanteCrea(false)
        // }else {
        //     setErrors(error)
        //     setDisablePulsanteCrea(true)
        // }
    }
    //console.log(arrayProdSez , ' arrayProdSezs');
    console.log(viewSezione , ' viewSezione');

    const esportaDati = async () => {
        //await classSezione.getInventarioMg({magazzini : magInventario})
        await file_fetch.scaricaInventario({magazzini : magInventario})
    }
    return( 
        <div className='d-flex justify-content-around flex-wrap '>

            <div className="w-100">

                <PaginazioneTitoloPulsanteCrea titolo={'Magazzini o sezioni'} funCrea={()=>{setViewCreaSez(true); verificaCampiObbligatoriCrea()}} />

                <div className="border_filtra p-3 my-5 ms-4" style={{width : '98.3%'}} >
                    <FiltraSezioni 
                        value={inputsFiltra}
                        onChange={onChangeFiltra}
                    />
                    <div className="ms-4">
                        <PulsantiFiltra
                            filtra={filtraSezioni}
                            azzera={readSez}
                        />
                    </div>
                </div>
                    
                <div className="ms-4" >
                    <button 
                        className="btn btn-primary" 
                        onClick={()=>setViewInventario(true)}
                    >
                        Scarica invetario magazzini
                    </button>
                </div>

                <Tabella
                    data={arraySez}
                    columns={columns}
                    Pulsanti={Pulsanti}
                />


                {viewCreaSez && <QuestionModal
                    title='Crea magazzino/sezione'
                    text={
                        <div>
                            <CampiSezioni      
                                values={inputs}
                                onChange={onChange}
                                errors={errors}
                            />
                        </div>
                    }
                    YesLabel='Crea'
                    yesDisabled={disablePulsanteCrea}
                    onClose={onCloseCreate}
                />}

                {viewModSez && <QuestionModal
                    title='Modifica magazzino/sezione'
                    text={
                        <div>
                            <CampiSezioni
                                values={inputs}
                                onChange={onChange}
                                errors={errors}
                            />
                        </div>
                    }
                    YesLabel='Modifica'
                    onClose={onCloseModifica}
                />}

                {!!viewMostraProdSez &&<InfoModal
                    title={"Articoli all'interno della magazzino/sezione "+ arrayProdSez.nome  +" e sottosezioni"}
                    text={
                        <div className="d-flex flex-column align-items-center " >
                            {arrayProdSez?.prodotti.length > 0 ?
                                <div className="w-100 border border-primary border-3 p-3 rounded-4 m-3" >
                                    <h3>Articoli</h3>
                                    <TabellaProdotti array={arrayProdSez.prodotti.filter(prod => prod.bollaUscitaId === null)} />
                                </div>
                                :
                                <>
                                    non ci sono articoli in questa sezione
                                </>
                            }
                            { arrayProdSez?.sezioneFigli.length > 0 ?
                                <div className="border border-primary border-3 p-3 rounded-4 m-3 w-50" >
                                    <h3>SottoSezioni</h3>
                                     {arrayProdSez.sezioneFigli.map((item)=> {
                                        return(
                                            <div>
                                                <p> {item.nome} <button className="btn text-primary" onClick={()=> mostraSottoSezione(item.id)} > <FontAwesomeIcon icon={faEye} /> </button> </p>
                                            </div>
                                        )
                                     })}
                                </div>
                                :
                                <>
                                    non ci sono sottosezzioni in questa sezione
                                </>
                            }
                        </div>
                    }
                    className='modal-xl'
                    canClose
                    handleClose={onCloseMostraProd}
                    onClose={()=>setViewMostraProdSez(false)}
                />}

            </div>

            {!!viewInventario &&
                <InfoModal
                    size='xl'
                    title={'Esporta inventario magazzino'}
                    text={<div className="w-100" >
                        <div className="border_filtra p-3 my-5" >
                            <FiltraSezioni 
                                value={inputsFiltra}
                                onChange={onChangeFiltra}
                            />
                            <PulsantiFiltra
                                filtra={filtraSezioni}
                                azzera={readSez}
                            />
                        </div>

                        <Tabella
                            data={arraySez}
                            columns={columns}
                            Pulsanti={PulsantiSeleziona}
                        />

         

                    </div>}
                    alternativeFooter={
                        <div>
                            <button className="btn btn-primary m-3" onClick={esportaDati}>
                                esporta
                            </button>
                        </div>
                    }
                    canClose
                    handleClose={()=>setViewInventario(false)}
                />
            }

            {!!viewSezione &&<InfoModal
                size='xl'
                title={'Anagrafica Magazzino/Sezione'}
                text={
                    <div>
                        <div className="d-flex flex-column border border-primary border-3 m-3 p-3 rounded">
                            <span className="col-6">
                                nome : {viewSezione?.nome}
                            </span>
                            <span className="col-6">
                                descrizione : {viewSezione?.descrizione}
                            </span>
                            <span className="col-6">
                                capienza : {viewSezione?.capienza}
                            </span>
                            <span className="col-6">
                                unita di misura : {viewSezione?.nome}
                            </span>
                            <span className="col-6">
                                RFID : {viewSezione?.rfid}
                            </span>

                            <span className="col-6">
                                Modulare {!!viewSezione?.modulare ?<FontAwesomeIcon className="text-primary text-bg-primary rounded" icon={faCircle} /> : <FontAwesomeIcon className="text-danger text-bg-danger rounded" icon={faCircle} />}
                            </span>

                            <span className="col-6">
                                Magazzino in opera {!!viewSezione?.opera ?<FontAwesomeIcon className="text-primary text-bg-primary rounded" icon={faCircle} /> : <FontAwesomeIcon className="text-danger text-bg-danger rounded" icon={faCircle} />}
                            </span>

                            <span className="col-6">
                                Mobile {!!viewSezione?.mobile ?<FontAwesomeIcon className="text-primary text-bg-primary rounded" icon={faCircle} /> : <FontAwesomeIcon className="text-danger text-bg-danger rounded" icon={faCircle} />}
                            </span>
                        </div>

                        <div className="d-flex flex-column border border-primary border-3 m-3 p-3 rounded"> 
                            {viewSezione.padreId !== null ?<span className="col-6">
                                Sezione padre : {viewSezione?.sezionePadre?.nome}
                            </span>
                            :
                            <>
                            <h3 className="mt-4" >Ubicazione impianto : </h3>
                                <span className="col-6">
                                    provincia : {viewSezione?.provincia}
                                </span>
                                <span className="col-6">
                                    comune : {viewSezione?.comune}
                                </span>
                                <span className="col-6">
                                    indirizzo : {viewSezione?.indirizzo}
                                </span>
                                <span className="col-6">
                                    civico : {viewSezione?.numeroCivico}
                                </span>
                                <span className="col-6">
                                    cap : {viewSezione?.cap}
                                </span>
                            </>}
                        </div>
                    </div>
                }

                canClose
                handleClose={()=>setViewSezione(false)}
            />}
        </div>
    )
}