import React from 'react';
import FormError from '../FormError';



export default function SelectForm({
    arrayElement ,
    label , 
    name , 
    value = false , 
    onChange , 
    classi , 
    errors,
    defaultValue
}) {
    //console.log(arrayElement , ' arrayElement');
    const segnalaErrore = errors[name] ? 'is-invalid' : ''
    return(
        <div className={"m-3 "+classi}>
            <label htmlFor={name} className="form-label"> {label} </label>
            <select 
                name={name} 
                className={"form-control " + segnalaErrore} 
                aria-label="Default select example"
                value={value}
                onChange={(event) => onChange(name, event.target.value)}
            >
                {arrayElement.map((item , key) =>{
                    return(
                        <option 
                            key={key} 
                            value={item.id}
                        >
                            {item.nome}
                        </option>
                    )
                })}
            </select>
            <FormError errors={errors} field={name} />
        </div>
    )
}