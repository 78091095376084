import React , { useEffect } from "react"


export default function PulsantiFontSize() {
    
    useEffect(()=> {
        aggiornaFontSize(parseInt(localStorage.getItem('MyFontSize')))
    },[])

    const leggiDimensioneFont = () => {
        const myFontSizLocalStorage = localStorage.getItem('MyFontSize')
        if (!!myFontSizLocalStorage) {
            return parseInt(myFontSizLocalStorage)
        }
        const elemento = document.querySelector(':root')
        const prop =  window.getComputedStyle(elemento)
        const fontAttuale = parseInt(prop.getPropertyValue('--MyFontSize').slice(0, -1))
        localStorage.setItem('MyFontSize' , fontAttuale)
        return fontAttuale
    }
    
    const handleFontSizeSmaller = () =>{
        const fontSize = leggiDimensioneFont()
        aggiornaFontSize(fontSize - 10);
    }

    const handleFontSizeBigger = () =>{
        const fontSize = leggiDimensioneFont()
        aggiornaFontSize(fontSize + 10);
    }

    const handleFontSizeBase = () =>{
        aggiornaFontSize(90);
    }



    const aggiornaFontSize = (v) => {
        const vMin = 50;
        const vMax = 200;
        const v0 = v < vMin ? vMin : (v > vMax ? vMax : v)
        localStorage.setItem('MyFontSize' , v0)
        document.documentElement.style.setProperty('--MyFontSize' , v0+'%');

    }

    return(
        
        <div className="btn-group py-1 d-flex align-items-center" role="group" aria-label="Grandezza carattere" style={{height : '2rem'}}>
            <button 
                type="button" 
                className="btn btn-primary font-italic" 
                onClick={handleFontSizeSmaller}
            >
                A
            </button>

            <button 
                type="button" 
                className="btn btn-primary" 
                onClick={handleFontSizeBase}
            >
                A
            </button>
            <button 
                type="button" 
                className="btn btn-primary font-weight-bold" 
                onClick={handleFontSizeBigger}
            >
                A
            </button>
        </div>
    )
}