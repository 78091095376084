import GeneraCampiText from "../../../riutilizabili/forms/GeneraCampiText";
import sezioneService from '../../../fetch/classSezione'
import create_autocompleteWithService from "../../../riutilizabili/autocomplite/create_autocompleteWithService";

export default function CampiProdSezione({
    value ,
    onChange ,
    errors
}) {

    const campi = {
        text : [
            {
                name : 'quantita',
                label : 'quantita'
            }
 
        ],
        number : [
            {
                name : 'prezzoUnitarioCent',
                label : 'Prezzo un unità'
            },
            {
                name : 'scontoValCent',
                label : 'sconto'
            },
            {
                name : 'imponibileCent',
                label : 'imponibile'
            },
        ]
    }
    //console.log(errors , ' errors');
    //console.log(value , ' dati 2')
    const UnMisuraAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => sezioneService.getUnMisura({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona unità di misura' ,
        value: value.nomeUnitaMisura ,
        onChange : ({value, item}) => {onChange('umId' , item?.id ? item.id : ''); onChange('nomeUnitaMisura' , item?.nome ? item.nome : '')},
        placeholder: 'inserisci nome sezione',
    });
   
    return(
        <div className="border border-primary border-3 p-3 rounded-4 m-3">
            <div className="ms-2 ps-1">
                <GeneraCampiText
                    campi={campi}
                    inputs={value}
                    onChange={onChange}
                    errors={errors}
                />
            </div>
                
            <div className="ms-4 ps-1">
                <UnMisuraAutocomplite />
                {!!errors?.umId && <span className="text-danger font-weight-bold"> {errors?.umId} </span> }
            </div>


        </div>
    )
}