import { useEffect, useState } from "react"
import caricaFile_fetch from "../fetch/caricaFile_fetch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from "@fortawesome/free-solid-svg-icons";


export default function ImgsProd({
    prodId
}) {

    const [imgs , setImgs]=useState([]);
    const [img , setImg]=useState(false);
    const [imgDim , setImgDim]=useState(30);

    useEffect(()=>{
        trovaImgsProd()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const trovaImgsProd = async () => {
        const imgs = await caricaFile_fetch.visualizzaFotoProd(prodId);
        //console.log(imgs.data , ' imgs prod');
        setImgs(imgs.data)
    }


    const test = (dimg) => {
        const immagine = '<body style="margin: 0px; height: 100%; background-color: rgb(14, 14, 14);" ><img style="display: block;-webkit-user-select: none;margin: auto;cursor: zoom-in;background-color: hsl(0, 0%, 90%);transition: background-color 300ms;" src="'+'data:'+dimg.tipo+';base64,'+dimg.scr+'" width="1085" height="903"></body>';
        let popup = window.open();
        popup.document.write(immagine);                        
    }
    
    return(
        <div>
            {imgs.length > 0 ? 
                <div className="d-flex flex-wrap" >
                    {imgs.map(item=>{
                        return(
                            <div className="w-25" >
                                <img onClick={()=>setImg(item)} className="w-75" src={'data:'+item.tipo+';base64,'+item.scr} alt={item.nome} />
                            </div>
                        )
                    })}
                </div>
                :
                <p>Foto non disponibili</p>
            }

            {!!img && 
            <div 
                className="card position-fixed bg-transparent"
                style={{
                    width : imgDim+'%',
                    top: '40%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                }}
            >
                <button onClick={()=>setImg(false)} className="btn fs-3 text-end border-0">
                    <FontAwesomeIcon icon={faXmark} />
                </button>
                {/* <a href={'data:'+img.tipo+';base64,'+img.scr} target="_blank" >
                </a> */}
                    <img src={'data:'+img.tipo+';base64,'+img.scr} onClick={()=>test(img)} className="card-img" alt={img.nome} />
               
                <input type="range" onChange={(g)=>setImgDim(g.target.value)} class="form-range" id="customRange1" min="10" max="100"></input>
            </div>}
        </div>
    )
}