import React, { useState } from "react";
import Downshift from 'downshift';
//import Label from './Label';

import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * creo il downshift con 
 * itemToString: item -> string visualizzata
 * itemCompare: item, inputValue -> boolean se deve essere visualizzato l'item in base al valore cercato
 * freeinput: boolean, ammette inserimento libero
 */
function Ds({
    onFocus,
    onBlur,
    initialItems = [],
    ulWidth, //larghezza ul, default 100%
    labelprops,
    excludeIds = [], //eventuali esclusioni
    excludeIdsFunc, //f (item, excludeIds) riceve elemento, e lista esclusione passata. di default lavora su id 
    servicePreprocess, // opzionale, preprocess dei dati dopo la lettura input response.data output items
    btns, //eventuali bottoni aggiuntivi renderizzati dopo input
    service, //modo di recuperare id dati. F che viene eseguita con (myValue, serviceValues)
    serviceValues, //parametri aggiuntivi passati al service
    redflag, //boolean, se false renderizza il campo invalid
    freeinput, //boolean se T accetta testo libero non presente nelle scelte
    label, //string, etichetta
    itemCompare, // f comparazione elemento - input, per filtrare lista suggerimenti chiamata con itemCompare(item, inputValue)
    itemFind, //solo per freeinput=T, f chiamata per verificare se un elemento è uguale all'input scritto a mano chiamta con (myValue, items) 
    onChange, //f usata per informare il chiamante della scelta chiamata. riceve ({value, item})
    value = '', //valore iniziale
    itemToLi, //da item a valore visualizzato dentro il LI 
    itemToString, // da item a value (valore dentro il campo, deve essere concorde con itemFind)
    rounded = true,
  //  placeholder,
    //valore,
    itemClassNames, //classi aggiuntive in base all'item
    ...ps //le altre proprieta vengono girate all'input
    
}) {
    //uso value (valore iniziale, tengo nello stato in myvalue) + onChange per dire al chiamante cosa succede quando cambio value
    //uso itemtostring per wrapparla con i casi base
    //preparo label, itemCompare
    //items sono i suggerimenti
    //quello che rimane va all'input
    //itemfind: f per verificare correttezza input onblur nel caso non sia ammesso freeinput
    //btns: bottoni aggiuntivi a dopo l'input
    const [myValue, setMyValue] = useState(value); //qui lo cambio in base all'input inserito
    const [myOrigValue, setMyOrigValue] = useState(value); //qui non lo cambio cosi vedo se ricevo una variazione
    if (myOrigValue !== value) {
        setMyValue(value);
        setMyOrigValue(value);
    }
    //uso redflag per impostare css aggiuntive su classname

    //si occupa di gestire i casi:
    // - item non impostato
    // - inserimento manuale (l'item ha solo la prop value)

    //fonte dei dati
    const [items, setItems] = useState(initialItems);
    const [readed, setReaded] = useState(true);
    const [fieldOnFocus, setFieldOnFocus] = useState(false);

    if (!readed && service) {
        service(myValue, serviceValues).then(response =>
            setItems(servicePreprocess ? servicePreprocess(response.data) : response.data)
        ).catch((error) => {
            setItems([])
            if (error.response.status === 406) {
                window.location.href = '/login';
            }
        })
        setReaded(true);
    }

    // demanda il resto a  itemToString passata via prop
    const myItemToString = item => {
        if (!item) {
            return '';
        }
        if (item.hasOwnProperty('value')) {
            return item.value;
        }
        return itemToString(item);
    }

    //gestione del cambio di stato in modalita' input libero:
    //in caso di inserimento manuale viene associato in elemento con value : inputinserito
    const handleStateChange = changes => {
        //imposto il valore e innesco onchange
        if (changes.hasOwnProperty('selectedItem')) {
            const value = myItemToString(changes.selectedItem);
            onChange({
                value,
                item: changes.selectedItem, //passo item come informazione aggiuntiva
            });
            setMyValue(value)
        } else if (changes.hasOwnProperty('inputValue')) {
            //selezione da tastiera: se testo libero non è ammesso aggiorna solo 
            //il valore nel campo senza innescare onchange. 
            //ad allineare la difformita ci pensera' onblur in maniera da permettere
            //Valori parziali nella digitazione
            (freeinput || changes.inputValue.length === 0) && onChange({
                value: changes.inputValue,
            });
            setMyValue(changes.inputValue);
            if (fieldOnFocus) {
                setReaded(false); //rileggo i valori
            }
        }
    }

    //verifico correttezza valore all'uscita
    const handleBlur = () => {
        setFieldOnFocus(false);
        //se quando esco dal campo props.value (valore vero, che comanda) e myvalue (valore visualizzato nel campo)
        //sono differenti (perche non e ammessa selezione libera) verifica myvalue e se non va bene aggiorna myvalue
        if (!freeinput && value !== myValue) {
            //verifico con itemfind se la mia selezione temporanea (myvalue) è valida
            const item = itemFind(myValue, items)
            if (item) {
                //se lo è il campo è ok e mi manca da informare il chiamante con onchange
                onChange({
                    value: myValue,
                    item,
                });
            } else {
                //altrimenti aggiorno il valore locale 
                setMyValue(value);
            }
        }
        onBlur && onBlur();
    }

    return (
        <div className="d-flex flex-column  mb-5">
            <label className="form-label" required={ps.required} {...labelprops} >{label}</label>
            <Downshift
                onStateChange={handleStateChange}
                selectedItem={{ value: myValue }}
                itemToString={item => myItemToString(item)}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    isOpen,
                    inputValue,
                    highlightedIndex,
                    selectedItem,
                    openMenu,
                    clearItems,
                    setItemCount,
                }) => {
                    const ps0 = { ...ps };
                    ps0.className = (ps.className ? ps.className + ' ' : '') + "form-control " + (redflag ? " is-invalid" : "") + (rounded && (isOpen ? " rounded-top" : " rounded"));
                    let ulCn = 'list-group border-primary';
                    let ulCnE = 'rounded-bottom';
                    if (ulWidth) {
                        ulCnE += ' rounded-right';
                    }

                    if (isOpen) {
                        ulCn += ' ac_ul';
                        ulCnE += ' ac_ul_extern';
                    }
                    return (
                        <div style={{ position: 'relative', }} className={(redflag ? " is-invalid " : "")}> {/* va anche qui per far comparire invalid-feedback */}
                            <div className="input-group stAutoComplete">
                                <input
                                    {...ps0}
                                    {...getInputProps({
                                        onFocus: () => {
                                            setFieldOnFocus(true);
                                            setReaded(false);
                                            openMenu();
                                            onFocus && onFocus();
                                        },
                                        onClick: openMenu,
                                        onBlur: handleBlur
                                    })}
                                />
                                {btns && btns.map((v, k) => <div key={k} className="input-group-append">{v}</div>)}
                                <div className="position-absolute" style={{right: '0.25rem'}}>
                                    <FontAwesomeIcon icon={faCaretDown} />
                                </div>
                            </div>
                            <div className={ulCnE} {...getMenuProps()} style={{
                                maxHeight: '11rem',
                                overflow: 'hidden',
                                zIndex: 100,
                                width: ulWidth || '100%',
                                position: 'absolute',
                            }}>
                                <ul className={ulCn} style={{
                                    borderTopWidth: 0,
                                    maxHeight: '11rem',
                                    overflowY: 'auto',
                                    overflowX: 'hidden',
                                    width: ulWidth || '100%',
                                }}>
                                    {isOpen
                                        ? items
                                            .filter(item => itemCompare(item, inputValue))
                                            .map((item, index) => (
                                                <DsItem
                                                    itemClassNames={itemClassNames}
                                                    excludeIds={excludeIds}
                                                    excludeIdsFunc={excludeIdsFunc}
                                                    key={index}
                                                    item={item}
                                                    index={index}
                                                    getItemProps={getItemProps}
                                                    highlightedIndex={highlightedIndex}
                                                    selectedItem={selectedItem}
                                                    itemToLi={itemToLi}
                                                />
                                            ))
                                        : null}
                                </ul>
                            </div>
                        </div>
                    )
                }
                }
            </Downshift>
        </div>
    );
}
/**
 *  elemento LI della lista suggerimenti
 */
function DsItem({
    item,
    index,
    getItemProps,
    highlightedIndex,
    selectedItem,
    itemToLi,
    excludeIds,
    excludeIdsFunc,
    itemClassNames,
}) {
    const excludeIdsFuncDefault = (item, excludeIds) => excludeIds.includes(item.id);
    const esclusione = excludeIds && item.id && (excludeIdsFunc ? excludeIdsFunc(item, excludeIds) : excludeIdsFuncDefault(item, excludeIds));
    const v = esclusione ? null : itemToLi(item);
    if (!v) {
        return null;
    }
    const css = ['list-group-item', 'pl-2', 'pr-2', 'pt-1', 'pb-1'];
    if (itemClassNames) {
        itemClassNames(item).forEach(k => css.push(k));
    }
    return (
        <li title={v} className={css.join(' ')}
            {...getItemProps({
                key: item.id,
                index,
                item,
                style: {
                    backgroundColor:
                        highlightedIndex === index ? 'lightgray' : null,
                    fontWeight: selectedItem === item ? 'bold' : 'normal',
                },
            })}
        >
            {v}
        </li>
    );
}

export default function create_autocompleteWithService(otherProps) {
    //ritorna un componente che contiene DS chiamato con le prop a runtime + le prop a buildtime
    return function (props) {
        return <Ds  {...otherProps} {...props} />
    }
};
