import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useState } from "react";
import caricaFile_fetch from "../fetch/caricaFile_fetch";

export default function ViewImgPreUpload({
    imgs=[] ,
    onChangeImg,
    prodId=false
}) {
    const [ blobs , setBlobs ] = useState([])


    useEffect(()=>{
        leggiImmagini()
    },[imgs]);

    const eliminaFoto = async (item) => {
        await caricaFile_fetch.eliminaFoto(item.id);
        const tmp = [...imgs]
        // if (tmp.map(item=> item.id).includes(item.id)) {
            const tmpIds =  tmp.map(item=> item.id);
            const position =tmpIds.indexOf(item.id);
            tmp.splice(position, 1);
            //console.log(position , tmp , ' position , tmp');
            onChangeImg(tmp);
            let blobsTmp = [...blobs];
            const blobsId = blobs.map(item=> item.id);
            const positionBlobs = blobsId.indexOf(item.id);
            //console.log(positionBlobs , ' positionBlobs');
            blobsTmp.splice(positionBlobs, 1);
            setBlobs(blobsTmp)

        // }

    }



    const cambiaDefault = async (id) => {
        const tmp = []
        for (let index = 0; index < blobs.length; index++) {
            const element = blobs[index];

            if (element?.default === '1' && element.id !==id) {
                const img = await caricaFile_fetch.modificaFoto({id : element.id ,default : '0' })
                tmp.push({...img.data , scr : element.scr}) 
            }else if ( element.id ===id) {
                const img = await caricaFile_fetch.modificaFoto({id : element.id ,default : '1' })
                tmp.push({...img.data , scr : element.scr}) 
            }else {
                tmp.push(element)
            }
        }
        //console.log(tmp , ' element cambiaDefault tpm' );
        setBlobs(tmp)

        
 
    }
        
    const leggiImmagini2 = async () => {
        //console.log('element cambiaDefault entro' , blobs ,imgs );
        console.log('element.scr ' , blobs , ' imgs ' ,imgs);
        
        const idsBlob = blobs.map(item => item.id)
        for (let index = 0; index < imgs.length; index++) {
            const element = imgs[index];
            console.log(idsBlob ,' idsBlob');
            if (!idsBlob.includes(element.id)) {
                const img = await caricaFile_fetch.visualizzaFoto(element.id);
                console.log(img , ' img');
                const scr = 'data:'+element.tipo+';base64,'+img.data.file;
                console.log(scr , ' scrscr');
                
                element.default = element.default && element.default.toString()
                const dati = { ...element , scr : scr };
                setBlobs(values => ( [...values,  dati ]));
            }
        }
        //console.log('element cambiaDefault entro 2' , blobs ,imgs );
    }
    const leggiImmagini = async () => {
        if (!!prodId) {
            const imgs = await caricaFile_fetch.visualizzaFotoProd(prodId);
            let tmp = []
            for (let index = 0; index < imgs.length; index++) {
                const element = imgs[index];
                console.log(element.scr , ' element.scr');
                
                element.scr = 'data:'+element.tipo+';base64,'+element.scr;
                element.default = element.default && element.default.toString()
                tmp.push(element)
            }
            setBlobs(tmp);
        }else {
            console.log('qui element.scr imgs');
            
            leggiImmagini2()
        }
    }

    //console.log(blobs , ' blobs');
    return(
        <div>
            <>
                {blobs.map((img) =>{
                    console.log(img ,  ' ing vv');
                    return(
                        <div key={img.id} className="mt-5 mb-5 pt-2 pb-2 position-relative" >
                                <button 
                                    className='rounded position-absolute text-danger btn border-2 border-danger rounded-5 px-4 bg-white fs-5'
                                    style={{
                                        top: '1rem',
                                        right: '33.9rem',
                                        zIndex: '1'
                                    }}
                                    onClick={()=>eliminaFoto(img)}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </button>
                            <div 
                            onClick={()=>cambiaDefault(img.id)} 
                            className={img.default === '1' ? 'rounded-1 w-50 position-relative border m-2 mt-5 border-4 border-primary' :'w-50 position-relative border m-2 mt-5 '}
                            >

                                {img.default === '1' &&<button 
                                    className='rounded position-absolute text-primary btn border-2 border-primary rounded-5 px-4 bg-white fs-5'
                                    style={{
                                        bottom: '-1rem',
                                        right: '-0.1rem'
                                    }}
                                    >
                                    <FontAwesomeIcon icon={faCheck} />
                                </button>}
                                    <img className='w-100' src={img?.scr} alt=""/>
                            </div>
                        </div>
                    ) 
                })}
            </>
        </div>
    )
}
