import InputForm from "./InputForm"



export default function GeneraCampiText({
    inputs , 
    onChange ,
    errors={} ,
    campi 
}) {
    //console.log(inputs , ' inputs genera campi');
    // const campi = {
    //     text : [
    //         {
    //             name : 'numero',
    //             label : 'Codice DDT'
    //         },
    //         {
    //             name : 'agente',
    //             label : 'agente'
    //         }
    //     ],
    //     number : [
    //         {
    //             name : 'iva',
    //             label : 'iva'
    //         },
    //         {
    //             name : 'iva',
    //             label : 'iva'
    //         },
    //     ],
    //     date : [
    //         {
    //             name : 'dataFattura',
    //             label : 'data fattura'
    //         }
    //     ],
    //     'datetime-local' : [
    //         {
    //             name : 'dataOraPartenza',
    //             label : 'data e ora partenza'
    //         }
    //     ]
    // }
    //console.log(inputs , 'tmp 2');

    const recuperaValore = (nome) => {
        if (nome !== undefined) {
           // console.log(nome, ' nome 2' ,inputs);
            if (!!inputs[nome]) {
                if (nome.slice(-4) === 'Cent') {
                    return inputs[nome.slice(0,nome.length-4)+'Eur']
                } else {
                    return inputs[nome]
                }
            } else {
                return ''
       
            }
        }else {
            return ''
        }
    } 
    
    return(
        <div>
            {Object.keys(campi).map((item, key)=> {
            return(
                    <span key={key}>
                        {campi[item].map((item2, key2)=>{
                            const value = recuperaValore(item2.name);
                            //console.log(value , ' value' , item2.name);
                            return(
                                <div key={key2} className="d-inline-block" style={{width : item2.lungCamp , height : '2rem'}} >
                                    <InputForm
                                        label={item2.label}
                                        type={item} 
                                        placeholder={'Inserisci '+item2.label}
                                        name={item2.name}
                                        value={value}
                                        onChange={(name , value) => onChange(name , value)}
                                        classi=''
                                        errors={errors}
                                    />
                                </div>
                            )
                        })}
                    </span>
                )
            })}
        </div>
    )
}