
const impostazioni = {
    api_url : 'https://logisticaapi.netoip.com/' ,
    //api_url : 'http://192.168.255.52:4021/' ,
    //api_url : 'http://192.168.255.52:3003/' ,
    //api_url : 'http://192.168.1.39:4011/' ,
    //api_url : 'https://apimagazzino.mpmgroup.it/' ,
    //api_url : 'http://10.16.0.122:3003/' 
}

export default  impostazioni ;