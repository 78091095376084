import http from "./controllerFetch";


class user {

    getAll() {
        return http.get('User/readUser');
    }

    get(params = {}) {
        return http.get('User/readUser', { params });
    }
    
    create(data) {
        return http.post('User/creaUser', data);
    }

    update(data) {
        //console.log(data);
        return http.put('User/updateUser' , data);
    }
}

export default new user();