import React, { useState, useEffect } from 'react';
import user from '../fetch/user_fetch';
import Modal from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus, faInfo } from '@fortawesome/free-solid-svg-icons' 
import { useNavigate } from 'react-router-dom';

export default function SelezionaUser({ onCancel, onSuccess, utentiDaEscludere }) {
    const navigate = useNavigate();
    //console.log(utentiDaEscludere , ' utentiDaEscludere');
    const [inputs, setInputs] = useState([]);
    const [dati, setDati] = useState([]);
    const [listReady, setListReady] = useState(false);

    useEffect( ()=>{
        filtrare()
      },[])

    const handleChange = async (event) => {
            
        const name = event.target.name;
        if(event.target.files){
        
            const value = event.target.files[0];
            setInputs(values => ({...values, [name]:  value}))
        }else{
            const value = event.target.value;
            setInputs(values => ({...values, [name]: value}))
        }

    }

    const cerca = async (event) => {
        event.preventDefault();
        filtrare(inputs)
        
    }

    const filtrare = async (dati) => {
        let res = await user.get(inputs);
        //console.log(res.data,'risultato');
        await setDati(res.data);
        if (res.data.length === 0) {
            setListReady(false)
        }else{
            setListReady(true)
            if (utentiDaEscludere) {
                if (utentiDaEscludere.length > 0) {
                    let utentiNonEsclusi = []
                    res.data.forEach((element, key) => {
                        if (utentiDaEscludere.some(code => code.id === element.id) === false) {
                            utentiNonEsclusi.push(element)
                        }
                        // console.log(res.data.length, ' res.data.length');
                        // console.log(key , ' key');
                        if (res.data.length === key+1) {
                            //console.log(utentiNonEsclusi, ' utentiNonEsclusi');
                            //console.log(dati, ' dati');
                            setDati(utentiNonEsclusi)
                        }
                        
                    });            
                }
            }


        }

    }

    const handleClose = () => {
        onCancel();
    };

    return(
        <>
            <Modal.Dialog className='d-inline'>
                <Modal.Header >
                    <Modal.Title>Seleziona utente</Modal.Title>
                    <Button className='btn' style={{fontSize: '2rem' }}  variant="btn btn_serch" onClick={handleClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </Button>
                </Modal.Header>

                <Modal.Body>
                    
                    <div className="container">
                        <form onSubmit={cerca}>
                            
                            <div className="mb-3">
                                <label htmlFor="username" className="form-label">Username</label>
                                <br/>
                                <input name="username" type="text" id="username" aria-describedby="emailHelp" onChange={handleChange} />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="email" className="form-label">Email</label>
                                <br/>
                                <input name="email" type="text" id="email" aria-describedby="emailHelp" onChange={handleChange} />
                            </div>

                            <div className="mb-3">
                                <label htmlFor="tell" className="form-label">Numero di telefono</label>
                                <br/>
                                <input name="tell" type="text" id="tell" aria-describedby="emailHelp" onChange={handleChange} />
                            </div>
                            
                        
                            <button type="submit" className="btn btn_green">cerca</button>
                        </form>

                        <div className="container">
                            
                        {listReady && <table className="table table-striped">
                        <thead>
                            <tr>

                                <th scope="col">id</th>
                                <th scope="col">username</th>
                                <th scope="col">email</th>
                                <th scope="col-2">azione</th>
                            </tr>
                        </thead>
                        <tbody>
                            {dati.map((item, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{item.id}</td>
                                        <td>{item.username}</td>
                                        <td>{item.email}</td>
                                        {/* <td><a className="btn btn-primary" ><FontAwesomeIcon icon={faInfo} /> </a></td> */}
                                        <td><a className="btn"  style={{fontSize: '1.6rem' ,color: '#006FE6'}}  onClick={() => onSuccess(item.id ,item.username)}><FontAwesomeIcon icon={faPlus} /></a></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    }
                        </div>
                    </div>
                    
                    
                </Modal.Body>

                <Modal.Footer>
                </Modal.Footer>
            </Modal.Dialog>
        </>
    )
}