import React, { useState } from "react";
import InputForm from "./pagine/riutilizabili/forms/InputForm";
import login_fetch from "../fetch/login_fetch";
import logo from '../img/logo_NETOIP.png'


export default function Login() {
    const [ inputs , setInputs ] = useState({})
    const [ errors , setErrors ] = useState({})

    const login = async ()=> {
        try {
            const log = await login_fetch.postLogin(inputs)
            if (log.status === 200) {
                setErrors({})
                localStorage.setItem('accessToken', log.data.accessToken);
                localStorage.setItem('refreshToken', log.data.refreshToken);
                localStorage.setItem('username', inputs.username);
                localStorage.setItem('statoMenu', 1);
                window.location.href = '/'

                
            }else {
                setErrors(log.data)
            }
        } catch (error) {
            setErrors({
                username : ' ',
                password : error.response.data.message
            })
            console.log(error.response.data.message , ' error');
            
        }


    }

    return(
        <div style={{height: '85vh'}} className="d-flex justify-content-center align-items-center p-3">
            <div className="w-25 border border-primary p-3 rounded" >
                <img className='w-75 p-4 my-5' src={logo} alt="logo"  />
                <h3 className="text-primary" >Login Magazzino</h3>
                <div>
                    <InputForm
                        label='username'
                        type='text'
                        name='username'
                        value={!!inputs?.username ? inputs?.username : ''}
                        onChange={(nome , valore)=> setInputs(values => ({...values , [nome]: valore}))}
                        errors={errors}
                        classi={'px-5'}
                    />
                    <InputForm
                        label='password'
                        type='password'
                        name='password'
                        value={!!inputs?.password ? inputs?.password : ''}
                        onChange={(nome , valore)=> setInputs(values => ({...values , [nome]: valore}))}
                        errors={errors}
                        classi={'px-5'}
                    />

                    <button className="btn btn-primary px-5 text-white fs-6 mt-5" onClick={login} >Login</button>
                </div>
            </div>
        </div>
    )
}