import React, { useEffect } from "react";
import create_autocompleteWithService from "../../riutilizabili/create_autocompleteWithService";
import bollaService from '../../fetch/bolla_fetch'
import InputForm from "../../riutilizabili/forms/InputForm";
import SelectForm from "../../riutilizabili/forms/SelectForm";
var sprintf = require('sprintf-js').sprintf;


export default function DettagliPagamento({
    onChange,
    inputs,
    errors
}) {
    //console.log(inputs , ' inputs fed');
    // useEffect(()=>{
    //     calcTotFattura()
    //     //console.log('entro b');
    // },[inputs?.prods])

    // console.log(inputs , ' inputs DettagliPagamento');

    // const calcTotFattura = async () => {
    //     let totale = 0;
    //     let totaleIvato = 0;
    //     let totaleIva = 0;
    //     if (!!inputs?.prods) {
    //         for (let index = 0; index < inputs.prods.length; index++) {
    //             const element = inputs.prods[index];
    //             totale += element.prezzoUnitarioCent*element.quantita;

    //             let prezzoUnitarioMil = element.prezzoUnitarioCent*10
    //             console.log(prezzoUnitarioMil , ' prezzoUnitarioMil');
    //             let iva = ((prezzoUnitarioMil/100)*element.iva)*element.quantita
    //             console.log('totale iva : ', iva);
    //             totaleIva += iva
    //             let prezzoUnitarioMilIvato = (prezzoUnitarioMil*element.quantita) +iva
    //             console.log(prezzoUnitarioMilIvato , ' prezzoUnitarioMilIvato');
    //             totaleIvato += prezzoUnitarioMilIvato
    //             //console.log(element.prezzoUnitarioCent);
    //         }
    //     }
    //     //onChange('totFatturaCent', totale)
    //     onChange('totFatturaCent',totaleIvato/1000 )
    //     onChange('totPagCent',totaleIvato/1000 )
    //     onChange('imponibileCent',totale/100 )
    //     onChange('impostaCent',totaleIva/1000 )
        
    //     console.log(totaleIvato/1000 , totaleIva/1000 ,totale);

    // }

    const assMetPag = ({item , value})=> {
        //console.log(item , ' item');
       // console.log(value , ' value');
        
        onChange('tipoPagId', item?.id)
        onChange('tipoPag', value)
    }

    const MetodiPagAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => bollaService.getMetodiPag({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome,
        itemToString: item => item.nome,
        value: inputs?.tipoPag,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label: 'Metodo di pagamento',
        placeholder: 'inserisci metodo di pagamento',
        onChange : assMetPag,
        className :  errors?.tipoPag ? 'is-invalid w-50' : 'w-50 '
    });


    const recuperaValore = (nome) => {
        if (nome !== undefined) {
            //console.log(nome, ' nome 2' ,inputs);
            if (!!inputs[nome]) {
                if (nome.slice(-4) === 'Cent') {
                    return inputs[nome.slice(0,nome.length-4)+'Eur']
                } else {
                    return inputs[nome]
                }
            } else {
                return ''
       
            }
        }else {
            return ''
        }
    } 
    

    return(
        <div className="border border-primary border-3 p-3 rounded-4 m-3 " >
            <h3 className="text-primary">Dettagli pagamento</h3>
            <div className="d-flex flex-wrap" >

                <div className='w-25 pt-4'>
                    <MetodiPagAutocomplite />
                </div>
                <InputForm
                    label='Banca di appoggio'
                    name='bancaAppoggio'
                    type='text'
                    placeholder="inserisci banca d'appoggio"
                    value={inputs.bancaAppoggio}
                    onChange={onChange}
                    classi='w-25'
                    errors={errors}
                />
                <InputForm
                    label='Totale fattura ivato'
                    name='totFatturaCent'
                    type='number'
                    placeholder="inserisci totale fattura"
                    value={recuperaValore('totFatturaCent')}
                    onChange={onChange}
                    classi=''
                    errors={errors}
                />

                <InputForm
                    label='Sconto in percentuale fattura'
                    name='scontoPerc'
                    type='number'
                    placeholder="inserisci sconto in percentuale fattura"
                    value={recuperaValore('scontoPerc')}
                    onChange={onChange}
                    classi=''
                    errors={errors}
                />
                <SelectForm
                    label={'Seleziona porto'}
                    name='porto'
                    value={inputs?.porto}
                    arrayElement={[
                        {
                            id : 'Franco',
                            nome : 'Franco'
                        },
                        {
                            id : 'Assegnato',
                            nome : 'Assegnato'
                        }
                    ]}
                    errors={[]}
                    onChange={onChange}
                    classi={'w-25'}
                />
            </div>
        </div>
    )
}