import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

export default function PulsantiFiltra({ filtra , azzera }) {

 
    return(
        <div style={{height : '90px'}} className='p-2 w-100'  >
                <div className="text-left pl-3 mt-3" >
                    <div className="btn-group mt-3 mb-3">
                        <button className="btn btn-secondary text-white"
                            onClick={azzera} 
                        >Azzera</button>
                        {/* <button className="btn btn-primary"
                            onClick={filtra} 
                        >Cerca</button> */}
                    </div>
                </div>
        </div>
    )
}