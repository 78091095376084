import React from 'react';
import FormError from '../FormError';

export default function InputForm({
    label , 
    type , 
    placeholder , 
    name , 
    value , 
    onChange ,  
    onBlur ,
    classi , 
    errors ,
    maxLenght, 
    max ,
    disabled=false
}) {
    const segnalaErrore = errors[name] ? 'is-invalid' : ''

    return(
        <div className={"m-3 "+classi}>
            <label htmlFor={name} className="form-label"> { label[0].toUpperCase() + label.substr(1) } </label>
            <input 
                value={value} 
                //defaultValue={value} 
                type={type} 
                className={"form-control ms-0 " + segnalaErrore} 
                name={name} 
                id={name} 
                placeholder={placeholder}
                onChange={(event) => onChange(name, event.target.value)}
                onBlur={onBlur}
                aria-describedby="validationServer03Feedback"
                maxLength={maxLenght}
                max={max}
                disabled={disabled}
            />
            <FormError errors={errors} field={name} />
        </div>
    )
}