var sprintf = require('sprintf-js').sprintf;

function daCentEu(params) {
    let euro = 0
    if (params > 0) {
        euro = params / 100
        euro = sprintf('%0.02f' , euro).toString().replace('.', ',')
        
    }
    return euro
}
 
function daEuCent(params) {
    let centesimi = 0
    const p = parseFloat((''+params).replace(',', '.'))
    if (p > 0) {
        centesimi = p * 100
    }
    return centesimi
}

module.exports = {
    daCentEu ,
    daEuCent
}