import React , { useEffect, useState } from "react"
import classProdSezzione from "../fetch/classProdSezzione";
import CampiProdotti from "./CampiProdotti";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra";
import Tabella from "../riutilizabili/Tabella";
import Switch2 from "../riutilizabili/Switch2";
import ImgTabella from "./ImgTabella";
import create_autocompleteWithService from "../riutilizabili/create_autocompleteWithService";
import anCliente_fetch from "../fetch/anCliente_fetch";
import classSezione from "../fetch/classSezione";
import InputForm from "../riutilizabili/forms/InputForm";



export default function SelezionaProdSezione({
    onChange ,
    prodIdSelezionati=[]
}) {
    let errors = {}

    const [inputsFiltra , setinputsFiltra] = useState({}) ;
    const [inputs , setInputs] = useState([]) ;

    useEffect(()=>{
        filtraProd()
    },[])

    // useEffect(()=>{
    //     filtraProd(inputsFiltra)
    // },[inputsFiltra])


    const filtraProd = async (param) => {
        console.log(inputsFiltra , ' inputsFiltra');
        const prod = await classProdSezzione.get({...param , bollaUscitaId : -1 , notSell : 0});
        console.log(prod.data , ' prod');
        let prodMod = [];
        for (let index = 0; index < prod.data.length; index++) {
            const element = prod.data[index];
            if (element.quantitaResidua > 0) {
                prodMod.push(element)
            }
            
        }
        setInputs(prodMod);
    }

    const selezionaProdotto = (item , stato) => {
        console.log(item , ' item ' , stato);
        onChange(item)
        if (!!stato) {
            setinputsFiltra(values => ({...values, ['sezionePadreId']: null}))
            filtraProd({...inputsFiltra ,  ['sezionePadreId']: null})
        }else {
            setinputsFiltra(values => ({...values, ['sezionePadreId']: item.sezioneId}))
            filtraProd({...inputsFiltra ,  ['sezionePadreId']: item.sezioneId})
        }
    }

    

    const ProdAutocomplite = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classProdSezzione.getProdBollaUscita({
            nomeCodice: myValue,
            ...serviceValues,
            ...inputsFiltra
        }),
        itemCompare: (item, inputValue) => item.nome?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
        itemToLi: item => item?.nome +' - '+ item?.rifInterno +' - quantità disponibile : '+ item?.qtTot,
        itemToString: item => item?.nome +' - '+ item?.rifInterno +' - quantità disponibile : '+ item?.qtTot,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.nome.toLowerCase() === value?.toLowerCase() ? curr : null), null),
        label: 'Anagrafica prodotto',
        placeholder: 'inserisci nome o codice prodotto',
        value : inputsFiltra?.nomeProdotto ,
        onChange : ({value, item}) => onChangeProd(item)
    });

    const onChangeProd = (item) => {
        console.log(inputsFiltra , ' inputsFiltra');
        if (item) {
            
            if (item.qtTot === 0) {
                
                setInputs([{
                    nome : item.nome ,
                    rifInterno : item.rifInterno ,
                    infoProd : {
                        codProd : item.codProd,
                        costo : item.costo
                    },
                    codBarre: item.codBarre,
                    infoSezione : {
                        nome : ''
                    },
                    quantitaResidua : 0 ,
                    
                    
                }])
            }else {
                setinputsFiltra(values => ({...values, ['nomeProdotto']: item?.nome +' - '+ item?.rifInterno +' - quantità disponibile : '+ item?.qtTot}))
                setinputsFiltra(values => ({...values, ['prodottoId']: item.id}))
                filtraProd({...inputsFiltra ,  ['prodottoId']: item.id})
            }
            // onChangeValue('nomeProdotto' , item?.nome +' - '+ item?.rifInterno +' - quantità disponibile : '+ item?.qtTot) ; 
            // onChangeValue('prodottoId' , item.id)
        } else {
            setinputsFiltra(values => ({...values, ['nomeProdotto']: ""}))
            setinputsFiltra(values => ({...values, ['prodottoId']: ""}))
        }
    }

    console.log(inputs , ' inputs fine');

    const columns = React.useMemo(
    () => [
            {
                Header: 'Immagine',
                accessor: 'img',
                Cell : ({row : { original}}) => {
                    console.log(original , ' originalImmagine');
                    
                    return(
                        <div style={{width: '10rem'}} className='m-auto' >
                        <ImgTabella prodId={original.infoProd.id} />
                        </div>
                    )
                }
            },
            {
                Header: 'Nome',
                accessor: 'nome',
                Cell : ({row : { original}}) => {
                    return(
                        <div className="text-center">
                            {original.infoProd.nome}
                        </div>
                    )
                }
            },
            {
                Header: 'Codice identificativo',
                accessor: 'rifInterno',
            },
            {
                Header: 'Codice produttore',
                accessor: 'codProd',
                Cell : ({row : { original}}) => {
                    return(
                        <>
                            {original?.infoProd?.codProd}
                        </>
                    )
                }
            },
            {
                Header: 'Sezione',
                accessor: 'infoSezione',
                Cell : ({row : { original}}) => {
                    console.log(original , 'original');
                    return(
                        <>
                            {original?.infoSezione?.nome}
                        </>
                    )
                }
            },
            {
                Header: 'Codice a barre',
                accessor: 'codBarre',
            },
            {
                Header: 'Quantità',
                accessor: 'quantitaResidua',
            },
            {
                Header: "Costo d'acquisto",
                accessor: 'costo',
                Cell : ({row : { original}}) => {
                    console.log(original , 'original');
                    return(
                        <>
                            {original?.costo}
                        </>
                    )
                }
            },
    ],
    []
    )
        
    const Pulsanti = (id) => {
        const element = id.id.original
        console.log(element , ' element');
        return(
            <td>
                {element.quantitaResidua > 0 ? <Switch2
                    stato={prodIdSelezionati.includes(element.id)}
                    number='prod'
                    //onChangeValue={()=>onChange(element)}
                    onChangeValue={()=>selezionaProdotto(element , prodIdSelezionati.includes(element.id))}
                />
                : 
                <span className="text-danger font-weight-bold" >Non disponibile</span>
            }
            </td>
        )
    }

    const Fornitore = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => anCliente_fetch.getAll({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item?.nome?.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
        itemToLi: item => item?.ragioneSociale ? item?.ragioneSociale : item.nome+' '+item.cognome,
        itemToString: item => item?.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.nome?.toLowerCase() === value?.toLowerCase() ? curr : null), null),
        label: 'Fornitore ',
        value: inputs?.fornitore_nome ,
        onChange : ({value, item}) => {onChangeFiltra('fornitoreId', item?.id) ; onChangeFiltra('fornitore_nome', item?.ragioneSociale ? item?.ragioneSociale : item?.nome+' '+item?.cognome); onChangeFiltra('fornitoreId', item?.id) ;} ,
        placeholder: 'inserisci nome fornitore',
        className: 'W-25'
    });
    const onChangeMagazzinoPrincipale = (item) => {
        if (!!item) {
            let oggetto = [item.id , ...item?.sezioniFigli.map(el => el.id)]
            console.log(item , ' item mag princ',oggetto);
    
            onChangeFiltra('arraySezioneId',oggetto)
            onChangeFiltra('magazzinoPrincipale_nome',item.nome)
            
        }else {
            onChangeFiltra('arraySezioneId',[])
            onChangeFiltra('magazzinoPrincipale_nome','')

        }
    }

    
    const MagazzinoPrincipale = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classSezione.get({
            magazzinoPrincipale : 1,
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item?.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label :'Seleziona Magazzino' ,
        value: inputs?.magazzinoPrincipale_nome ,
        onChange : ({value, item}) => onChangeMagazzinoPrincipale(item) ,
        placeholder: 'inserisci nome magazzino',
        redflag : false ,

    });

    const onChangeFiltra = async (name , value) => {
        setinputsFiltra(values => ({...values, [name]: value})) ; 
        await filtraProd({...inputsFiltra , [name]: value}) 
    }

    return(
        <div>
            <div className="border_filtra p-3" >
                {/* <CampiProdotti
                    classiDiv={'d-flex flex-wrap'}
                    value={inputsFiltra}
                    errors={{}}
                    onChange={(name , value )=>{}
                /> */}
                <div className="d-flex flex-wrap">
                    <InputForm 
                        label='Nome*' 
                        type='text' 
                        placeholder='Inserisci nome'
                        name='nome'
                        value={!!inputsFiltra?.nome ? inputsFiltra.nome : ''}
                        onChange={(name , value) => onChangeFiltra(name , value)}
                        classi=' w-25 m-2'
                        errors={errors}
                    />
                    <InputForm 
                        label='Codice a barre' 
                        type='text' 
                        maxLenght='13'
                        placeholder='Inserisci codice a barre'
                        name='codBarre'
                        value={!!inputsFiltra.codBarre ? inputsFiltra.codBarre : ''}
                        onChange={(name , value) => onChangeFiltra(name , value)}
                        classi='w-25 m-2'
                        errors={errors}
                    />
                    
                    {/* <InputForm 
                        label='Codice produttore *' 
                        type='text' 
                        placeholder='Inserisci codice produttore'
                        name='codProd'
                        value={!!inputsFiltra.codProd ? inputsFiltra.codProd : ''}
                        onChange={(name , value) =>{ onChangeFiltra(name , value)}}
                        classi='w-25 m-2'
                        errors={errors}
                    />
                    
                    <InputForm 
                        label='Codice interno *' 
                        type='text' 
                        placeholder='Inserisci codice interno'
                        name='rifInterno'
                        value={!!inputsFiltra.rifInterno ? inputsFiltra.rifInterno : ''}
                        onChange={(name , value) => onChangeFiltra(name , value)}
                        classi='w-25 m-2'
                        errors={errors}
                    /> */}
                    {/* <div className='w-25 m-2'>
                        <MagazzinoPrincipale />
                    </div>
                    <div className='w-25 m-2 me-5 ms-3'>
                        <Fornitore />
                    </div> */}
                </div>
                <ProdAutocomplite />

                <PulsantiFiltra
                    azzera={()=>{filtraProd();setinputsFiltra({});}}
                    filtra={()=> filtraProd(inputsFiltra)}
                />
            </div>

            <Tabella
                columns={columns}
                data={inputs}
                Pulsanti={Pulsanti}
            /> 

        </div>
    )
    
}