import React from "react";
import GeneraCampiText from "../../riutilizabili/forms/GeneraCampiText";
import TextAreaForm from "../../riutilizabili/forms/TextAreaForm";
import Switch from "../../riutilizabili/forms/Switch";
import SelectForm from "../../riutilizabili/forms/SelectForm";
import InputForm from "../../riutilizabili/forms/InputForm";
import create_autocompleteWithService from "../../riutilizabili/create_autocompleteWithService";
import setupDDT_fetch from "../../fetch/setupDDT_fetch";
import classZone_fetch from "../../fetch/classZone_fetch";
import FormError from "../../riutilizabili/FormError";
import { useLocation } from "react-router";



export default function DettagliBolla({
    onChange,
    inputs,
    errors
}) {
    let location = useLocation()

    console.log(location.pathname.includes('Uscita') , "location.pathname.includes('creaBollaUscita');");
    

    console.log('====================================');
    console.log(inputs , ' inputs dettagli bolla uscita');
    console.log('====================================');
    
    const campi = {
        text : [
            {
                name : 'incTrasporto',
                label : 'Incaricato transporto',
                lungCamp :'40%'
            },
        ],
        number : [
            {
                name : 'nColli',
                label : 'Numero colli',
                lungCamp :'20%'
            },

        ]
   
    }

    const campi2 = {
        number : [
            {
                name : 'totPesoKg',
                label : 'totale peso in kg'
            },
        ],
        date : [
            {
                name : 'dataFattura',
                label : 'data bolla'
            }
        ],
        'datetime-local' : [
            {
                name : 'dataOraPartenza',
                label : 'data e ora partenza'
            },
        ]
    }

    const onChangeCausale = ({item , value})=> {
        onChange('causale', item?.nome)
    }
    

    const CausaliAutocomplite = create_autocompleteWithService({
        freeinput: true,
        service: (myValue, serviceValues) => setupDDT_fetch.getCausale({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
        itemToLi: item => item.nome,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.nome.toLowerCase() === value?.toLowerCase() ? curr : null), null),
        label: 'Causale',
        placeholder: 'inserisci causale',
        value : inputs?.causale,
        onChange : onChangeCausale  ,
        className :'w-25' ,
    });

    const onChangeAspettoBeni = ({item , value})=> {
        onChange('packaging', item?.nome)
    }
    

    const AspettoBeniAutocomplite = create_autocompleteWithService({
        freeinput: true,
        service: (myValue, serviceValues) => setupDDT_fetch.getAspettoBeni({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "")?.toLowerCase()) !== -1,
        itemToLi: item => item.nome,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr?.nome.toLowerCase() === value?.toLowerCase() ? curr : null), null),
        label: 'Aspetto esteriore dei beni',
        placeholder: 'inserisci aspetto esteriore dei beni',
        value : inputs?.packaging,
        onChange : onChangeAspettoBeni  ,
        className :'w-25' ,
    });

    const onChangeProdottiProvincia = async ({ value, item }) => {
        console.log(item , ' item ',value);
        onChange('provianciaDestinatario', item?.sigla)
    }
    
    const AutocompleteProdottiProvincia = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classZone_fetch.provinciaLike({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome + ' (' + item.sigla + ')',
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label: 'Seleziona provincia',
        placeholder: 'inserisci provincia',
        onChange: onChangeProdottiProvincia,
        value: inputs?.provianciaDestinatario
    });

    const onChangeProdottiComune = async ({ value, item }) => {
        onChange('comuneDestinatario', item?.nome)
    }


    const AutocompleteProdottiComune = create_autocompleteWithService({
        freeinput: false,
        service: (myValue, serviceValues) => classZone_fetch.comuneLike({
            nome: myValue,
            ...serviceValues,
        }),
        itemCompare: (item, inputValue) => item.nome.toLowerCase().indexOf((inputValue || "").toLowerCase()) !== -1,
        itemToLi: item => item.nome ,
        itemToString: item => item.nome,
        itemFind: (value, items) => items.reduce((acc, curr) => acc || (curr.nome.toLowerCase() === value.toLowerCase() ? curr : null), null),
        label: 'Seleziona comune',
        placeholder: 'inserisci comune',
        onChange: onChangeProdottiComune,
        value: inputs?.comuneDestinatario
    });

    const campiDestinatario = {
        text : [
            {
                name : 'indirizzoDestinatario',
                label : 'indirizzo destinazione',
                lungCamp :'40%'
            },
            {
                name : 'civicoDestinatario',
                label : 'civico destinazione',
                lungCamp :'40%'
            },
            {
                name : 'capDestinatario',
                label : 'cap destinazione',
                lungCamp :'40%'
            },
            {
                name : 'ragioneSocialeDestinatario',
                label : 'Denominazione destinazione',
                lungCamp :'40%'
            },
        ]
    }
    
    return(
        <div className="border border-primary border-3 p-3 rounded-4 m-3" >
            <h3 className="text-primary ms-2" >Dettagli DDT </h3>
            <div className="d-flex align-items-center">
                <InputForm
                    type={'text'}
                    name={'numero'}
                    label={'numero DDT '+(!location.pathname.includes('Uscita') ? 'fornitore' :'')}
                    onChange={onChange}
                    errors={location.pathname.includes('Uscita') ? {} :errors}
                    value={location.pathname.includes('Uscita') ? (!!inputs?.id ? inputs?.id+'/'+inputs?.annoId : '') :  inputs?.numero}
                    classi={' w-25'}
                    disabled={location.pathname.includes('Uscita')}
                />
                <div className="w-50 m-3">
                    <CausaliAutocomplite />
                </div>
                <div className="w-50 m-3">
                    <AspettoBeniAutocomplite />
                </div>
            </div>

            <GeneraCampiText 
                campi={campi}
                inputs={inputs}
                onChange={onChange}
                errors={errors}
            />
            <div className="d-flex">
                <SelectForm
                    label={'Seleziona porto'}
                    name='porto'
                    value={inputs?.porto}
                    arrayElement={[
                        {
                            id : 'Franco',
                            nome : 'Franco'
                        },
                        {
                            id : 'Assegnato',
                            nome : 'Assegnato'
                        }
                    ]}
                    errors={[]}
                    onChange={onChange}
                    classi={'w-25 d-inline-block'}
                />
                <GeneraCampiText 
                    campi={campi2}
                    inputs={inputs}
                    onChange={onChange}
                    errors={errors}
                />
            </div>
            <TextAreaForm
                label='Annotazioni'
                name='annotazioni'
                value={inputs?.annotazioni ? inputs?.annotazioni : '' }
                onChange={onChange}
                errors={errors}
                classi={''}
            />
            
                <Switch
                    label='Destinazione diversa da destinatario'
                    stato={parseInt(inputs.indirizzoDestinazioneDiverso) }
                    number='indirizzoDestinazioneDiverso'
                    onChangeValue={(valore , nome)=>onChange(nome , valore+'')}
                    width='24%'
                    classe='mt-5 mb-5 pb-5 ps-0'
                />

                {!!parseInt(inputs.indirizzoDestinazioneDiverso ) &&
                    <>
                        <h3 className="text-primary ps-2">Destinazione</h3>
                            <div className='col-4 d-inline-block ps-3'>
                                <AutocompleteProdottiProvincia />
                                <FormError errors={errors} field="provianciaDestinatario" />
                            </div>
                            <div className='col-4 ms-5 d-inline-block'>
                                <AutocompleteProdottiComune disabled={!inputs?.provianciaDestinatario} serviceValues={{ prov: inputs?.provianciaDestinatario }} />
                                <FormError errors={errors} field="comune" />
                            </div>
                            <GeneraCampiText 
                                campi={campiDestinatario}
                                inputs={inputs}
                                onChange={onChange}
                                errors={errors}
                            />
                            
                    </>
                }
        </div>
    )
}