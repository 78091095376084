import http from "./controllerFetch";

class catProdService {
    getAll() {
        return http.get('Categoria/readCategoria');
    }

    get( params = {}) {
        return http.get('Categoria/readCategoria', { params });
    }

    create(data) {
        return http.post('Categoria/creaCategoria', data);
    }

    update(data) {
        return http.put('Categoria/updateCategoria', data);
    }

}

export default new catProdService();