import React, {useEffect, useState } from "react";
import { faEdit, faEye, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CampiBolla from "./CampiBolla";
import QuestionModal from "../riutilizabili/QuestionModal";
import { daEuCent } from "../riutilizabili/daEurCent";
import bolla_fetch from "../fetch/bolla_fetch";
import TabellaBolle from "./sezioni/TabellaBolle";
import FiltraBolla from "./FiltraBolla";
import InfoModal from "../riutilizabili/InfoModal";
import ReadCampiBolla from "./sezioni/ReadCampiBolla";
import PaginazioneTitoloPulsanteCrea from "../riutilizabili/PaginazioneTitoloPulsanteCrea";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra";
import funzioniRiccorrenti from "../riutilizabili/funzioniRiccorrenti";
import { format } from "date-fns";





export default function ViewBolla(onChangeCreaV) {
    const [inputs , setInputs] = useState({dataFattura : format(new Date(), 'yyyy-MM-dd')});
    const [inputsModifica , setInputsModifica] = useState({});
    const [inputsFiltra , setInputsFiltra] = useState({orderMoreRecente : 'DESC'});
    const [viewBolla , setViewBolla] = useState(false);
    const [bolla , setBolla] = useState({});
    const [errors , setErrors] = useState({});
    const [viewCrea , setViewCrea] = useState(false);
    const [viewModifica , setViewModifica] = useState(false);
    const [arrayBolla , setArrayBolla] = useState([]);
    const [statoAvanti , setStatoAvanti] = useState(false);
    const [campiObSegnale , setCampiObSegnale] = useState({
        'indirizzo': 'campo obbligatorio',
        'provincia': 'campo obbligatorio',
        'codiceFiscale': 'campo obbligatorio',
        'numeroCivico': 'campo obbligatorio',
        'numero': 'campo obbligatorio',
        'dataFattura': 'campo obbligatorio',
    });

    useEffect(()=> {
        readBolle() 
    },[])

    useEffect(()=> {
        if (!!onChangeCreaV.onChangeCrea) {
            setViewCrea(true) 
        }
    },[!!onChangeCreaV])

    useEffect(()=> {
        abilitaAvanti()
    },[inputs])

    const campiOb = [
        'numero',
        'dataFattura',

    ]

    const abilitaAvanti = () => {
        console.log(campiOb , inputs);
        let error = funzioniRiccorrenti.validazioneCampi(campiOb , inputs);
        console.log(error);
        if (Object.keys(error).length > 0) {
            setCampiObSegnale(error)
            setStatoAvanti(false)
        }else {
            setCampiObSegnale({})
            setStatoAvanti(true)
        }
    }

    const readBolle = async () => {
        const bolle = await bolla_fetch.get({orderMoreRecente : 'DESC'});
        //console.log(bolle , ' bolle');
        setInputsFiltra({})
        setArrayBolla(bolle.data);
    }

    const readFiltraBolle = async (param) => {
        const bolle = await bolla_fetch.get({...inputsFiltra,...param});
        console.log(bolle , ' bolle');
        setArrayBolla(bolle.data);
    }

    const onChangeCreaBolla = async(stato) => {
        if (!!stato) {
            const bolla = await bolla_fetch.creaBollaEntrata(inputs);
            if (bolla.status === 200) {
                setInputs({})
                setViewCrea(false)
                
            }
           // console.log(bolla , ' bolla creata');
        } else {
            setViewCrea(false)
        }
        readBolle()
    }

    const onChangeModificaBolla = async(stato) => {
        if (!!stato) {
            const bolla = await bolla_fetch.modBollaEntrata(inputsModifica);
            setViewModifica(false)
           // console.log(bolla , ' bolla creata');
        } else {
            setViewModifica(false)
        }
        readBolle()
    }

    const onChangeValue= (nome , valore) => {
        console.log(nome);
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputs(values => ({ ...values, [nome]: cent }))
            setInputs(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputs(values => ({ ...values, [nome]: valore }))
        }
    }

    const onChangeValueMod= (nome , valore) => {
      //  console.log(nome , valore , ' onChangeValueMod', inputsModifica);
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputsModifica(values => ({ ...values, [nome]: cent }))
            setInputsModifica(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputsModifica(values => ({ ...values, [nome]: valore }))
        }
    }

    const onChangeValueFiltra= (nome , valore) => {
        console.log(nome , valore , ' nome , valore');
        if ( nome.slice(-4) === 'Cent') {
            const cent = daEuCent(valore)
            setInputsFiltra(values => ({ ...values, [nome]: cent }))
            setInputsFiltra(values => ({ ...values, [nome.slice(0,nome.length-4)+'Eur']: valore }))
        } else {
           
            setInputsFiltra(values => ({ ...values, [nome]: valore }))
        }
        readFiltraBolle({[nome]: valore})
    }

    const recuperaDatiModBolla = (dati) => {
        let oggetto = {}
       // console.log(dati , 'dati');
        Object.keys(dati).forEach(element => {
            //console.log();
            switch (element) {
                case 'infoFornitore':
                    Object.keys(dati.infoFornitore).forEach(element2 => {
                        oggetto['an'+element2.charAt(0).toUpperCase()+element2.slice(1)] = dati.infoFornitore[element2]
                        console.log(element2 , ' element2');
                        
                        if (element2 === 'viewName') {
                            oggetto.anNominazioneFornitore = dati.infoFornitore[element2]
                        }
                    });
                break;

                case 'dataOraPartenza' :
                    if (!!dati[element]) {
                        const formattedDate = format(dati[element], "yyyy-MM-dd'T'HH:mm")
                        oggetto[element] = formattedDate
                    }
                break;
            
                case 'prods':
                    if (dati.prods.length) {
                        oggetto.prods= []
                        dati.prods.forEach(element2 => {
                            if (element2.stato === 1) {
                                Object.keys(element2.infoProd).forEach(element3 => {    
                                    if (element3 !== 'id' && element3 !== 'costo' && element3 !== 'costoCent') {
                                        element2[element3] = element2.infoProd[element3]
                                    }
                                })
                                oggetto.prods.push(element2)
                            }
                        })

                    }
                break;
            
            
                default:
                    oggetto[element] = dati[element]
                break;
            }
        });
       console.log(oggetto , 'oggetto');
        setInputsModifica(oggetto)
        setViewModifica(true)
    }

    const Pulsanti = (id) => {
        //console.log(id.id.original ,' id.id.original');
        return(
            <td>
                <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4' onClick={()=>setViewBolla(id.id.original)} icon={faEye} />
                </button>
  
                <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4' icon={faEdit} onClick={()=>recuperaDatiModBolla(id.id.original)} />
                </button>
            </td>
        )
    }


    return(
        <div className="p-3">
            <PaginazioneTitoloPulsanteCrea titolo={'Ddt in entrata'} funCrea={()=> setViewCrea(true)} />

            <div className="border_filtra p-3 ms-4" style={{width : '98.3%'}} >
                <FiltraBolla inputs={inputsFiltra} onChangeValue={onChangeValueFiltra} />
                <PulsantiFiltra azzera={readBolle} filtra={readFiltraBolle} />
            </div>

            <div className='d-flex justify-content-around flex-wrap w-100'>
                <TabellaBolle
                    array={arrayBolla}
                    Pulsanti={Pulsanti}
                    FiltraBolla={inputsFiltra}
                    onChangeFiltraBolla={(nome , valore)=>{setInputsFiltra(values => ({ ...values, [nome]: valore })); readFiltraBolle({[nome]: valore})}}
                />
            </div>

            {viewCrea &&<QuestionModal
                title={<div className="ms-4" >Crea ddt in entrata </div>}
                text={
                    <CampiBolla
                        inputs={inputs} 
                        errors={{...errors , ...campiObSegnale}}
                        onChange={onChangeValue}
                        />
                   }
                YesLabel='Salva'
                yesDisabled={!statoAvanti}
                onClose={onChangeCreaBolla}
            />}

            {viewModifica &&<QuestionModal
                title={<div className="ms-3" >Modifica ddt in entrata{' N.'+inputsModifica.id + ' del '+ format(inputsModifica.dataFattura, 'dd/MM/yyyy') }</div>}
                text={
                    <CampiBolla
                        inputs={inputsModifica} 
                        errors={{...errors }}
                        onChange={onChangeValueMod}
                        />
                   }
                YesLabel='Salva'
                onClose={onChangeModificaBolla}
            />}

            {!!viewBolla && 
            <InfoModal
                size='xl'
                title={'Ddt n° ' +viewBolla.numero}
                text={
                    <ReadCampiBolla inputs={viewBolla} />
                }
                canClose
                handleClose={()=>setViewBolla(false)}
                className = 'modal-lg'
            />}
        </div>
    )
}