import { useEffect, useState } from "react"

 


export default function TableSemp({
    colonne = [] ,//[{id, label , nome}]
    dati = [] ,
    Pulsanti ,
    infoAdd = false
}) {
    // redirect a pagina
    const [datiTab , setDatiTab]= useState([])
    const [numeroPag , setNumeroPag]= useState(1)
    const [prodStart , setProdStart]= useState(0)
    const [prodEnd , setProdEnd]= useState(10)
    const [pageSize , setPageSize]= useState(10)
    const [canPreviousPage , setCanPreviousPage]= useState(false)
    const [canNextPage , setCanNextPage]= useState(true)
    useEffect(()=>{
        gotoPage(numeroPag)
    },[pageSize , numeroPag ,dati])

    const gotoPage = (paginaDest) => {
        const start = (pageSize*(paginaDest-1))+1;
        const end = pageSize*paginaDest;
        console.log(pageSize , ' pageSize in' ,start , end);
        let prods = []
        dati.forEach((element , key) => {
            console.log(start < key+1 < end , start, key+1 , end);
            if ( start <= key+1 &&  key+1 <= end) {
                prods.push(element)
            }
        });
        if (paginaDest === 1) {
            setCanPreviousPage(false)
        }else {
            setCanPreviousPage(true)
        }
        if (paginaDest === Math.ceil(dati.length/pageSize)) {
            setCanNextPage(false)
        }else {
            setCanNextPage(true)
        }
        console.log(prods , ' prods');
        setNumeroPag(paginaDest)
        setDatiTab(prods)
        setProdStart(start)
        setProdEnd(end)
    }

    const nextPage =() => {
        gotoPage(numeroPag+1)
    }
    const previousPage =() => {
        gotoPage(numeroPag-1)
    }

    console.log(dati , ' dati');
    
    return(
        <div className="my-5" >
            <table className="w-100" >
                <thead>
                    <tr className="border bg-body-secondary" >
                        {colonne.map(item => {
                            return(
                                <th id={item.id}>
                                    {item.label}
                                </th>
                            )
                        })}
                         {Pulsanti && <th>Azioni</th>}
                    </tr>
                </thead>
                <tbody>

                    {datiTab.map((item, key) => {
                        return(
                            <tr id={item.id} className="border">
                                {colonne.map(colonna => {
                                    return(
                                        <th id={colonna.id}>
                                            {!!colonna.altCol ? 
                                            <>
                                                {colonna.altCol(item)}
                                            </>
                                            :
                                            (!!item[colonna.nome] && item[colonna.nome])
                                            }
                                        </th>
                                    )
                                })}
                                {Pulsanti && <Pulsanti id={item} />}
                            </tr>
                        )
                    })}

                </tbody>
            </table>

            {!!infoAdd && infoAdd}

            <div className="pagination mt-5 d-flex align-items-center">
                <div className='me-3'>
                    <button className='btn btn-primary' onClick={() => gotoPage(1)} disabled={!canPreviousPage}>
                        {'<<'}
                    </button>{' '}
                    <button className='btn btn-primary' onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                    </button>{' '}
                    <button className='btn btn-primary' onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                    </button>{' '}
                    <button className='btn btn-primary' onClick={() => gotoPage(Math.ceil(dati.length/pageSize))} disabled={!canNextPage}>
                        {'>>'}
                    </button>{' '}
                </div>
                <span className='me-4'>
                    Pagina{' '}
                    <strong>
                    {numeroPag} of {Math.ceil(dati.length/pageSize)}
                    </strong>{' '}
                </span>
                <span className='align-items-center d-flex me-4' >
                    | Vai a pagina :{' '}
                    <input
                    className='form-control ms-2'
                    type="number"
                    defaultValue={numeroPag}
                    onChange={e => {
                        const page = e.target.value ? Number(e.target.value) - 1 : 0
                        gotoPage(page)
                    }}
                    style={{ width: '100px' }}
                    />
                </span>{' '}
                Elementi 

                <select
                    className='ms-1 form-select'
                    style={{ width: '15%' }}
                    value={pageSize}
                    onChange={e => {
                    setPageSize(Number(e.target.value));
                    gotoPage(numeroPag)

                    }}
                >
                    {[5 , 10, 20, 30, 40, 50].map(pageSize => (
                    <option key={pageSize} value={pageSize}>
                        {pageSize}
                    </option>
                    ))}
                </select>
            </div>
        </div>
    )
}