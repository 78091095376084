import React from "react";
import ViewBolla from "./pagine/bolla/ViewBolla"
import ViewBollaUscita from "./pagine/bolla/uscita/ViewBollaUscita"
import ViewPermessi from "./pagine/permessi/ViewPermessi"
import ReadProdotti from "./pagine/prodotti/ReadProdotti"
import ViewsProdottiSezioni from "./pagine/prodotti/ViewsProdottiSezioni"
import CreaSezione from "./pagine/sezioni/CreaSezione"
import ReadUsers from "./pagine/users/ReadUsers"
import ReadAnagrafica from "./pagine/anagrafica/ReadAnagrafica";
import View from "./pagine/categorieProd/View";
import Daschboard from "./pagine/daschboard/View";
import ReadGruppi from "./pagine/gruppi/ReadGruppi";
import ViewAnFornitore from "./pagine/anagrafica/fornitore/ViewAnFornitore";


const ArrayRotte = {
    '/anagrafica' : <ReadAnagrafica /> ,
    '/anagraficaFornitori' : <ViewAnFornitore /> ,
    '/daschboard' : <Daschboard /> ,
    '/Utenti' : <ReadUsers /> ,
    '/BolleEntrata' : <ViewBolla onChangeCrea={false} /> ,
    '/BolleUscita' : <ViewBollaUscita onChangeCrea={false}/> ,
    '/Permessi' : <ViewPermessi /> ,
    '/Sezioni' : <CreaSezione /> ,
    '/ProdottiGenerici' : <ReadProdotti /> ,
    '/prodotti' : <ViewsProdottiSezioni /> ,
    '/categorieProdotti' : <View /> ,
    '/Gruppi' : <ReadGruppi /> ,
    '/creaBollaEntrata' : <ViewBolla onChangeCrea={true} /> ,
    '/creaBollaUscita' : <ViewBollaUscita onChangeCrea={true} /> ,
}


export default ArrayRotte