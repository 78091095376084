import http from "./controllerFetch";

class gruppiService {

  getAll() {
    return http.get('Gruppo/readGruppo');
  }

  readGruppo( params = {}) {
    return http.get('Gruppo/readGruppo', { params });
  }

  readAssGruppoUser( params = {}) {
    return http.get('Gruppo/readAssGruppoUser', { params });
  }

  creaGruppo(data) {
    return http.post('Gruppo/creaGruppo', data);
  }

  assUserGruppo(data) {
    return http.post('Gruppo/assUserGruppo', data);
  }

  updateGruppo( data) {
    return http.put('Gruppo/updateGruppo', data);
  }

  updateAssGruppoUser( data) {
    return http.put('Gruppo/updateAssGruppoUser', data);
  }


}

export default new gruppiService();