import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import anCliente_fetch from '../fetch/anCliente_fetch';
import AutocompliteCopertura from '../riutilizabili/autocomplite/autocompliteCopertura';
import ComuneAutocomplite from '../riutilizabili/autocomplite/ComuneAutocomplite';
import funzioniRicorrenti from '../riutilizabili/funzioniRiccorrenti.js';
import InputForm from '../riutilizabili/forms/InputForm';
import SelectForm from '../riutilizabili/forms/SelectForm';
import CheckForm from '../riutilizabili/CheckForm';


export default function CreaAnagrafica({
    values ,
    onChange,
    onChangeError,
    error ,
    onChangeTipoAn
}) {
    const [errorCampiOb , setErrorCampiOb] = useState()
    const [campiOb , setCampiOb] = useState({});
    
    
    const navigate = useNavigate();
    const [arrayTipoAn, setArrayTipoAn] = useState([]);
    
    useEffect( ()=>{
        tipoAnagrafica()
        searchCambiaTipoAn()
    },[])

    useEffect(()=>{verificaCampiOb()},[values])
    
    const campiObx =[]

    const searchCambiaTipoAn = async () => {
        if (!!values?.liberoProfessionista) {
            onChangeCambiaTipoAn('liberoProfessionista')
        }
        if (!!values?.azienda) {
            onChangeCambiaTipoAn('azienda')
        }
        if (!!values?.privato) {
            onChangeCambiaTipoAn('privato')
        }
        if (!!values?.entePubblico) {
            onChangeCambiaTipoAn('entePubblico')
        }
    }

    const onChangeCambiaTipoAn = (tipo) => {
        console.log(tipo , 'tipo' , campiObx)
        let array 
        let errors
        switch (tipo) {
            case 'liberoProfessionista':
                array = [...campiObx ,'pIva','ragioneSociale' ]
                errors = {}
                array.map(item => errors[item] = 'Campo obbligatorio' )
                setCampiOb(errors)
                onChangeError([...campiObx ,'pIva','ragioneSociale' ])
                onChangeTipoAn('liberoProfessionista')
            break;
            case 'azienda':
                array = [...campiObx ,'pIva','ragioneSociale' ]
                console.log(array , ' array')
                errors = {}
                array.map(item => errors[item] = 'Campo obbligatorio' )
                console.log(errors , ' errors')
                setCampiOb(errors)
                onChangeError([...campiObx ,'pIva','ragioneSociale' ])
                onChangeTipoAn('azienda')
            break;
            case 'privato':
                array = [...campiObx ,'codiceFiscale','nome', 'cognome' ]
                errors = {}
                array.map(item => errors[item] = 'Campo obbligatorio' )
                setCampiOb(errors)
                onChangeError([...campiObx ,'codiceFiscale','nome', 'cognome' ])
                onChangeTipoAn('privato')
            break;
            case 'entePubblico':
                array = [...campiObx ,'pIva','ragioneSociale' ]
                errors = {}
                array.map(item => errors[item] = 'Campo obbligatorio' )
                setCampiOb(errors)
                onChangeError([...campiObx ,'pIva','ragioneSociale' ])
                onChangeTipoAn('entePubblico')
            break;
        }
        console.log(errors , ' errors')
    }

    const verificaCampiOb = () => {
        Object.keys(campiOb).forEach(element => {

            if (element === 'comune') {
                if(!values['comuneResidenza']) {
                    setErrorCampiOb(values => ({ ...values, [element]: 'Campo obbligatorio' }))
    
                }else {
                    let tpm = {...errorCampiOb}
                    if (!!tpm[element]) {
                        delete tpm[element] 
                        setErrorCampiOb(tpm)
                        onChangeError(tpm)
                    }
                }
            }else {
                if(!values[element]) {
                    setErrorCampiOb(values => ({ ...values, [element]: 'Campo obbligatorio' }))
                    onChangeError({ ...errorCampiOb, [element]: 'Campo obbligatorio' })
    
                }else {
                    let tpm = {...errorCampiOb}
                    if (!!tpm[element]) {
                        delete tpm[element] 
                        setErrorCampiOb(tpm)
                        onChangeError(tpm)
                    }
                }

            }
            
        });
    }

    const tipoAnagrafica = () => {
        anCliente_fetch.tipoAn()
        .then(response => {
            setArrayTipoAn(response.data)
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");
            }
        })
    }

    const elabCodFiscale = (codiceFiscale) => {
        if(codiceFiscale.length === 16) {
            const {dataNascita , comuneNascita} = funzioniRicorrenti.recuperaDatiCodFiscale(codiceFiscale)
            onChange('dataNascita' , dataNascita)
            onChange('luogoNascita' , comuneNascita)
            console.log({dataNascita , comuneNascita} , codiceFiscale ,' codiceFiscale.length')
        }
        
        onChange('codiceFiscale' , codiceFiscale)
    }

    console.log(campiOb , ' campiObs');
    console.log(errorCampiOb , ' errorCampiOb ' , values);
    console.log(values , ' values');
    return(
        <div className='border border-primary rounded p-5 m-2 border-3' >

            {/* <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" checked={!!values?.fornitore} onClick={() =>{onChange('fornitore' , 1);onChangeCambiaTipoAn('fornitore')}} />
                <label className="form-check-label" for="flexRadioDefault1">
                Fornitore
                </label>
                </div>
                <div className="form-check">
                <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked={!values?.fornitore} onClick={() =>{onChange('fornitore' , 0)}} /> 
                <label className="form-check-label" for="flexRadioDefault2">
                Cliente
                </label>
            </div> */}
            <div className='ms-2 ps-1'>
                <label >Tipo anagrafica : </label>
                <CheckForm
                    option={[
                        {label : 'libero professionista' , nome : 'liberoProfessionista' },
                        {label : 'azienda' , nome : 'azienda'},
                        {label : 'privato' , nome : 'privato' },
                        {label : 'PA' , nome : 'entePubblico'}
                    ]}
                    values={values}
                    onChange={(nome , valore)=>{ onChangeCambiaTipoAn(nome)}}
                />
            </div>

            
            
            {values?.tipo !== '3' &&<InputForm  
                label='Ragione Sociale'
                type = 'text' 
                placeholder = 'Inserisci Ragione Sociale'
                name = 'ragioneSociale'
                value = {values?.ragioneSociale}  
                onChange = {(name , value)=> onChange(name , value)} 
                errors = {{...error , ...campiOb }}
                classi={'w-75'}
            />}
            <div className='d-flex' >
                <InputForm  
                    label='Nome'
                    type = 'text' 
                    placeholder = 'Inserisci nome'
                    name = 'nome'
                    value = {values?.nome}  
                    onChange = {(name , value)=> onChange(name , value)} 
                    errors = {{...error , ...campiOb}}
                    classi={'w-50'}
                />
                
                <InputForm  
                    label='cognome'
                    type = 'text' 
                    placeholder = 'Inserisci cognome'
                    name = 'cognome'
                    value = {values?.cognome}  
                    onChange = {(name , value)=> onChange(name , value)} 
                    errors = {{...error , ...campiOb}}
                    classi={'w-50'}
                />
            </div>

            <div className='align-items-end d-flex flex-wrap'>
                {/* {values?.tipo !== '3' &&<div className='d-flex flex-column pb-3' >
                    <ComuneAutocomplite 
                        labelprops={{className : 'm-2'}}
                        label='Sede legale' 
                        value={values?.sedeLegale} 
                        onChange={({comune , item})=>onChange('sedeLegale' , item.nome)} 
                    />
                </div>} */}
                <div>
                    <InputForm  
                        label='Codice fiscale'
                        type = 'text' 
                        placeholder = 'Inserisci codice fiscale'
                        name = 'codiceFiscale'
                        value = {values?.codiceFiscale}  
                        onChange = {(name , value)=> elabCodFiscale(value)} 
                        errors = {{...error , ...campiOb}}
                    />
                </div>
                {values?.tipo !== '3' &&<div>
                    <InputForm  
                        label='Partita IVA'
                        type = 'text' 
                        placeholder = 'Inserisci partita IVA'
                        name = 'pIva'
                        value = {values?.pIva}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                    />

                </div>}
                <div>
                    <InputForm  
                        label='Luogo di nascita'
                        type = 'text' 
                        placeholder = 'Inserisci luogo di nascita'
                        name = 'luogoNascita'
                        value = {values?.luogoNascita}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                    />
                </div>
                <div>
                    <InputForm  
                        label='Data di nascita'
                        type = 'date' 
                        placeholder = 'Inserisci data di nascita'
                        name = 'dataNascita'
                        value = {values?.dataNascita}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                    />
                </div>

            </div>

            <div className='align-items-end d-flex flex-wrap'>
                <div>
                    <InputForm  
                        label='Email'
                        type = 'email' 
                        placeholder = 'Inserisci email'
                        name = 'email'
                        value = {values?.email}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {80}
                    />
                </div>
                {values?.email &&<div>
                    <InputForm  
                        label='Seconda email'
                        type = 'email' 
                        placeholder = 'Inserisci seconda email'
                        name = 'email2'
                        value = {values?.email2}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {80}
                    />
                </div>}

                {values?.email2 &&<div>
                    <InputForm  
                        label='Terza email'
                        type = 'email' 
                        placeholder = 'Inserisci Terza email'
                        name = 'email3'
                        value = {values?.email3}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {80}
                    />
                </div>}

                <div>
                    <InputForm  
                        label='Numero di telefono'
                        type = 'text' 
                        placeholder = 'Inserisci numero di telefono'
                        name = 'numeroTelefono1'
                        value = {values?.numeroTelefono1}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {15}
                    />
                </div>

                {values?.numeroTelefono1 &&<div>
                    <InputForm  
                        label='Numero di telefono 2'
                        type = 'text' 
                        placeholder = 'Inserisci numero di telefono 2'
                        name = 'numeroTelefono2'
                        value = {values?.numeroTelefono2}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {15}
                    />
                </div>}

                {values?.numeroTelefono2 &&<div>
                    <InputForm  
                        label='Numero di telefono 3'
                        type = 'text' 
                        placeholder = 'Inserisci numero di telefono 3'
                        name = 'numeroTelefono3'
                        value = {values?.numeroTelefono3}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {15}
                    />
                </div>}

                {values?.numeroTelefono3 &&<div>
                    <InputForm  
                        label='Numero di telefono 4'
                        type = 'text' 
                        placeholder = 'Inserisci numero di telefono 4'
                        name = 'numeroTelefono3'
                        value = {values?.numeroTelefono3}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {15}
                    />
                </div>}

                {values?.tipo !== '3' &&<div>
                    <InputForm  
                        label='Codice SDI'
                        type = 'text' 
                        placeholder = 'Inserisci codice SDI'
                        name = 'codiceSDI'
                        value = {values?.codiceSDI}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {7}
                    />
                </div>}

                {values?.tipo !== '3' &&<div>
                    <InputForm  
                        label='Pec'
                        type = 'text' 
                        placeholder = 'Inserisci pec'
                        name = 'pec'
                        value = {values?.pec}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {80}
                    />
                </div>}

                {values?.tipo !== '3' &&<div>
                    <InputForm  
                        label='Ruolo firmatario'
                        type = 'text' 
                        placeholder = 'Inserisci ruolo firmatario'
                        name = 'ruoloFirmatario'
                        value = {values?.ruoloFirmatario}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {60}
                    />
                </div>}

                {values?.tipo !== '3' &&<div>
                    <InputForm  
                        label='IPA'
                        type = 'text' 
                        placeholder = 'Inserisci ipa'
                        name = 'ipa'
                        value = {values?.ipa}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {64}
                    />
                </div>}
{/* 
                <InputForm  
                    label='Ricarico'
                    type = 'number' 
                    placeholder = 'Inserisci ricarico'
                    name = 'ricarico'
                    value = {values?.ricarico}  
                    onChange = {(name , value)=> onChange(name , value)} 
                    errors = {{...error , ...campiOb}}
                    maxLenght = {11}
                /> */}

                <div>
                    <SelectForm 
                        arrayElement={[
                                {
                                    id : 1 ,
                                    nome : "carta d'identita" ,
                                },
                                {
                                    id : 2 ,
                                    nome : "patente" ,
                                },
                                {
                                    id : 3 ,
                                    nome : "passaporto" ,
                                },

                        ]}
                        label='Seleziona documento di riconoscimento'
                        name='tipoDocRiconoscimento'
                        value={values?.tipoDocRiconoscimento || 1}
                        errors = {{...error , ...campiOb}}
                        onChange = {(name , value)=> onChange(name , value)} 
                    />
                </div>
                
                <div>
                    <InputForm  
                        label='Luogo documento di riconoscimento'
                        type = 'text' 
                        placeholder = 'Inserisci luogo documento di riconoscimento'
                        name = 'luogoDocRiconoscimento'
                        value = {values?.luogoDocRiconoscimento}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {60}
                    />
                </div>
                
                <div>
                    <InputForm  
                        label='Numero documento di riconoscimento'
                        type = 'text' 
                        placeholder = 'Inserisci numero documento di riconoscimento'
                        name = 'numeroDocRiconoscimento'
                        value = {values?.numeroDocRiconoscimento}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {20}
                    />
                </div>
                
                <div>
                    <InputForm  
                        label='Data documento di riconoscimento'
                        type = 'date' 
                        placeholder = 'Inserisci data documento di riconoscimento'
                        name = 'dataDocRiconoscimento'
                        value = {values?.dataDocRiconoscimento}  
                        onChange = {(name , value)=> onChange(name , value)} 
                        errors = {{...error , ...campiOb}}
                        maxLenght = {20}
                    />
                </div>
            </div>
            
            <div className='ps-1'>
                <h3 className='ms-1'>Sede Legale</h3>
                <div className="d-flex flex-wrap">
                    <div className='col-3' >
                        <InputForm  
                            label='Provincia'
                            type = 'text' 
                            placeholder = 'Inserisci provincia'
                            name = 'provincia'
                            value = {values?.provincia}  
                            onChange = {(name , value)=> onChange(name , value)} 
                            errors = {{...error , ...campiOb}}
                            maxLenght = {60}
                        />
                    </div>
                    <div className='col-3'>
                        <InputForm  
                            label='comune'
                            type = 'text' 
                            placeholder = 'Inserisci comune'
                            name = 'comuneResidenza'
                            value = {values?.comuneResidenza}  
                            onChange = {(name , value)=> onChange(name , value)} 
                            errors = {{...error , ...campiOb}}
                            maxLenght = {60}
                        />
                    </div>
                    <div className='col-5'>
                        <InputForm  
                            label='indirizzo'
                            type = 'text' 
                            placeholder = 'Inserisci indirizzo'
                            name = 'indirizzo'
                            value = {values?.indirizzo}  
                            onChange = {(name , value)=> onChange(name , value)} 
                            errors = {{...error , ...campiOb}}
                        />
                    </div>
                    <div className='col-2'>
                        <InputForm  
                            label='civico'
                            type = 'text' 
                            placeholder = 'Inserisci civico'
                            name = 'numeroCivico'
                            value = {values?.numeroCivico}  
                            onChange = {(name , value)=> onChange(name , value)} 
                            errors = {{...error , ...campiOb}}
                            maxLenght = {7}
                        />
                    </div>
                    <div className='col-2'>
                        <InputForm  
                            label='cap'
                            type = 'text' 
                            placeholder = 'Inserisci cap'
                            name = 'cap'
                            value = {values?.cap}  
                            onChange = {(name , value)=> onChange(name , value)} 
                            errors = {{...error , ...campiOb}}
                            maxLenght = {5}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}