import React from 'react';

export default function Switch2 ({
    stato , 
    onChangeValue, 
    number ,  
    label
}) {
    //console.log(stato , ' stato ', number );
    
    const handleCambiavalore = () => onChangeValue(stato ? 0 : 1, number);

    return (
        <>
            <div className="form-check form-switch  text-center d-flex flex-column align-items-center justify-content-between" style={{width: '15%', marginBottom : '1rem'}}>
                <label className="form-check-label" htmlFor={"customSwitch"+number}>{label}</label>
                <input checked={!!stato} onChange={handleCambiavalore} className="form-check-input m-auto mt-4 mb-5"
                 type="checkbox" role="switch" id={"customSwitch"+number}/>
            </div> 
        </>
    )
}  