import DettagliBolla from "../sezioni/DettagliBolla";
import DettagliPagamento from "../sezioni/DettagliPagamento";
import AnagraficaCliente from "./AnagraficaCliente.js";
import ProdottiBollaUscita from "./ProdottiBollaUscita.js";


export default function CampiBollaUscita({
    inputs,
    errors,
    onChange
}) {
    

    return(
        <div className="w-100">
            <AnagraficaCliente  onChange={onChange} value={inputs} errors={errors} />
            <DettagliBolla  onChange={onChange} inputs={inputs} errors={errors} />
            {/* <DettagliPagamento  onChange={onChange} inputs={inputs} errors={errors} /> */}
            <ProdottiBollaUscita  onChange={onChange} inputs={inputs} errors={errors} />
        </div>
    )
}