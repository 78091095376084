import React from "react"



export default function Card({title ,text }) {

    const styleBox = {
        boxShadow: "0 0.46875rem 2.1875rem rgba(90,97,105,.1), 0 0.9375rem 1.40625rem rgba(90,97,105,.1), 0 0.25rem 0.53125rem rgba(90,97,105,.12), 0 0.125rem 0.1875rem rgba(90,97,105,.1)",
    }

    return(
        <div className="card  mb-3 p-0 h-100" style={styleBox}>
            <div 
                //className="card-body"
            >
                <div className="text-center text-white py-2 bg-primary rounded-top" >
                    <h5 class="card-title fs-2">{title}</h5>
                </div>
                <p className="card-text fs-6 p-3 px-5">
                    {text}
                </p>
            </div>
        </div>
    )
}