// import { faSearch } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FormError from "../riutilizabili/FormError";
import PulsantiFiltra from "../riutilizabili/PulsantiFiltra";
import InputForm from "../riutilizabili/forms/InputForm";


 

export default function RicercaCampiAn( {values , onChange , filtra = false , errors={} , azzeraFiltra }) {  

    return(
        <>
            <div className="d-flex flex-wrap">
                <InputForm
                    label='ragione sociale'
                    name="ragioneSociale"
                    type="text"
                    placeholder="Inserisci ragione sociale"
                    value={!!values?.ragioneSociale ? values?.ragioneSociale : ''}
                    onChange={(nome , valore) => onChange(nome, valore)}
                    errors={errors}
                    classi={' col-5'}
                />
                
                <InputForm
                    label='nome'
                    name="nome"
                    type="text"
                    placeholder="Inserisci nome"
                    value={!!values?.nome ? values?.nome : ''}
                    onChange={(nome , valore) => onChange(nome, valore)}
                    errors={errors}
                    classi={' col-3'}
                />
                
                <InputForm
                    label='cognome'
                    name="cognome"
                    type="text"
                    placeholder="Inserisci cognome"
                    value={!!values?.cognome ? values?.cognome : ''}
                    onChange={(nome , valore) => onChange(nome, valore)}
                    errors={errors}
                    classi={' col-3'}
                />
                
                <InputForm
                    label='Codice fiscale'
                    name="codiceFiscale"
                    type="text"
                    placeholder="Inserisci codice fiscale"
                    value={!!values?.codiceFiscale ? values?.codiceFiscale : ''}
                    onChange={(nome , valore) => onChange(nome, valore)}
                    errors={errors}
                    classi={' col-5'}
                /> 

                {filtra &&
                <div className="col-7 ms-2" >

                    <PulsantiFiltra
                        filtra={filtra}
                        azzera={azzeraFiltra}
                    />
                </div>
                }
            </div>
        </>
    )
}