import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import anFornitore_fetch from '../../fetch/anFornitore_fetch';


export default function ViewSingolaAnFornitore({id}) {

  const navigate = useNavigate();

  //console.log(id , ' id');
    const [dati , setDati] = useState({});

    useEffect( ()=>{
        datiAn() 
      },[])

    const datiAn = async () => {
        await anFornitore_fetch.get({id : id})
        .then(response => {
         // console.log(response.data , ' response.data[0] 1');
            if (response.data.length > 0 ) {
                let tmp = response.data[0]

                if (response.data[0].privato === 1) {
                    tmp.tipo = 'privato'
                } else if (response.data[0].entePubblico === 1) {
                    tmp.tipo = 'entePubblico'
                }else if (response.data[0].fornitore === 1) {
                    tmp.tipo = 'fornitore'
                }else if (response.data[0].liberoProfessionista === 1) {
                    tmp.tipo = 'liberoProfessionista'
                }else if (response.data[0].azienda === 1) {
                    tmp.tipo = 'azienda'
                }
                
                tmp.tipoDocRiconoscimento = tmp.tipoDocRiconoscimento === 1 ? "carta d'identita" : (tmp.tipoDocRiconoscimento === 2 ? 'patente' : 'passaporto')
                setDati(tmp)
            }
        })
       .catch(error => {
          if (error.response.status === 406) {
              navigate("/login");;
          }
        })
    }

    const columns = [
              {
                Header: 'Tipo',
                accessor: 'tipo',
              },

              {
                Header: 'Nome',
                accessor: 'nome',
              },
              {
                Header: 'Ragione Sociale',
                accessor: 'ragioneSociale',
              },
              {
                Header: 'Cognome',
                accessor: 'cognome',
              },
              {
                Header: 'Email',
                accessor: 'email',
              },
              {
                Header: 'Email 2',
                accessor: 'email2',
              },
              {
                Header: 'Email 3',
                accessor: 'email3',
              },
              {
                Header: 'Partita Iva',
                accessor: 'pIva',
              },
              {
                Header: 'Indirizzo',
                accessor: 'indirizzo',
              },
              {
                Header: 'Numero civico',
                accessor: 'numeroCivico',
              },
              {
                Header: 'Comune',
                accessor: 'comuneResidenza',
              },
              {
                Header: 'Provincia',
                accessor: 'provincia',
              },
              {
                Header: 'CAP',
                accessor: 'cap',
              },
              {
                Header: 'Sede legale',
                accessor: 'sedeLegale',
              },
              {
                Header: 'Codice fiscale',
                accessor: 'codiceFiscale',
              },
              {
                Header: 'luogoNascita',
                accessor: 'Luogo nascita',
              },
              {
                Header: 'Data nascita',
                accessor: 'dataNascita_formatoCorretto',
              },
              {
                Header: 'Numero di telefono',
                accessor: 'numeroTelefono1',
              },
              {
                Header: 'Numero di telefono 2',
                accessor: 'numeroTelefono2',
              },
              {
                Header: 'Numero di telefono 3',
                accessor: 'numeroTelefono3',
              },
              {
                Header: 'Numero di telefono 4',
                accessor: 'numeroTelefono4',
              },
              {
                Header: 'Codice SDI',
                accessor: 'codiceSDI',
              },
              {
                Header: 'PEC',
                accessor: 'pec',
              },
              {
                Header: 'Ruolo firmatario',
                accessor: 'ruoloFirmatario',
              },
              {
                Header: 'IPA',
                accessor: 'ipa',
              },
              {
                Header: 'Tipo documento di riconoscimento',
                accessor: 'tipoDocRiconoscimento',
              },
              {
                Header: 'Luogo documento di riconoscimento',
                accessor: 'luogoDocRiconoscimento',
              },
              {
                Header: 'Numero documento di riconoscimento',
                accessor: 'numeroDocRiconoscimento',
              },
              {
                Header: 'Data documento di riconoscimento',
                accessor: 'dataDocRiconoscimento_formatoCorretto',
              }

        ]

    console.log(dati , ' dati');
    return(
        <div>

            <ul>
                {columns.map((item , key) => {
                    return(
                        <>
                        {dati[item.accessor] &&<li key={key}>
                            <span className='fw-bold' > {item.Header}  :</span>  {dati[item.accessor]}
                        </li>}
                        </>
                    )
                })}
            </ul>
            {Object.keys(dati).length === 0 ? 'Anagrafica utente non disponibile' : ''}

        </div>
    )
}