import { faEdit, faEye, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import ViewAnagrafica from '../anagrafica/ViewAnagrafica';
import user_fetch from '../fetch/user_fetch';
import InfoModal from '../riutilizabili/InfoModal';
import PulsantiFiltra from '../riutilizabili/PulsantiFiltra';
import QuestionModal from '../riutilizabili/QuestionModal';
import Tabella from '../riutilizabili/Tabella';
import CampiUsers from './CampiUsers';

export default function ReadUsers() { 
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({});
    const [datiUtenti, setDatiUtenti] = useState([]);
    const [modificaDatiUtente, setModificaDatiUtente] = useState([]);
    const [modificaViewUtente, setModificaViewUtente] = useState(false);
    const [creaDatiUtente, setCreaDatiUtente] = useState([]);
    const [creaViewUtente, setCreaViewUtente] = useState(false);
    const [error, setError] = useState([]);
    const [viewAn , setViewAn ] = useState(false);

    
    useEffect( ()=>{
        readUsers()
      },[])

      useEffect(()=>{verCampiOb()},[creaDatiUtente])

      const campiOb = [
        'username',
        'password_hash',
        'email'
      ]

      const verCampiOb = () => {
        campiOb.forEach(element => {
          if (!creaDatiUtente[element]) {
            setError(values => ({ ...values, [element]: 'Campo obbligatorio' }))
          } else {
            let tpm = {...error}
            if (!!tpm[element]) {
                delete tpm[element] 
                setError(tpm)
            }
          }
        });
      }

      const readUsers = () => {
        user_fetch.getAll()
        .then(response => {
          
          let tmp = [...response.data]
          for (let index = 0; index < tmp.length; index++) {
            const element = tmp[index];
            //console.log(element.status , ' response.data');
            if (element.status === 1) {
              element.status = <div className='d-flex justify-content-center' >
                  <div 
                    className="border rounded-circle" 
                    style={{width: '25px', height: '25px', backgroundColor: 'green', marginLeft: '56px'}}
                  >
                    &nbsp;
                  </div>
              </div>
            }else {
              element.status = <div className='d-flex justify-content-center' >
                  <div 
                    className="border rounded-circle" 
                    style={{width: '25px', height: '25px', backgroundColor: 'red', marginLeft: '56px'}}
                  >
                    &nbsp;
                  </div>
              </div>

            }
            
          }
          setDatiUtenti(tmp);
        })
        .catch(error => {
          if (error.response.status === 406) {
              navigate("/login");
          }
      })
        
      }

      const columns = React.useMemo(
        () => [
          

              {
                Header: 'Username',
                accessor: 'username',
              },
              {
                Header: 'Email',
                accessor: 'email',
              },
              {
                Header: 'Livello',
                accessor: 'livello',
              },
              {
                Header: 'Stato',
                accessor: 'status',
              }
        ],
        []
      ) 

      const Pulsanti = (id) => {
        return(
            <td>
                {/* <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4 text_bluDue' icon={faEye} onClick={()=> setViewAn(id.id.original.anagrId)} />
                </button> */}

                <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4 text_bluDue' icon={faEdit} onClick={()=> modUser(id.id.original.id)} />
                </button>
            </td>
        )
      }

      const modUser = async (id) => {
        user_fetch.get({id:id})
        .then(response => {
          setModificaDatiUtente(response.data[0]);
          setModificaViewUtente(true)
        })
        .catch(error => {
          if (error.response.status === 406) {
              navigate("/login");;
          }else{
              setError(error.response.data)
          }
      })

      }

      const handleChangeModifica = (name , value) =>{
        //console.log(name , value);
        setModificaDatiUtente(values => ({ ...values, [name]: value }))
      }

      const saveModUtente = async (stato) => {
        if (stato) {
            await user_fetch.update(modificaDatiUtente)
            .then(response => {
                //console.log(response , ' response');
                setModificaViewUtente(false)
                readUsers()
            })
            .catch(error => {
                //console.log(error , ' error');
            })
        }else {
            setModificaViewUtente(false)
        }
      }

      const filtra = async (param) => {
        await user_fetch.get({...inputs , ...param})
        .then(respomse => {
          setDatiUtenti(respomse.data);
        })
        .catch(error=> {
          if (error.response.status === 406) {
              navigate("/login");;
          }else {
              setError(error.response.data)

          }
        })
      }

      const creaUser = async (stato) => {
        if (stato) {
          await user_fetch.create(creaDatiUtente)
          .then(response => {
            setCreaDatiUtente({})
            setCreaViewUtente(false)
            readUsers()
          })
          .catch(error=> {
            if (error.response.status === 406) {
                navigate("/login");;
            }else {
                setError(error.response.data)
            }
          })
        }else {
          setCreaViewUtente(false)
        }
      }
      
      //console.log(creaDatiUtente , ' creaDatiUtente');
      return(
        <div className='d-flex justify-content-around flex-column flex-wrap  ps-3'>
            <div className='mt-5'>
                <h2 className='text-primary ms-4 ps-3' >Utenti </h2>
                <div className='border border-3 border-primary m-3 p-2 rounded' >
                  <h3 className='text-primary ms-2 ps-1' >Filtra</h3>

                  <CampiUsers 
                    value ={inputs}
                    onChange={(name , value) => { setInputs(values => ({ ...values, [name]: value })); filtra({[name]: value})}}
                    errors={[]}
                    filtra = {true}
                    classiDiv={'d-flex'}
                  />
                  <div style={{height : '90px'}}  className='m-2' >
                    <PulsantiFiltra
                        filtra={filtra}
                        azzera={()=>{readUsers() ;  setInputs({})}}
                    />
                  </div>


                </div>
            
                {creaViewUtente ? 
                  <QuestionModal
                  title='Crea Utente'
                  text={
                    <div>
                      <CampiUsers 
                        classiDiv='' 
                        value={creaDatiUtente} 
                        onChange={(name ,value) => setCreaDatiUtente(values => ({ ...values, [name]: value }))}
                        errors={error} 
                        
                      />
                    </div>
                  }
                  onClose={creaUser}
                  />
                  :
                <button
                    className='border button_fix border-4 border-primary btn float-end fs-4 m-2 rounded-circle  text-primary' 
                    onClick={() =>setCreaViewUtente(true)}  
                >
                    <span>
                    <FontAwesomeIcon icon={faPlus} />

                    </span>
                </button>
                }
            
                <Tabella data={datiUtenti} columns={columns} Pulsanti = {Pulsanti}/>

                {modificaViewUtente &&<QuestionModal 
                    title={"Modifica utente "+modificaDatiUtente?.username }
                    text={
                    <CampiUsers 
                        classiDiv='' 
                        value={modificaDatiUtente} 
                        onChange={handleChangeModifica}
                        //errors={error} 
                        errors={{}}
                     />
                    }
                    onClose={saveModUtente}
                />}

                {viewAn &&
                    <InfoModal
                        title={'Anagrafica'}
                        text={<ViewAnagrafica id={viewAn} />}
                        handleClose={() => setViewAn(false)}
                        canClose={true}
                    />
                }

            </div>
        </div>
      )
}