import React , { useState } from 'react';
import Dropzone from 'react-dropzone'
import caricaFile_fetch from '../fetch/caricaFile_fetch';

export default function UploadFile({
    onSuccess,
    txtBtn = 'Aggiungi file',
    txtInfo = 'Trascina o seleziona i documenti da caricare',
    numFiles = 0, //max num di file , 0 = unlimited
    show = true,
    noBorder ,
    imgs
}) {
    const [wait, setWait] = useState(false);

    const handleDrop = acceptedFiles => {
        setWait(true)
        acceptedFiles.forEach((file, index) => {
            const n = parseInt(numFiles);
            if (n === 0 || index < n) {
                //console.log(imgs , ' imgs qui');
                caricaFile_fetch.uploadFotoProdotto(file, imgs.length === 0 ? 1 : 0)
                .then(response => {
                    //console.log(response.data , ' foto');
                    onSuccess(response.data)
                })
            }
        })
        setWait(false)
    }
    return(
        <>
            <div className={'rounded p-3 mt-3' + (noBorder ? '' : '  border')}>
                <Dropzone onDrop={handleDrop} >
                    {({ getRootProps, getInputProps, isDragActive }) => (
                        <section>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <div className={'pt-3 pb-3 ' + (isDragActive ? 'bg-primary' : '')}>
                                    {txtInfo}
                                    <button type="button" className="btn btn-primary pt-0 pb-0 ml-3 mr-3">{txtBtn}</button>
                                </div>
                            </div>
                        </section>
                    )}
                </Dropzone>
            </div>
            {wait && <div className="p-3 m-3">Caricamento ...</div>}
        </>
    )
    
}