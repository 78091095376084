import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import prodotti_fetch from '../fetch/prodotti_fetch';
import SelezionaMagazzino from '../Magazzino/SelezionaMagazzino.js';
import InputForm from '../riutilizabili/forms/InputForm';
import QuestionModal from '../riutilizabili/QuestionModal';
import SelectLivello from '../riutilizabili/SelectLivello';

export default function AssegnaMg ({values , onChange , errors}) { 
    const navigate = useNavigate();
    //console.log(values , ' values arriv');

    const [dati, setDati] = useState([]);
    const [selezionaMg , setSelezionaMg ] = useState(false);
    const [nameProdDelete , setNameProdDelete ] = useState(false);

    useEffect( ()=>{
        recuperaValori()
    },[])

    const recuperaValori = async () => {
        //console.log(values ,  ' values');
        if (values.prodottoId) {
            await prodotti_fetch.getAssPosMg({
                prodottoId : values.prodottoId,
                stato : 1
            })
            .then(response => {
                //console.log(response.data , ' cisco');
                setDati(response.data)
            })
            .catch(error => {
                if (error.response.status === 406) {
                    navigate("/login");
                }
            })
        }
    }
   
    const selectMg = (
        id ,
        nome ,
        livello1 , 
        livello2 , 
        livello3 , 
        livello4 , 
        livello5 , 
        livello6  
    ) => {

        onChange('magazzinoId' , id);
        onChange('magazzino_name' , nome);
        onChange('livello1' , livello1);
        onChange('livello2' , livello2);
        onChange('livello3' , livello3);
        onChange('livello4' , livello4);
        onChange('livello5' , livello5);
        onChange('livello6' , livello6);
        setSelezionaMg(false)
    }

    const deleteProd = async (stato) => {
        if (stato) {
            await prodotti_fetch.updateAssPosMg({
                id : nameProdDelete.id ,
                stato : 0
            })
            .then(response => {
                recuperaValori()
                setNameProdDelete(false)
            })
            .catch(error=> {
                if (error.response.status === 406) {
                    navigate("/login");;
                }
            })
        }
    }

    
    return(
        <div>
            <div>
                <ul>
                    {dati.map((item,key) =>{
                        return(
                            <li key={key}>
                                il articolo con codice {item.codiceProd} è nel magazzino
                                {' "'+ item.infoMagazzino.nome}" in posizione {item.livello1}
                                {item.livello2 && item.livello2}
                                {item.livello3 && item.livello3}
                                {item.livello4 && item.livello4}
                                {item.livello5 && item.livello5}
                                {item.livello6 && item.livello6}
                                <button 
                                    className='btn fs-3 button_fix'
                                    onClick={()=>setNameProdDelete({id :item.id , codiceProd : item.codiceProd})}
                                >
                                    <FontAwesomeIcon icon={faBan} />
                                </button>
                            </li>
                        )
                    })}
                </ul> 
            </div>
            <div>
                <InputForm
                    label='Codice articolo'
                    type='text'
                    placeholder='Inserisci codice articolo'
                    name='codiceProd'
                    value={values.codiceProd}
                    onChange={(name , value) =>onChange(name , value)}
                    errors={errors}
                />
                {selezionaMg ?
                    <SelezionaMagazzino 
                        onCancel={(()=> setSelezionaMg(false))}
                        onSuccess={selectMg}
                        utentiDaEscludere
                        />
                        :
                        <>
                            <button
                                className='btn btn-primary'
                                onClick={(()=> setSelezionaMg(true))}
                            >
                                Seleziona magazzino
                            </button>

                            <p> <span className='fw-bold' > Magazzino selezionato : </span> {values.magazzino_name}</p>
                        </>
                }

                {values.livello1 > 0 &&
                    <SelectLivello
                        label='Seleziona livello'
                        maxLenght= {values.livello1}
                        name='livello1Res'
                        errors={errors}
                        lettere={true}
                        value={values.livello1Res}
                        onChange={onChange}
                    />
                }

                {values.livello2 > 0 && values?.livello1Res ?
                    <SelectLivello
                        label='Seleziona livello 2'
                        maxLenght= {values.livello2}
                        name='livello2Res'
                        errors={errors}
                        //lettere={true}
                        value={values.livello2Res}
                        onChange={onChange}
                    />
                    : ''
                }

                {values.livello3 > 0 && values?.livello2Res ?
                    <SelectLivello
                        label='Seleziona livello 3'
                        maxLenght= {values.livello3}
                        name='livello3Res'
                        errors={errors}
                        lettere={true}
                        value={values.livello3Res}
                        onChange={onChange}
                    />
                    : ''
                }

                {values.livello4 > 0 && values?.livello3Res ?
                    <SelectLivello
                        label='Seleziona livello 4'
                        maxLenght= {values.livello4}
                        name='livello4Res'
                        errors={errors}
                        lettere={true}
                        value={values.livello4Res}
                        onChange={onChange}
                    />
                    : ''
                }

                {values.livello5 > 0 && values?.livello4Res ?
                    <SelectLivello
                        label='Seleziona livello 5'
                        maxLenght= {values.livello5}
                        name='livello5Res'
                        errors={errors}
                        lettere={true}
                        value={values.livello5Res}
                        onChange={onChange}
                    />
                    : ''
                }

                {values.livello6 > 0 && values?.livello5Res ?
                    <SelectLivello
                        label='Seleziona livello 6'
                        maxLenght= {values.livello6}
                        name='livello6Res'
                        errors={errors}
                        value={values.livello6Res}
                        onChange={onChange}
                    />
                    : ''
                }
                {nameProdDelete && <QuestionModal 
                    title='Elimina articolo dal magazzino'
                    text={'Confermi di voler eliminare il articolo con codice '+ nameProdDelete.codiceProd+' ?' }
                    onClose={deleteProd}
                    classi='m-5 modal-dialog-centered'
                />}

            </div>
        </div>
    )
}