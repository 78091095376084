import React from 'react';
import FormError from '../FormError';

export default function TextAreaForm({
    label , 
    name , 
    value , 
    onChange , 
    classi ,  
    errors
}) {
    //console.log(errors, ' errors');
    const segnalaErrore = errors[name] ? 'is-invalid' : ''
    return(
        <div className={"form-floating m-3" +classi}>
            <textarea 
                onChange={(event) => onChange(name, event.target.value)} 
                className={"form-control mt-5" + segnalaErrore} 
                placeholder="Leave a comment here" 
                id="floatingTextarea"
                defaultValue={value} 
            > 
                
            </textarea>
            <label htmlFor="floatingTextarea"> {label} </label>
            <FormError errors={errors} field={name} />
        </div>
    )
}