import React , {useEffect, useState } from "react";
import { Route , Routes } from "react-router-dom";
import login_fetch from "../fetch/login_fetch";

import ArrayRotte from "./ArrayRotte";
import Base2 from "./Base2";

function Rotte() {
    const [arrayRotte ,setArrayRotte]= useState({})

    useEffect(()=> {
        creaRotte()
    },[])

    const rotte = ArrayRotte;

    const creaRotte = async () => {
        
        const menu = await login_fetch.mostraRotte();
        const array = []
        if (menu) {
            if (menu.status === 200) {
                for (let index = 0; index < menu.data.length; index++) {
                const element = menu.data[index];
                array.push({
                    path: element.url ,
                    element:element.etichetta,
                })
                }
            }
            
        }
      
        setArrayRotte(array) ;
    }

    return(
            <>
                {arrayRotte.length > 0 &&
                    <Routes >
                        <Route path="" element={<Base2 />}>
                            <Route path='' element={rotte['/daschboard']} />
                            {arrayRotte.map((item , key)=> {

                                return(
                                    <Route key={key} path={item.path} element={rotte[item.path]} />
                                )
                            })}
                        </Route>
                    </Routes>
                }
            </>
    )
}


export default Rotte ;