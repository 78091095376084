import React from 'react';
//import ReactDOM from 'react-dom';
import './index.css';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './scss/App.scss'
const container = document.getElementById('root');

const root = createRoot(container); 

root.render(<BrowserRouter>
      <App />
  </BrowserRouter>);