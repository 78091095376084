import './App.css';
import './css/generale.css';
import Login from './components/Login';
import Rotte from './components/Rotte';
import { Helmet } from "react-helmet";
import "@fontsource/open-sans"; // Defaults to weight 400
import "@fontsource/open-sans/400.css"; // Specify weight
import "@fontsource/open-sans/400-italic.css"; // Specify weight and style

function App() {

  const esiste = localStorage.getItem('accessToken')

  if (esiste === null) {
    return (
      <div className="App">
        <Login />
      </div>
    );
  }

  return(
    <Rotte />
  )
  

}

function Application() {
  return (
    <>
      <Helmet htmlAttributes={{ 'lang': 'it_IT' }} />
      <App />
    </>
  );
};

export default Application;
