import http from "./controllerFetch";

class datiDaschboard {
    getBolla() {
        return http.get('daschboard/bolla');
    }
    getProdotti() {
        return http.get('daschboard/prodotti');
    }
    getSezioni() {
        return http.get('daschboard/sezzioni');
    }
}

export default new datiDaschboard();