import React from "react";
import Tabella from "../../riutilizabili/Tabella"
import { daCentEu } from "../../riutilizabili/daEurCent"
import { format } from "date-fns";


export default function TabellaBolleUscita({
    array = [] ,
    Pulsanti
}) {
    const columns = React.useMemo(
        () => [
            {
                Header: 'numero',
                accessor: 'id',
                Cell : ({row : { original}}) => {
                    return(
                        <>
                        {original.annoId+'/'+original.id}
                        </>
                    )
                }
            },
            {
                Header: 'Data DDT',
                accessor: 'dataFattura',
                Cell : ({row : { original}}) => {
                    return(
                        <>
                        {format(original.dataFattura, 'dd/MM/yyyy')}
                        </>
                    )
                }
            },
            {
                Header: 'descrizione',
                accessor: 'descrizione',
            },
            {
                Header: 'Cliente',
                accessor: 'cliente',
                Cell : ({row : { original}}) => {
                    return(
                        <>
                           {Object.keys(original.infoCliente).length > 0 && <>
                                {!!original.infoCliente.ragioneSociale ? original.infoCliente.ragioneSociale : original.infoCliente.nome+' '+original.infoCliente.cognome}
                            </>}
                        </>
                    )
                }
            },
            {
                Header: 'Peso totale in kg',
                accessor:'totPesoKg'
            },
            {
                Header: 'Porto',
                accessor:'porto'
            },
            {
                Header: 'N. colli',
                accessor:'nColli'
            },
            {
                Header: 'Totale DDT',
                Cell : ({row : { original}}) => {
                    return(
                        <>
                            {daCentEu(original.totFatturaCent)}
                        </>
                    )
                }
            },
        ],[]
    )
    return(
        <div className="w-100 p-4">
        <Tabella
            data={array}
            columns={columns}
            Pulsanti={Pulsanti}
        />
    </div>
    )
}