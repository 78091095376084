import http from "./controllerFetch";

class magazziniService {
    getAll() {
        return http.get('Magazzino/readMagazzino');
    }

    create(data) {
        return http.post('Magazzino/creaMagazzino', data);
    }

    update(data) {
        return http.put('Magazzino/updateMagazzino', data);
    }

    get( params = {}) {
        return http.get('Magazzino/readMagazzino', { params });
    }

    getMgUs( params = {}) {
        return http.get('Magazzino/magazziniUtente', { params });
    }

}

export default new magazziniService();