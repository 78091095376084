import { faBoxArchive, faBoxesStacked, faEdit, faEye, faPlus, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import prodotti_fetch from '../fetch/prodotti_fetch';
import SelezionaMagazzino from '../Magazzino/SelezionaMagazzino';
import ConfermaEliminazione from '../riutilizabili/ConfermaEliminazione';
import InputForm from '../riutilizabili/forms/InputForm';
import SelectForm from '../riutilizabili/forms/SelectForm';
import InfoModal from '../riutilizabili/InfoModal';
import PulsantiFiltra from '../riutilizabili/PulsantiFiltra'; 
import QuestionModal from '../riutilizabili/QuestionModal';
import SelectLivello from '../riutilizabili/SelectLivello';
import Tabella from '../riutilizabili/Tabella';
import AssegnaMg from './AssegnaMg';
import CampiProdotti from './CampiProdotti';
import PaginazioneTitoloPulsanteCrea from '../riutilizabili/PaginazioneTitoloPulsanteCrea';
import UploadFile from '../riutilizabili/UploadFile';
import ViewImgPreUpload from '../riutilizabili/ViewImgPreUpload';
import caricaFile_fetch from '../fetch/caricaFile_fetch';
import ImgsProd from './ImgsProd.js';
import ImgTabella from './ImgTabella.js';
 


export default function ReadProdotti() {
    const navigate = useNavigate();

    const [error, setError] = useState({});
    const [inputs, setInputs] = useState({});
    const [datiProd, setDatiProd] = useState([]);
    const [datiCrea , setDatiCrea] = useState({});
    const [datiModifica , setDatiModifica] = useState({});
    const [datiAssegnaMagazzino , setDatiAssegnaMagazzino] = useState({});
    const [datiView , setDatiView] = useState({});
    const [imgProd , setImgProd] = useState([]);
    const [imgProdMod , setImgProdMod] = useState([]);
    const [viewCrea , setViewCrea] = useState(false);
    const [viewModifica , setViewModifica] = useState(false);
    const [viewAssegnaMagazzino , setViewAssegnaMagazzino] = useState(false);
    const [viewView , setViewView ] = useState(false);
    const [viewEliminaProd, setViewEliminaProd] = useState(false);

    useEffect( ()=>{
        readProdotti()
      },[])

    const readProdotti = async () => {
        const response = await prodotti_fetch.get({stato : 1})
        let tmp = [...response.data]
        for (let index = 0; index < tmp.length; index++) {
            const element = tmp[index];
            element.nomeUnitaMisura = element?.infoUnitaMisura?.nome
        }
        setDatiProd(response.data);
        setInputs({})
        setError({})
    }


    const columns = [

              {
                Header: 'Immagine',
                accessor: 'img',
                Cell : ({row : { original}}) => {
                    return(
                        <div style={{width: '10rem'}} className='m-auto' >
                          <ImgTabella prodId={original.id} />
                        </div>
                    )
                }
              },
              {
                Header: 'Nome',
                accessor: 'nome',
              },
              {
                Header: 'Categoria',
                accessor: 'infoCategoria.nome',
                Cell : ({row : { original}}) => {
                    return(
                        <div style={{width: '10rem'}} className='m-auto' >
                          {original?.infoCategoria?.nome +(!!original?.infoSottoCategoria ? ' => ' +original?.infoSottoCategoria?.nome : ' ')}
                        </div>
                    )
                }
              },
              {
                Header: 'Codice a barre',
                accessor: 'codBarre',
              },
              {
                Header: 'riferimento interno',
                accessor: 'rifInterno',
              },
              {
                Header: 'ASIN',
                accessor: 'asin',
              }
        ]
    
    const handleChangeFiltra = (name , value) => {
        //console.log(name , value);
        setInputs(values => ({ ...values, [name]: value }))
        filtra({[name]: value})
    }
    
    const handleChangeModifica = (name , value) => {
        //console.log(name , value);
        setDatiModifica(values => ({ ...values, [name]: value }))
    }
    
    const handleChangeAssegnaPosizione = (name , value) => {
        //console.log(name , value);
        setDatiAssegnaMagazzino(values => ({ ...values, [name]: value }))
    }
    
    const handleChangeCrea = (name , value) => {
        //console.log(name , value);
        setDatiCrea(values => ({ ...values, [name]: value }))
    }

    const filtra = async (param) => {
        await prodotti_fetch.get({...inputs ,  stato : 1 , ...param})
        .then(response => {
            //console.log(response.data , ' response.data');
            setDatiProd(response.data)
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");
            }
        })
    }

    const modificaProdotto = async (stato) => {
        if (stato) {
            await prodotti_fetch.update(imgProd ,datiModifica)
            .then(response => {
                readProdotti()
                setViewModifica(false)
                setDatiModifica({})
            })
            .catch(error=> {
                if (error.response.status === 406) {
                    navigate("/login");;
                }else {
                    setError(error.response.data)
                }
                //console.log(error.response.data , ' error');
            })
        }else {
            setViewModifica(false)
        }
    }
    const creaProdotto = async (stato) => {
        if (stato) {
            //console.log(datiCrea , 'formdata 0');
            await prodotti_fetch.create({imgProd , datiCrea})
            .then(response => {
                readProdotti()
                setViewCrea(false)
                setDatiCrea({})
                setImgProd([])
            })
            .catch(error=> {
                if (error.response.status === 406) {
                    navigate("/login");;
                }else {
                    setError(error.response.data)
                }
                //console.log(error.response.data , ' error');
            })
        }else {
            setViewCrea(false)
        }
    }
    
    const viewPr = async (id) => {
        const an = await prodotti_fetch.get({id : id})
        
        const array = an.data[0];
        //console.log(array , 'array');
        let newArray = {}
        const datiPrendere = [
            'nome', 
            'descrizione', 
            'infoCategoria' ,
            'infoSottoCategoria' ,
            'infoFornitore' ,
            'codBarre' ,
            'prezzoVendita' ,
            'costo' ,
            'rifInterno' ,
            'note' ,
            'asin' ,
            'qtMin' ,
            'automaticReorder' ,
            'posizioneMg' ,
        ]
        for (let index = 0; index < Object.keys(array).length; index++) {
            const element = Object.keys(array)[index];
            //console.log(element , ' element');
            if (datiPrendere.includes(element)) {
                if (element === 'infoCategoria') {
                    newArray['categoria'] = array[element].nome
                }else if (element === 'infoSottoCategoria') {
                    newArray['sottoCategoria'] = array[element].nome
                }else if (element === 'infoFornitore') {
                    newArray['fornitore'] = array[element]?.viewName
                } if (element === 'codBarre') {
                    newArray['codice a barre'] = array[element]
                } if (element === 'prezzoVendita') {
                    newArray['prezzo di vendita'] = array[element]
                } if (element === 'rifInterno') {
                    newArray['riferimento interno'] = array[element]
                } if (element === 'qtMin') {
                    newArray['quantita minima'] = array[element]
                } if (element === 'automaticReorder') {
                    newArray['Riordino automatico'] = array[element] ? 'si' : 'no'
                }  if (element === 'posizioneMg') {
                    // newArray['Posizione prodotti in magazzino'] = 
                    // <ul>
                    //     {array[element].map(item =>{
                    //         return(
                    //             <li>
                    //             il prodotto con codice {item.codiceProd} è nel magazzino
                    //             {' "'+ item.infoMagazzino.nome}" in posizione {" "+item.livello1 && item.livello1}
                    //             {item.livello2 && item.livello2}
                    //             {item.livello3 && item.livello3}
                    //             {item.livello4 && item.livello4}
                    //             {item.livello5 && item.livello5}
                    //             {item.livello6 && item.livello6}
                    //             </li>
                    //         )
                    //     } )}

                    // </ul>
                } {
                    if (!datiPrendere.includes(element)) {
                        newArray[element] = array[element]
                        
                    }
                }
            }
        }
        //console.log(newArray , ' newArray');
        setDatiView(newArray)
        setViewView(id)
    }

    const modificaPr = async (id) => {
        const img = await caricaFile_fetch.visualizzaFotoProd(id)
        console.log(img.data ,' imgimg');
        
        setImgProdMod(img.data)
        await prodotti_fetch.get({id : id})
        .then(an => {
            let tmp = {...an.data[0]}
            tmp.nomeUnitaMisura = tmp?.infoUnitaMisura?.nome
            setDatiModifica(tmp)
            setViewModifica(true)
        })
        .catch(error => {
            if (error.response.status === 406) {
                navigate("/login");;
            }else{
                setError(error.response.data)
            }
        })
    }

    const eliminaProd = (stato) => {
        if (stato) {
            prodotti_fetch.update([] ,{
                id: viewEliminaProd.id ,
                stato : '0'
            })
            .then(response => {
                //console.log(response.data);
                readProdotti();
            })
            .catch(error => {
                if (error.response.status === 406) {
                    navigate("/login");;
                }else{
                    setError(error.response.data)
                }
            })
            
        }
        setViewEliminaProd(false)
    }
    
    const Pulsanti = (id) => {

        return(
            <td>
                <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4 text_bluDue' icon={faEye} onClick={()=>viewPr(id.id.original.id)} />
                </button>

                <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4 text_bluDue' icon={faEdit} onClick={()=>modificaPr(id.id.original.id)} />
                </button>

                {/* <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4 text_bluDue' icon={faBoxesStacked} onClick={()=>viewAssegnaMg(id.id.original.id , id.id.original.nome)} />
                </button> */}

                <button className='btn button_fix' >
                    <FontAwesomeIcon className='fs-4 text_bluDue' icon={faTrash} onClick={()=>setViewEliminaProd(id.id.original)} />
                </button>
            </td>
        )
    }

    const viewAssegnaMg = (id , nome) => {
        setDatiAssegnaMagazzino(values => ({ ...values, ['prodottoId']: id , ['nomeProd'] : nome }))
        setViewAssegnaMagazzino(true)
    }

    const assegnaMg = async (stato) => {
        if (stato) {
            await prodotti_fetch.createAssPosMg({
                prodottoId : datiAssegnaMagazzino.prodottoId,
                codiceProd : datiAssegnaMagazzino.codiceProd,
                magazzinoId : datiAssegnaMagazzino.magazzinoId,
                livello1 : datiAssegnaMagazzino.livello1Res,
                livello2 : datiAssegnaMagazzino.livello2Res,
                livello3 : datiAssegnaMagazzino.livello3Res,
                livello4 : datiAssegnaMagazzino.livello4Res,
                livello5 : datiAssegnaMagazzino.livello5Res,
                livello6 : datiAssegnaMagazzino.livello6Res,
                stato : 1
    
            })
            .then(response => {
                setDatiAssegnaMagazzino({})
                setViewAssegnaMagazzino(false)
            })
            .catch(error=> {
                if (error.response.status === 406) {
                    navigate("/login");;
                }else {
                    setError(error.response.data)
                }
                //console.log(error.response.data , ' error');
            })
        } else {
            setViewAssegnaMagazzino(false)
        }
    }

    const aggiungiImgMod = async (file) =>{
        await caricaFile_fetch.modificaFoto({
            id : file.id ,
            prodId : datiModifica.id
        } )
        setImgProdMod(values => ( [...values,  file ]))
    }


    //console.log(imgProd , ' imgProd' );
    return(
        <div className='d-flex justify-content-around flex-wrap'>
            <div className='m-3' >
                <PaginazioneTitoloPulsanteCrea titolo={'Anagrafica articoli'}  funCrea={() =>setViewCrea(true)} />
            

                <div className='border_filtra p-3 ms-4'style={{width : '98.3%'}} >
                    {/* <h3 className='text-primary' >Filtra</h3> */}
                    <CampiProdotti 
                        value={inputs}
                        errors={{}}
                        onChange={handleChangeFiltra}
                        classiDiv='d-flex flex-wrap'
                    />
                    <div>
                        <PulsantiFiltra
                            filtra={filtra}
                            azzera={readProdotti}
                        />
                    </div>
                </div>

                <Tabella 
                    data={datiProd}
                    columns={columns}
                    Pulsanti={Pulsanti}
                />

            </div>

                {viewCrea && <QuestionModal 
                    title = 'Crea articolo'
                    text = {
                    <>
                        <CampiProdotti 
                        value={datiCrea} 
                        onChange={handleChangeCrea} 
                        errors={error} 
                        classiDiv={'d-flex flex-wrap'} 
                        />
                        <UploadFile
                            txtInfo='Immagine articolo'
                            onSuccess={(file)=>setImgProd(values => ( [...values,  file ]))}
                            imgs={imgProd}
                        />

                        <ViewImgPreUpload
                            imgs={imgProd}
                            onChangeImg={(imgs)=> setImgProd(imgs)}
                            prodId={datiModifica.id}
                        />
                    </>
                    }
                    YesLabel = 'Crea'
                    onClose = {creaProdotto}
                />}

                {viewModifica&& <QuestionModal 
                    title = 'Modifica articolo'
                    text = {
                    <>
                        <CampiProdotti 
                            value={datiModifica} 
                            onChange={handleChangeModifica} 
                            errors={error} 
                            classiDiv={'d-flex flex-wrap'} 
                        />
                        <UploadFile
                            txtInfo='Immagine articolo'
                            onSuccess={(file)=>aggiungiImgMod(file)}
                            imgs={imgProdMod}
                        />
                        <ViewImgPreUpload
                            imgs={imgProdMod}
                            onChangeImg={(imgs)=> setImgProdMod(imgs)}
                        />
                    </>
                    }
                    YesLabel = 'Modifica'
                    onClose = {modificaProdotto}
                />}

                {viewView && <InfoModal
                  title={'Articolo : ' + datiView.nome}
                  handleClose={()=>setViewView(false)}
                  canClose='Chiudi'
                  text={
                    <div>
                        <ul>
                            {Object.keys(datiView).map((item , key) => {
                                //console.log(item, ' itemc ', datiView[item]);
                                return(
                                    <li key={key} >
                                        <span className='fw-bold' > {item} : </span>
                                        {datiView[item] && datiView[item] }
                                    </li>
                                )
                            })}

                        </ul>
                        <ImgsProd prodId={viewView} />
                    </div>
                  }
                />}
                
                {viewAssegnaMagazzino && <QuestionModal 
                    title = {'Assegna magazzino a "'+ datiAssegnaMagazzino.nomeProd +'"'}
                    text = {
                        <div>
                            <AssegnaMg 
                                values={datiAssegnaMagazzino} 
                                onChange={handleChangeAssegnaPosizione}
                                errors={error}
                            />
                        </div>
                    }
                    YesLabel = 'Modifica'
                    onClose = {assegnaMg}
                />}

                <ConfermaEliminazione nome={'Articolo '+viewEliminaProd.nome} stato={viewEliminaProd} conferma={(stato)=>eliminaProd(stato)} />
        </div>
    )
    
}